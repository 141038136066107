const get = require('lodash/get');
const set = require('lodash/set');
const isNil = require('lodash/isNil');
const defaultTo = require('lodash/defaultTo');

const n100 = (n) => Math.floor(n / 100) * 100 || 0;

// returns monthly payment, based on payment by interval and payment interval
const getMonthlyPaymentFromInterval = (payment, paymentInterval) => {
	payment = convertToPlainNumber(payment);

	switch (paymentInterval) {
		// NOTE: Hourly is always converted to weekly before being passed in here
		case 'HOURLY':
		case 'WEEKLY':
			return (payment * 52) / 12;
		case 'BIWEEKLY':
			return (payment * 26) / 12;
		case 'TWICEAMONTH':
			return payment * 2;
		case 'ANNUAL':
			return payment / 12;
		case 'MONTHLY':
		default:
			return payment;
	}
};

// returns payment by interval, based on monthly payment and payment interval
const convertMonthlyPaymentByInterval = (monthlyPayment, paymentInterval) => {
	monthlyPayment = convertToPlainNumber(monthlyPayment);

	switch (paymentInterval) {
		case 'WEEKLY':
			return (monthlyPayment * 12) / 52;
		case 'BIWEEKLY':
			return (monthlyPayment * 12) / 26;
		case 'TWICEAMONTH':
			return monthlyPayment / 2;
		default:
			return monthlyPayment;
	}
};

// format number using built in INTL method (see https://caniuse.com/#feat=internationalization for support)
const formatNumber = (number, locale) => {
	const IntlFormat = new Intl.NumberFormat(locale);

	return IntlFormat.format(number);
};

const convertToPlainNumber = (string) => {
	if (typeof string !== 'string') {
		return string;
	}

	if (!string) {
		return 0;
	}

	const selectNonNumbers = new RegExp(/[^0-9|.]/g);

	return Number(string.replace(selectNonNumbers, ''));
};

const closestNumber = (numbers, number) =>
	numbers.reduce((prev, curr) => (Math.abs(curr - number) < Math.abs(prev - number) ? curr : prev));

/**
 * sanitize a string to prevent xss using character blacklist
 * @param  {String} [str=''] String to be sanitized
 * @return {string}          Sanitized string
 */
const escapeHTML = (str = '', isAttribute = false) => {
	const ESC_MAP = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		'"': '&quot;',
		"'": '&#x27;',
		'/': '&#x2F;',
		'\\': '&#x5C;',
		'`': '&#96;',
	};
	const re = isAttribute ? new RegExp('[&<>\'"]', 'g') : /[&<>\/\\`"]/g; // eslint-disable-line

	if (str && str.replace) {
		return str.replace(re, (c) => ESC_MAP[c]);
	}

	return str;
};

const stringifyComments = (comments = []) => {
	if (Array.isArray(comments)) {
		return comments.map((comment) => comment.message).join('\n');
	}
	return comments;
};

// marks all discounts excluded except the discount with the given price plan
// if no price plan is passed in, it marks price plan discounts as excluded

const includePricePlanDiscount = (discounts = [], pricePlan) => {
	const customDiscountCode = 'DLR_CUSTOM_DISCOUNT';
	const pricePlanCode = `PLAN:${pricePlan}`;
	const pricePlanDiscount = discounts.find((discount) => discount.code === pricePlanCode);

	return discounts.map((discount) => {
		discount.include = pricePlanDiscount
			? [pricePlanCode, customDiscountCode].includes(discount.code)
			: !discount.code.includes('PLAN');

		return discount;
	});
};

const getPricePlanType = (discount) => get(discount, 'code', '').split('PLAN:')[1];

// gets the apr for a given lease offer
// sometimes APR is not populated on lease offers, in which case
// we need to get it from the money factor (moneyFactor * 24)
const getLeaseAPR = (offer) => defaultTo(offer.apr, offer.moneyFactor * 24);

const setIfPresent = (obj, key, val) => !isNil(val) && set(obj, key, val);

module.exports = {
	getMonthlyPaymentFromInterval,
	convertMonthlyPaymentByInterval,
	convertToPlainNumber,
	closestNumber,
	n100,
	formatNumber,
	escapeHTML,
	stringifyComments,
	sanitize: escapeHTML,
	includePricePlanDiscount,
	getPricePlanType,
	getLeaseAPR,
	setIfPresent,
};
