import React from 'react';
import PropTypes from 'prop-types';

const CheckSquareIcon = ({ color = '#2693E9' }) => (
	<svg fill="none" height="32" width="32" xmlns="http://www.w3.org/2000/svg">
		<rect fill={color} height="32" rx="3" width="32" />
		<path d="M6.667 17.368l5.347 5.299L24.008 9.333" stroke="#fff" strokeLinecap="square" strokeWidth="2" />
	</svg>
);

CheckSquareIcon.propTypes = {
	color: PropTypes.string,
};

export default CheckSquareIcon;
