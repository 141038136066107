import type { FC, ReactElement } from 'react';

export enum RowType {
	APR = 'apr',
	Address = 'Address',
	AnnualMiles = 'annualMiles',
	CashDown = 'cash-down',
	Contact = 'contact',
	CreditRange = 'credit-range',
	Customer = 'customer',
	Discount = 'discount',
	DownPayment = 'downPayment',
	Fee = 'fee',
	Lock = 'lock',
	Markup = 'markup',
	Money = 'money',
	PaymentInterval = 'paymentInterval',
	Rate = 'incentive',
	Rebate = 'rebate',
	Tax = 'tax',
	Term = 'termMonths',
	TradeIn = 'trade-in',
}

export type Row = {
	editable?: boolean;
	hint?: JSX.Element | string;
	id?: number | string;
	label?: string;
	popUpStyle?: object;
	popupContent?: ReactElement;
	removable?: boolean;
	tooltip?: string;
	type: RowType;
	value: any;
	valueFormatter?: (v: any) => any;
};

export type ActionType = {
	Icon?: FC<any>;
	form: ReactElement;
	label: string;
	onSave: (v: any) => void;
};

export type OnEditRow = (v: { row: Row; rows: Row[] }) => void;
export type OnRemoveRow = (v: { row: Row; rows: Row[] }) => void;

export type EditFormProps = {
	onCancel?: () => void;
	onSave?: (v: any) => void;
};
