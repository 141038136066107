import Rollbar from 'rollbar';

declare global {
	interface Window {
		Rollbar: Rollbar;
	}
}

let rollbar: Rollbar;

const getEnv = () => {
	const env = typeof window === 'undefined' ? process.env.HEROKU_APP_NAME || `-local` : window.location.hostname;

	if (env.includes('-local') || env.includes('.local')) {
		return process.env.NODE_ENV;
	}
	if (env.includes('-pr-')) {
		return 'PR';
	}
	if (env.includes('-qa')) {
		return 'QA';
	}
	if (env.includes('-dev-staging') || env.includes('-handshake')) {
		return 'dev-staging';
	}
	if (env.includes('-staging')) {
		return 'staging';
	}
	return 'production';
};

export const getRollbarInstance = () => {
	// Find out whether or not we're on server side or client
	if (typeof window === 'undefined') {
		if (process.env.ROLLBAR_SERVER_TOKEN) {
			rollbar = new Rollbar({
				accessToken: process.env.ROLLBAR_SERVER_TOKEN,
				captureUncaught: true,
				captureUnhandledRejections: true,
				environment: getEnv(),
				host: process.env.HEROKU_APP_NAME || 'local',
			});
		}
	} else {
		rollbar = window.Rollbar;
	}

	rollbar?.configure({ payload: { environment: getEnv() } });

	return rollbar;
};
