import type { IcoProvider } from '@client/graphql/generated';

type TradeInTrackingOpts = { icoProvider: IcoProvider };

export enum TradeInType {
	APPLY_ICO = 'user opted to apply an ICO',
	DENY_ICO = 'user opted to reject an ICO',
	GET_NEW_TRADE_IN = 'user starts a new tradeIn flow while already having a tradeIn on the loanapp',
	REQUEST_ICO_OFFER = 'user completed form to received ICO from AccuTrade',
	RESTART_TRADE_IN = 'user opted to restart tradein process',
	SELECT_ESTIMATE_OPTION = 'user opted to get a tradein estimate versus an ICO',
	SELECT_ICO_OPTION = 'user opted to get an ICO versus a tradein estimate',
	SELECT_ICO_TYPE = 'user selected an ICO strategy',
	SUBMIT_VEHICLE_INFORMATION = 'user submits the first form of the ICO survey',
}

export type TradeInAction =
	| {
			deniedOfferId: string;
			track: {
				meta: 'ICO Offer Removed';
				opts: TradeInTrackingOpts;
				panel: 'TradeIn';
			};
			type: typeof TradeInType.DENY_ICO;
	  }
	| {
			track: {
				meta: 'Get New Trade in Value';
				opts: TradeInTrackingOpts;
				panel: 'TradeIn';
			};
			type: typeof TradeInType.GET_NEW_TRADE_IN;
	  }
	| {
			track: {
				meta: 'Estimate Button Clicked';
				opts: TradeInTrackingOpts;
				panel: 'TradeIn';
			};
			type: typeof TradeInType.SELECT_ESTIMATE_OPTION;
	  }
	| {
			track: {
				meta: 'ICO Button Clicked';
				opts: TradeInTrackingOpts;
				panel: 'TradeIn';
			};
			type: typeof TradeInType.SELECT_ICO_OPTION;
	  }
	| {
			track: {
				meta: string;
				opts: TradeInTrackingOpts;
				panel: 'TradeIn';
			};
			type: typeof TradeInType.SELECT_ICO_TYPE;
	  }
	| {
			track: {
				meta: 'ICO Get Offer Clicked';
				opts: TradeInTrackingOpts;
				panel: 'TradeIn';
			};
			type: typeof TradeInType.REQUEST_ICO_OFFER;
	  }
	| {
			track: {
				meta: 'ICO Offer Applied';
				opts: TradeInTrackingOpts;
				panel: 'TradeIn';
			};
			type: typeof TradeInType.APPLY_ICO;
	  }
	| {
			track: {
				meta: 'completed: click: restart';
				opts: TradeInTrackingOpts;
				panel: 'TradeIn';
			};
			type: typeof TradeInType.RESTART_TRADE_IN;
	  }
	| {
			track: {
				meta: 'ICO Vehicle Condition CTA Clicked';
				opts: TradeInTrackingOpts;
				panel: 'TradeIn';
			};
			type: typeof TradeInType.SUBMIT_VEHICLE_INFORMATION;
	  };
