import React from 'react';
import PropTypes from 'prop-types';

const ChevronDownIcon = ({ color = '#818181', size = {} }) => (
	<svg height="7" width="10" xmlns="http://www.w3.org/2000/svg" {...size} viewBox="0 0 10 7">
		<path d="M1.178 0L5 4.33 8.822 0 10 1.335 5 7 0 1.335z" fill={color} fillRule="evenodd" />
	</svg>
);

ChevronDownIcon.propTypes = {
	color: PropTypes.string,
	size: PropTypes.shape({
		width: PropTypes.string,
		height: PropTypes.string,
	}),
};

export default ChevronDownIcon;
