// @ts-nocheck
/* eslint-disable */
var autofi = autofi || {};

/* istanbul ignore next */
autofi.utils = {
	addClass: function addClass(el, name) {
		el.classList.add(name);
	},
	removeClass: function removeClass(el, name) {
		el.classList.remove(name);
	},
	n100: function n100(n) {
		return Math.floor(n / 100) * 100 || 0;
	},
	updateElementNode: function updateElementNode(method, selector, content) {
		var $elements = document.querySelectorAll(selector);
		if ($elements) {
			for (var $$i = 0, $$length = $elements.length; $$i < $$length; $$i++) {
				$elements[$$i][method] = content;
			}
		}
	},
	formatAddress: function (address, oneLine) {
		return address.street + (oneLine ? ', ' : '\n') + address.city + ', ' + address.state + ' ' + address.zip;
	},
	formatPhone: function formatPhone(s) {
		if (!s || typeof s !== 'string') {
			return '';
		}
		var result = '';
		var numbers = s.replace(/\D/g, '');
		var char = { 0: '(', 3: ') ', 6: '-' };
		for (var i = 0; i < numbers.length; i++) {
			result += (char[i] || '') + numbers[i];
		}
		return result;
	},
	formatMessage: function formatMessage(string, options) {
		try {
			return autofi.globalize.messageFormatter(string)(options || {});
		} catch (e) {
			return string;
		}
	},
	formatRebateMessage: function formatRebateMessage(string, rebateOptions, options) {
		try {
			var rebateFormat = autofi.utils.get(rebateOptions, 'format', 'default');
			var shouldShowIncentivesText = autofi.utils.get(rebateOptions, 'shouldShowIncentivesText', false);
			var rebateLangKey = shouldShowIncentivesText
				? 'common/rebates-lang/incentives/' + rebateFormat
				: 'common/rebates-lang/rebates/' + rebateFormat;
			var localOptions = Object.defineProperty(options || {}, 'rebates-lang', {
				value: autofi.utils.formatMessage(rebateLangKey),
				enumerable: true,
			});
			return autofi.utils.formatMessage(string, localOptions);
		} catch (error) {
			return string;
		}
	},
	formatMessageWithDefault: function formatMessageWithDefault(input, options, defaultMessage) {
		try {
			return autofi.globalize.messageFormatter(input)(options || {});
		} catch (e) {
			return defaultMessage;
		}
	},
	formatUnit: function formatUnit(string, options) {
		try {
			string = autofi.globalize.messageFormatter('unit/' + string)();
		} catch (e) {
			/* noop error, lets use the default units instead */
		}

		try {
			return autofi.globalize.unitFormatter(string, options || {});
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error('i18n: missing ' + string);
			return function () {
				return string;
			};
		}
	},
	addCommas: function addCommas(num) {
		if (autofi.globalize) {
			return autofi.globalize.formatNumber(Number(num || 0));
		}

		var numStr = num + '';
		var val = numStr.split('.');
		var val1 = val[0];
		var val2 = val.length > 1 ? '.' + val[1] : '';
		var re = /(\d+)(\d{3})/;
		while (re.test(val1)) {
			val1 = val1.replace(re, '$1' + ',' + '$2');
		}
		return val1 + val2;
	},
	/* istanbul ignore next */
	getDealerPhone: function getDealerPhone(type, phones) {
		if (typeof phones === 'undefined') phones = window.autofi.data.dealer.phones;

		if (!phones || !phones.length) {
			return;
		}

		function findPhone(type) {
			return phones.filter(function (phone) {
				return phone.type === type;
			})[0];
		}

		// get first phone of given type or the first of DEFAULT type
		var phone = findPhone(type) || findPhone('DEFAULT');

		// return phone of type / default, or first phone when neither is present
		return phone ? phone : phones[0];
	},
	/* istanbul ignore next */
	round: function round(n, decimalPlaces) {
		var dp = Math.pow(10, decimalPlaces || 0);
		return Math.round(n * dp) / dp;
	},

	isMatchedPanel: function isMatchedPanel(currentPanel, targetPanel) {
		return currentPanel.toLowerCase().startsWith(targetPanel);
	},

	/* istanbul ignore next */
	addClickTracking: function addClickTracking($el, msg, submitAppText) {
		if ($el && typeof $el.addEventListener === 'function') {
			// Ignoring click events that were not initiated from user interaction
			$el.addEventListener('click', function (e) {
				if (
					(e.clientX === 0 &&
						e.screenX === 0 &&
						e.offsetX === 0 &&
						e.clientY === 0 &&
						e.screenY === 0 &&
						e.offsetY === 0) ||
					$el.classList.contains('disabled') ||
					$el.disabled === true
				) {
					// do nothing
				} else {
					msg.panel = msg.panel.replace(/ford$/, '');
					var isTradeInEvent = autofi.utils.isMatchedPanel(msg.panel, 'trade');
					var trackingData = autofi.utils.getExpressCheckoutTrackingData({}, isTradeInEvent);
					window.autofi.track(msg, trackingData);
					if (($el.innerText + '').trim() === submitAppText) {
						window.autofi.track({ action: 'submit application' }, trackingData);
					}
				}
			});
		}
	},

	/* istanbul ignore next */
	getExpressCheckoutTrackingData: function getExpressCheckoutTrackingData(obj, isTradeInEvent) {
		isTradeInEvent = Boolean(isTradeInEvent);
		obj = obj || {};
		obj.abTestGroup = autofi.utils.get(window, 'autofi.data.expressCheckout.abTestGroup');
		obj.dealer = autofi.utils.get(window, 'autofi.data.dealer.name');
		obj.defaultTab = autofi.utils.get(window, 'autofi.data.expressCheckout.defaultTab');
		obj.isTestUser = autofi.utils.get(window, 'autofi.data.applicant.isTestUser');
		obj.price =
			autofi.utils.get(window, 'autofi.data.vehicle.dealerRetailPrice', 0) -
			autofi.utils.get(window, 'autofi.data.loanInfo.totalDiscounts', 0);
		obj.vin = autofi.utils.get(window, 'autofi.data.vehicle.vin');
		obj.vehicleAge = autofi.utils.get(window, 'autofi.data.vehicle.age', '').toLowerCase();
		obj.panel = autofi.utils.get(window, 'autofi.data.currentStepName', '').replace(/ford$/, '');
		obj.state = autofi.utils.get(window, 'autofi.data.appState');
		obj.inStore = autofi.utils.get(window, 'autofi.data.isInStore');
		obj.buyNowVia = autofi.utils.get(window, 'autofi.data.buyNowVia');
		obj.loanAppId = autofi.utils.get(window, 'loanAppId');
		obj.consumerFlowVersion = autofi.utils.get(window, 'autofi.data.dealer.settings.consumerFlow.version');

		obj.appType = autofi.utils.getLoanAppType(window, 'autofi.data');

		var subpanel = $('[data-subpanel]:visible').attr('data-subpanel');
		if (subpanel) {
			obj.panelHeader = subpanel;
		}
		if (isTradeInEvent) {
			obj.tradeInFlow = autofi.utils.get(window, 'autofi.data.isTradeInPathway') ? 'pathway' : 'dm';
		}
		return obj;
	},
	/* istanbul ignore next */
	getExpressCheckoutPreferences: function (app, offerType) {
		offerType = offerType || autofi.utils.getLoanAppType(app);
		var preferences = autofi.utils.get(app, 'expressCheckout.preferences', {
			finance: {},
			lease: {},
		});
		var terms = preferences[offerType.toLowerCase()] || {};

		if (preferences.tradeIn) {
			terms.tradeIn = preferences.tradeIn;
		}

		if (preferences.creditBand) {
			terms.creditBand = preferences.creditBand;
		}

		terms.selectedDirectOfferIds = preferences.selectedDirectOfferIds || [];

		return terms;
	},

	/* istanbul ignore next */
	getBestOffer: function (app, offers) {
		offers = offers || autofi.utils.getOffers(app);

		return offers.length ? offers[0] : autofi.utils.get(app, 'pricing.matrix', [])[0];
	},

	// checks if the app has offers which have adjustable terms
	// terms are adjustable if there are multiple offers for any one lender
	// or any one lender's single offer has terms that allow for adjustment
	areTermsAdjustable: function (app) {
		var isLease = app.requestedOfferType === 'LEASE';
		var offers = autofi.utils.get(app, 'pricing.matrix', []);
		// TODO use _.groupBy once lodash is usable in here
		// i.e. groupBy(offers, 'lender.id');
		// https://www.pivotaltracker.com/story/show/162018349
		var offersByLender = offers.reduce(function (acc, offer) {
			acc[offer.lender.id] = (acc[offer.lender.id] || []).concat([offer]);
			return acc;
		}, {});

		var multiOfferLenders = Object.keys(offersByLender).filter(function (lender) {
			var lenderOffers = offersByLender[lender];

			if (lenderOffers.length > 1) {
				// there is more than one offer, so terms are adjustable
				return true;
			}

			// only one offer, so check if its terms are adjustable
			var offer = lenderOffers[0];
			var adjustableDown = offer.minDownPayment !== offer.maxDownPayment;
			var adjustableTerm = offer.minTerm !== offer.maxTerm;
			var adjustableMiles = isLease ? autofi.utils.get(offer, 'mileOptions', []).length > 1 : false;

			return adjustableDown || adjustableTerm || adjustableMiles;
		});

		return multiOfferLenders.length > 0;
	},
	/* istanbul ignore next */
	extend: function () {
		var resObj = {};
		for (var i = 0; i < arguments.length; i += 1) {
			var obj = arguments[i];
			var keys = Object.keys(obj);
			for (var j = 0; j < keys.length; j += 1) {
				resObj[keys[j]] = obj[keys[j]];
			}
		}
		return resObj;
	},
	/* istanbul ignore next */
	updateOffer: function (app, offer, offerType, filters, disableCashDownAdjustments = false) {
		var termMonths = filters.termMonths;
		var downPayment = filters.downPayment;
		var annualMiles = filters.annualMiles;

		if (autofi.utils.get(offer, 'mileOptions', []).length && offer.mileOptions.indexOf(annualMiles) === -1) {
			annualMiles = autofi.utils.closestNumber(offer.mileOptions, annualMiles);
		}

		if (autofi.utils.get(offer, 'termOptions', []).length && offer.termOptions.indexOf(termMonths) === -1) {
			termMonths = autofi.utils.closestNumber(offer.termOptions, termMonths);
		} else if (offer.minTerm && offer.minTerm > filters.termMonths) {
			termMonths = offer.minTerm;
		} else if (offer.maxTerm && offer.maxTerm < filters.termMonths) {
			termMonths = offer.maxTerm;
		}

		// Prevent blocking the offer when the rounded up filter won't fit between min/max down payment
		if (!disableCashDownAdjustments) {
			if (offer.minDownPayment === offer.maxDownPayment) {
				offer.minDownPayment = autofi.utils.up100(offer.minDownPayment);
				offer.maxDownPayment = autofi.utils.up100(offer.maxDownPayment);
			}
		}

		if (offer.minDownPayment && offer.minDownPayment > filters.downPayment) {
			downPayment = offer.minDownPayment;
		}

		if (offer.maxDownPayment && offer.maxDownPayment < filters.downPayment) {
			downPayment = offer.maxDownPayment;
		}

		offer.term = termMonths;
		offer.termMonths = termMonths;
		offer.downPayment = downPayment;
		offer.productsAmount = app.loanInfo.productsAmount;

		if (offerType === 'LEASE') {
			offer.annualMiles = annualMiles;
			offer.totalCash = Math.ceil(downPayment);

			var leasingData = autofi.calculator.getLeasingNumbers(app, offer);
			if (leasingData && leasingData.monthlyPayment) {
				offer.leasing = leasingData;
				offer.leasing.annualMileage = annualMiles;
				offer.biweeklyPayment = offer.leasing.biweeklyPayment;
				offer.monthlyPayment = offer.leasing.monthlyPayment;
				offer.effectiveRate = offer.leasing.effectiveRate;
			} else {
				return;
			}
		} else {
			var retailData = autofi.calculator.getFinancingNumbers(app, offer);
			if (retailData && retailData.monthlyPayment) {
				offer.biweeklyPayment = retailData.biweeklyPayment;
				offer.monthlyPayment = retailData.monthlyPayment;
				offer.amountFinanced = retailData.amountFinanced;
				offer.totalOfPayments = retailData.totalOfPayments;
				offer.totalInterest = retailData.totalInterest;
				offer.effectiveRate = retailData.effectiveRate;
			} else {
				return;
			}
		}

		return offer;
	},
	/* istanbul ignore next
	 *
	 * @param app {object} - the loan app object, i.e autofi.data
	 * @param filters {object} - filters to filter offers based on terms
	 * @param overrideDefaultFilters {bool} - will ingore default filters if true
	 */
	getOffers: function (app, filters, overrideDefaultFilters, disableCashDownAdjustments = false) {
		overrideDefaultFilters = overrideDefaultFilters || false;

		if (app.pricing.matrix.length === 0) {
			return [];
		}

		if (!overrideDefaultFilters) {
			filters = autofi.utils.extend(autofi.utils.defaultOfferFilters(app), filters || {});
		}

		var offerType = autofi.utils.getLoanAppType(app);
		var dp = typeof filters.downPayment === 'number' ? filters.downPayment : app.loanInfo.downPayment || 0;
		var term = filters.termMonths;
		var miles = filters.annualMiles;
		var offers = app.pricing.matrix;

		if (filters.lenderId) {
			offers = offers.filter(function (offer) {
				if (offer.lender.id === filters.lenderId) {
					return true;
				}
				return false;
			});
		}

		offers = offers
			.map(function (offer) {
				return autofi.utils.updateOffer(app, offer, offerType, filters, disableCashDownAdjustments);
			})
			.filter(function (offer) {
				if (!offer) {
					return;
				}

				offer.offerType = offer.offerType || 'FINANCE';
				var matchingTerm =
					offer.termOptions && offer.termOptions.length > 0
						? offer.termOptions.indexOf(term) > -1
						: term >= offer.minTerm && term <= offer.maxTerm;
				var matchingDownPayment = dp >= offer.minDownPayment && dp <= offer.maxDownPayment;
				var matchingAnnualMiles = offer.offerType === 'LEASE' ? offer.mileOptions.indexOf(miles) > -1 : true;
				var matchingOfferType = offer.offerType === offerType.toUpperCase();

				return (
					offer.monthlyPayment > 0 && matchingOfferType && matchingTerm && matchingDownPayment && matchingAnnualMiles
				);
			})
			.sort(function (a, b) {
				return a.monthlyPayment - b.monthlyPayment;
			});

		// dedupe offers -- only return one of each offer with matching terms & MP
		offers = offers.filter(function (offer, index) {
			var nextOffer = offers[index + 1];

			if (!nextOffer) {
				return true;
			}
			var offerRebates = autofi.utils.get(offer, 'rebates.total', 0);
			var nextOfferRebates = autofi.utils.get(nextOffer, 'rebates.total', 0);
			if (
				offer.monthlyPayment === nextOffer.monthlyPayment &&
				offerRebates === nextOfferRebates &&
				offer.downPayment === nextOffer.downPayment &&
				offer.termMonths === nextOffer.termMonths &&
				offer.apr === nextOffer.apr
			) {
				return false;
			}

			return true;
		});

		return offers;
	},

	// Logic duplicated from: ./clients/dealmaker/selectors/loanApplicaition.getSelectedOffer()
	// and ./lib/models/loanapp-models.selectedOffer()
	// ideally this would live in one place, could not get import to work.
	getSelectedOffer: function (app) {
		var downPayment = app.loanInfo.downPayment;
		var productsAmount = app.loanInfo.productsAmount;
		var selectedOfferId = app.loanInfo.selectedOfferId;
		var termMonths = app.loanInfo.termMonths;
		if (selectedOfferId) {
			var offer = app.pricing.matrix.find(function (el) {
				return el.id === selectedOfferId;
			});
			offer.termMonths = termMonths;
			offer.productsAmount = productsAmount;
			if (app.loanInfo.selectedOfferType === 'LEASE') {
				offer.annualMiles = app.loanInfo.leasing.annualMileage;
				offer.totalCash = downPayment;
			} else {
				offer.downPayment = downPayment;
			}
			return offer;
		}
		return null;
	},

	/* istanbul ignore next */
	getLoanAppType: function (app) {
		app.loanInfo = app.loanInfo || {};

		return (app.loanInfo.selectedOfferId && app.loanInfo.selectedOfferType) || app.requestedOfferType;
	},

	get: function (obj, path, defaultValue) {
		if (!obj) {
			return defaultValue;
		}

		path = path.split('.');

		var index = 0;
		var length = path.length;

		/* eslint-disable-next-line */
		while (obj != null && index < length) {
			obj = obj[path[index++]];
		}

		if (typeof obj === 'undefined' || obj === null) {
			return defaultValue;
		}

		return index && index === length ? obj : defaultValue;
	},

	/* istanbul ignore next */
	calculateAmountFinanced: function (app, offer, filters) {
		var amountFinanced = parseFloat(autofi.utils.get(app, 'vehicle.dealerRetailPrice', 0));
		amountFinanced -= filters.downPayment || offer.downPayment;
		amountFinanced -= app.tradeIn.amount || 0;
		amountFinanced -= app.loanInfo.totalDiscounts || 0;
		amountFinanced += app.loanInfo.totalTaxes || 0;
		amountFinanced += app.loanInfo.totalOtherFees || 0;
		amountFinanced -= (offer.rebates && offer.rebates.total) || 0;

		return amountFinanced;
	},

	/* istanbul ignore next */
	calculateFinanceMP: function (app, offer, filters) {
		return (
			Math.round(
				-100 *
					autofi.utils.pmt(
						offer.apr / 12,
						offer.term,
						autofi.utils.calculateAmountFinanced(app, offer, filters),
						autofi.utils.get(offer, 'firstPaymentDateDelay', 45),
						autofi.utils.get(offer, 'daysInAYear', 360)
					)
			) / 100
		);
	},

	/* istanbul ignore next */
	closestNumber: function closestNumber(numbers, number) {
		return numbers.reduce(function (prev, curr) {
			return Math.abs(curr - number) < Math.abs(prev - number) ? curr : prev;
		});
	},

	/* istanbul ignore next */
	defaultOfferFilters: function (app, offersSubSet) {
		var offers = offersSubSet || app.pricing.matrix;
		var offerType = autofi.utils.getLoanAppType(app);
		var preferences = autofi.utils.getExpressCheckoutPreferences(app) || {};
		var filters = {};
		var lowestDPOffer;
		var initialMiles;
		var defaultTerm = offerType === 'LEASE' ? 36 : 60;
		var initialTerm = preferences.termMonths || defaultTerm;
		var initialOffers = offers
			.filter(function (offer) {
				var matchingTerm =
					offer.termOptions && offer.termOptions.length
						? offer.termOptions.indexOf(initialTerm) > -1
						: initialTerm >= offer.minTerm && initialTerm <= offer.maxTerm;

				return autofi.utils.get(offer, 'offerType', app.requestedOfferType) === offerType && matchingTerm;
			})
			.sort(function (a, b) {
				return a.minDownPayment - b.minDownPayment;
			});
		if (initialOffers && initialOffers.length) {
			lowestDPOffer = initialOffers[0];
		} else {
			lowestDPOffer = offers[0];
			initialTerm = lowestDPOffer.minTerm;
		}
		// populate filters with express checkout preferences if we have it
		// otherwise use initial term and lowest down payment for initial term
		filters.termMonths =
			preferences &&
			preferences.termMonths &&
			preferences.termMonths >= lowestDPOffer.minTerm &&
			preferences.termMonths <= lowestDPOffer.maxTerm
				? preferences.termMonths
				: initialTerm;
		filters.downPayment = (preferences && preferences.downPayment) || 0;

		if (filters.downPayment < lowestDPOffer.minDownPayment) {
			filters.downPayment = autofi.utils.up100(lowestDPOffer.minDownPayment);
		}

		if (offerType === 'LEASE') {
			initialMiles =
				lowestDPOffer.mileOptions.indexOf(15000) > -1
					? 15000
					: lowestDPOffer.mileOptions[lowestDPOffer.mileOptions.length / 2 - 1];
			filters.annualMiles = (preferences && preferences.annualMileage) || initialMiles;
		}

		var maxDP = offers
			.map(function (p) {
				return p.maxDownPayment;
			})
			.sort(function (a, b) {
				return b - a;
			})[0];

		if (filters.downPayment > maxDP) {
			filters.downPayment = maxDP;
		}

		if (offerType === 'LEASE' && !autofi.utils.getOffers(app, filters, true).length) {
			filters.annualMiles = initialMiles;
		}

		if (!autofi.utils.getOffers(app, filters, true).length) {
			filters.downPayment = autofi.utils.closestNumber(
				offers.map(function (offer) {
					return offer.minDownPayment;
				}),
				filters.downPayment
			);
		}

		filters.downPayment = autofi.utils.n100(filters.downPayment);

		return filters;
	},

	/* istanbul ignore next */
	isProductSelectedByOfferType: function (product, requestedOfferType) {
		if (requestedOfferType === 'CASH') {
			var isCredit = product.category === 'gap waiver';
			var isFamily = product.category === 'credit life';
			var isCashProduct = !isCredit && !isFamily;
			return product.userSelected && isCashProduct;
		}

		return product.userSelected && product.offerType === requestedOfferType;
	},

	/* istanbul ignore next */
	up100: function up100(value) {
		return Math.ceil(value / 100) * 100;
	},

	/* istanbul ignore next */
	getProductPaymentAmount: function getProductPaymentAmount(product) {
		// TODO: remove references to window
		var loanApp = JSON.parse(JSON.stringify(window.autofi.data));
		var productCopy = JSON.parse(JSON.stringify(product));
		var validApr = loanApp.loanInfo.selectedOfferType === 'LEASE' ? true : !isNaN(parseFloat(loanApp.loanInfo.apr));
		var validTermMonths = !!loanApp.loanInfo.termMonths;
		var validPrice = !!product.suggestedRetailPrice || !!product.suggestedRetailPricePretax;

		if (!validApr || !validTermMonths || !validPrice) {
			return null;
		}

		// get payment for the loanApp when it has no products in productsAmount
		loanApp.loanInfo.productsAmount = 0;
		loanApp.productsOffered = [];
		var offer = autofi.utils.getSelectedOffer(loanApp);
		var noProductNumbers = window.autofi.calculator.getNumbers(loanApp.requestedOfferType, loanApp, offer);
		var paymentWithoutProducts =
			loanApp.requestedPaymentInterval === 'BIWEEKLY'
				? noProductNumbers.biweeklyPayment
				: noProductNumbers.monthlyPayment;
		// get payment for the loanApp when it has this product in productsAmount
		loanApp.loanInfo.productsAmount = product.suggestedRetailPricePretax;
		productCopy.userSelected = true;
		loanApp.productsOffered = [productCopy];
		offer = autofi.utils.getSelectedOffer(loanApp);
		var withProductNumbers = autofi.calculator.getNumbers(loanApp.requestedOfferType, loanApp, offer);
		var paymentWithProducts =
			loanApp.requestedPaymentInterval === 'BIWEEKLY'
				? withProductNumbers.biweeklyPayment
				: withProductNumbers.monthlyPayment;

		if (!paymentWithProducts || !paymentWithoutProducts) {
			// calculator returned falsey monthly payment, so we couldnt calculate
			return null;
		}

		// determine product MP based By subtracting the base MP from the MP w/ it
		return paymentWithProducts - paymentWithoutProducts;
	},

	/* istanbul ignore next */
	productTotal: function productTotal(product, loanApp) {
		loanApp = loanApp || window.autofi.data;
		var appType =
			(loanApp.loanInfo.selectedOfferId && loanApp.loanInfo.selectedOfferType) || loanApp.requestedOfferType;

		var existAprOrMoneyFactor = function existAprOrMoneyFactor(offerType, loanInfo) {
			if (offerType === 'LEASE') {
				return loanApp.loanInfo.apr === null && loanApp.loanInfo.leasing.moneyFactor === null;
			}

			return loanInfo.apr === null;
		};

		if (
			!loanApp.loanInfo ||
			loanApp.loanInfo.termMonths === null ||
			product.suggestedRetailPrice === null ||
			product.suggestedRetailPricePretax === null ||
			existAprOrMoneyFactor(appType, loanApp.loanInfo)
		) {
			return null;
		}

		// showProductsBasePrice removal: remove the following if block
		if (!window.launchDarklyFeatureFlags.showProductsBasePrice) {
			return parseFloat(appType === 'LEASE' ? product.suggestedRetailPricePretax : product.suggestedRetailPrice);
		}

		return parseFloat(product.suggestedRetailPricePretax);
	},

	/* istanbul ignore next */
	pmt: function pmt(ratePerPeriod, term, amountFinanced, firstPaymentDayOffset, daysInAYear) {
		var numberOfDays = (firstPaymentDayOffset || 30) - 30;
		var additionalInterest = this.dailyInterestFee(
			amountFinanced,
			ratePerPeriod * 12,
			numberOfDays,
			daysInAYear || 360
		);

		amountFinanced += additionalInterest;
		if (ratePerPeriod) {
			return (
				(-amountFinanced * Math.pow(1 + ratePerPeriod, term) * ratePerPeriod) / (Math.pow(1 + ratePerPeriod, term) - 1)
			);
		} else {
			return -(amountFinanced / term);
		}
	},

	/* istanbul ignore next */
	pmt2: function pmt2(ratePerPeriod, numberOfPayments, presentValue, futureValue, type) {
		if (ratePerPeriod !== 0) {
			// Interest rate exists
			var q = Math.pow(1 + ratePerPeriod, numberOfPayments);
			return -(ratePerPeriod * (futureValue + q * presentValue)) / ((-1 + q) * (1 + ratePerPeriod * type));
		} else if (numberOfPayments !== 0) {
			// No interest rate, but number of payments exists
			return -(futureValue + presentValue) / numberOfPayments;
		}

		return 0;
	},

	dailyInterestFee: function dailyInterestFee(amountFinanced, apr, numberOfDays, daysInAYear) {
		daysInAYear = daysInAYear || 360;
		return this.round((amountFinanced * apr * numberOfDays) / daysInAYear, 2);
	},

	/**
	 * @param  {number} - number to be formatted
	 * @param  {number} - number of decimal places to show
	 * @param  {number} - show commas
	 * @return {string} - returns formatted number $2,223.99
	 */
	/* istanbul ignore next */
	money: function money(num, n, x, currencyCode) {
		var money = num + '';
		var prefix = '$';
		var float = parseFloat(money.replace(/[$,\s]/g, ''));
		var globalize = autofi.globalize;

		if (globalize) {
			currencyCode = currencyCode || autofi.utils.getCurrencyCode();

			return globalize.currencyFormatter(currencyCode, {
				minimumFractionDigits: n,
				maximumFractionDigits: n,
			})(float);
		}
		try {
			// prepend "-" to the prefix for negative amounts of money
			prefix = float >= 0 ? prefix : '-' + prefix;

			money = Math.abs(float)
				.toFixed(n)
				.replace(/./g, function (c, i, a) {
					return i && i !== 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
				});
		} catch (ex) {
			// just for linting purposes
		}
		return prefix + money;
	},
	/* istanbul ignore next */
	getCurrencyCode: function getCurrencyCode() {
		if (typeof window !== 'undefined') {
			return autofi.utils.get(window.autofi, 'data.dealer.settings.currencyCode', 'USD');
		} else {
			return autofi.utils.get(autofi, 'currencyCode', 'USD');
		}
	},
	/* istanbul ignore next */
	getCurrencySymbol: function getCurrencySymbol() {
		var currencyCode = autofi.utils.getCurrencyCode();

		if (!autofi.globalize) {
			return '$';
		}

		return autofi.globalize.cldr.main('numbers/currencies/' + currencyCode + '/symbol');
	},
	/* istanbul ignore next */
	toPercent: function formatPercent(num, sig, opts) {
		if (sig === undefined) {
			sig = 2;
		}
		if (window.Globalize) {
			return window.Globalize.numberFormatter(
				opts || {
					style: 'percent',
					minimumFractionDigits: sig,
					maximumFractionDigits: sig,
				}
			)(Number(num || 0));
		}
		return (num * 100).toFixed(sig) + '%';
	},

	/* istanbul ignore next */
	moneyToFloat: function (amount) {
		return parseFloat(amount.replace(/[$,\s]/g, ''));
	},

	/* istanbul ignore next */
	find: function (arr, fnselect, fnreturn) {
		for (var i = 0; i < arr.length; i++) {
			if (fnselect(i)) {
				return fnreturn(i);
			}
		}
	},

	/* istanbul ignore next */
	populateTemplate: function populateTemplate($template, obj) {
		var $html = $template;
		Object.keys(obj).forEach(function (key) {
			var val = obj[key];
			var re = new RegExp('{{' + key + '}}', 'g');
			$html = $html.replace(re, val);
		});
		return $html;
	},

	states:
		'AL|Alabama|AK|Alaska|AZ|Arizona|AR|Arkansas|CA|California|CO|Colorado|CT|Connecticut|DE|Delaware|FL|Florida|GA|Georgia|HI|Hawaii|ID|Idaho|IL|Illinois|IN|Indiana|IA|Iowa|KS|Kansas|KY|Kentucky|LA|Louisiana|ME|Maine|MD|Maryland|MA|Massachusetts|MI|Michigan|MN|Minnesota|MS|Mississippi|MO|Missouri|MT|Montana|NE|Nebraska|NV|Nevada|NH|New Hampshire|NJ|New Jersey|NM|New Mexico|NY|New York|NC|North Carolina|ND|North Dakota|OH|Ohio|OK|Oklahoma|OR|Oregon|PA|Pennsylvania|RI|Rhode Island|SC|South Carolina|SD|South Dakota|TN|Tennessee|TX|Texas|UT|Utah|VT|Vermont|VA|Virginia|WA|Washington|WV|West Virginia|WI|Wisconsin|WY|Wyoming',

	/* istanbul ignore next */
	radioButtons: function (name, click, useCheck) {
		var $radioButtons = $('input[name="' + name + '"]');
		var selectedClass = useCheck ? 'af-check-square' : 'af-dot-circle-o';
		var deselectedClass = useCheck ? 'af-square' : 'af-circle-o';
		$radioButtons.each(function () {
			var $this = $(this);
			$this.parent().prepend('<i class="af ' + deselectedClass + '"></i><i class="af ' + selectedClass + '"></i>');
			$this.parent().wrap('<div class="custom-radio"></div>');
			if ($this.is(':checked')) {
				$this.parent().parent().addClass('selected');
			}
		});
		$radioButtons.click(function () {
			var $this = $(this);
			var isNotThis = $radioButtons.not(this);
			var deselectCheckBox = $this.is(':checked') && useCheck;

			isNotThis.each(function () {
				var $this = $(this);
				$this.parent().parent().removeClass('selected');
			});
			// Need to make sure the radio get checked before changing the class
			setTimeout(function () {
				if (deselectCheckBox) {
					$this.parent().parent().removeClass('selected');
					$this.removeAttr('checked');
				}
				if ($this.is(':checked')) {
					$this.parent().parent().addClass('selected');
				}
				if (click) {
					click($this);
				}
			}, 0);
		});
	},

	/* istanbul ignore next */
	dateFormat: function (dt) {
		if (!dt) {
			return '';
		}
		if (typeof dt === 'string') {
			dt = new Date(dt);
		}
		return dt.getMonth() + 1 + '/' + dt.getDate() + '/' + dt.getFullYear();
	},

	/* istanbul ignore next */
	streetAddressFormat: function (address) {
		// only uses street address when input if filled with google
		// response that includes country
		var UNITED_STATES = 'UNITED STATES';
		var delineatedAddress = address.split(',');
		var lastItem = delineatedAddress[delineatedAddress.length - 1];

		if (lastItem.toUpperCase().trim() === UNITED_STATES) {
			return delineatedAddress[0];
		}
		return address;
	},

	/* istanbul ignore next */
	fullDateFormat: function fullDateFormat(dt) {
		if (!dt) {
			return '';
		}
		if (typeof dt === 'string') {
			dt = new Date(dt);
		}
		var months = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];
		return months[dt.getMonth()] + ' ' + nthOfMonth(dt) + ', ' + dt.getFullYear();
	},

	nthOfMonth: nthOfMonth,

	/* istanbul ignore next */
	phoneFormat: function formatPhone(s) {
		var result = '';
		var numbers = s.replace(/\D/g, '');
		var char = { 0: '(', 3: ') ', 6: ' - ' };
		for (var i = 0; i < numbers.length; i++) {
			result += (char[i] || '') + numbers[i];
		}
		return result;
	},

	/* istanbul ignore next */
	shuffle: function shuffle(array) {
		var currentIndex = array.length;
		var temporaryValue;
		var randomIndex;
		while (0 !== currentIndex) {
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;
			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}
		return array;
	},

	/* istanbul ignore next */
	alert: function swalalert(title, msg, type, buttonText, cb) {
		var parsedMsg;
		var alertMsg;
		var errorJson = '';

		if (typeof buttonText === 'function') {
			cb = buttonText;
			buttonText = null;
		}
		try {
			parsedMsg = $.parseJSON(msg);
		} catch (e) {
			// just fixing some linting issues
		}

		if (parsedMsg && parsedMsg.error) {
			alertMsg = parsedMsg.error.message || 'Sorry, something went wrong. Please try again.';
			errorJson = JSON.stringify(parsedMsg.error);
		} else {
			alertMsg = msg || 'Sorry, something went wrong. Please try again.';
		}

		type = type || 'error';
		var opts = {
			title: title,
			msg: msg,
			type: type,
			app: 'Consumer',
			logType: 'Client Alert Log',
		};
		autofi.utils.logValues(opts);
		window.autofi.track('Alert: ' + opts.type, opts);

		var sweeetAlertsOpts = {
			title: title,
			text: alertMsg + '<div style="display:none">' + errorJson + '</div>',
			type: type,
			customClass: 'swal-custom-class',
			html: true,
		};

		if (buttonText) {
			sweeetAlertsOpts.confirmButtonText = buttonText;
		}
		if (window.swal) {
			window.swal(sweeetAlertsOpts, cb);
		} else {
			alert(title + '\n' + alertMsg);
			cb && cb();
		}
	},
	fadeIn: function fadeIn(el, opts, callback) {
		el.style.opacity = 0;
		el.style.display = 'block';

		(function fade() {
			var val = parseFloat(el.style.opacity);
			val = (val * 10 + 1) / 10;
			if (!(val > 1)) {
				el.style.opacity = val;
				if (window.requestAnimationFrame) {
					requestAnimationFrame(fade);
				} else {
					fade();
				}
			} else {
				if (callback) {
					callback();
				}
			}
		})();
	},
	fadeOut: function fadeOut(el, callback) {
		el.style.opacity = 1;

		(function fade() {
			var val = parseFloat(el.style.opacity);
			val = (val * 10 - 1) / 10;
			if (val <= 0) {
				el.style.display = 'none';
				if (callback) {
					callback();
				}
			} else {
				el.style.opacity = val;
				if (window.requestAnimationFrame) {
					requestAnimationFrame(fade);
				} else {
					fade();
				}
			}
		})();
	},

	div: function div() {
		return [].slice.call(arguments).reduce(function (prev, curr) {
			if (!prev) {
				return curr.split('.').reduce(function (pr, cu) {
					if (!pr) {
						return document.createElement(!cu ? 'div' : cu);
					} else {
						pr.classList.add(cu);
						return pr;
					}
				}, '');
			}
			if (typeof curr === 'string') {
				prev.innerHTML = curr;
			} else {
				if (!Array.isArray(curr)) {
					for (var i in curr) {
						if (i.slice(0, 2) === 'on') {
							prev.addEventListener(i.slice(2), curr[i], false);
						} else {
							if (typeof curr[i] !== 'undefined') {
								prev.setAttribute(i, curr[i]);
							}
						}
					}
				}
			}
			return prev;
		}, '');
	},

	/* istanbul ignore next */
	formBuilder: function formBuilder(panel, dom, results, opts) {
		results = results || {};
		opts = opts || { autofocus: true };
		dom.$h1.text(panel.h1);
		dom.$h5.text(panel.h5);
		dom.$h6.text(panel.h6);
		dom.$back[panel.step > 0 ? 'show' : 'hide']();
		dom.$continue.text(panel.cont);
		dom.$form.html('');
		dom.$review.html('');
		dom.$innerTop.attr('id', panel.name);

		panel.form.map(function (inputEl) {
			if (Array.isArray(inputEl)) {
				inputEl.map(function (inputEl) {
					if (!inputEl.render) {
						return null;
					}
					makeForm(inputEl);
				});
			} else {
				if (!inputEl.render) {
					return null;
				}
				makeForm(inputEl);
			}
			dom.$form.append(autofi.utils.div('.clearfix'));
			dom.$form.append(autofi.utils.div('.afi-v2-space-15'));
		});

		if (panel.name && panel.name === 'disclosure') {
			disclosure(panel);
		} else {
			$('.afi-v2-notices').hide();
		}

		// scroll to the top of the next panel
		window.scrollTo(0, 0);
		dom.$panel.fadeIn();

		// last things we do in render() is check if the panel is valid or focus input
		if (panel.valid) {
			dom.$continue.removeClass('disabled');
		} else {
			dom.$continue.addClass('disabled');
			if (panel.form.length && opts.autofocus) {
				var k = document.querySelectorAll('#pii .afi-v2-form input.afi-v2-apply');
				for (var i = 0; i < k.length; i++) {
					if (!k[i].value) {
						return setTimeout(function () {
							$(k[i]).focus();
						}, 150);
					}
				}
			}
		}

		function form(panel) {
			var ret = [];
			panel.form.map(function (i) {
				if (Array.isArray(i)) {
					i.map(function (j) {
						ret.push(j);
					});
				} else {
					ret.push(i);
				}
			});
			return ret;
		}

		function fillInAddress() {
			var cForm = {
				street_number: 'short_name',
				route: 'long_name',
				locality: 'long_name',
				administrative_area_level_1: 'short_name',
				country: 'long_name',
				postal_code: 'short_name',
			};
			try {
				var getPlace = maps.getPlace();
				if (!getPlace.address_components) {
					var cmap = ['route', 'locality', 'administrative_area_level_1', 'country'];
					getPlace.name.split(',').map(function (v, i) {
						cForm[cmap[i]] = v.trim();
					});
				} else {
					getPlace.address_components.map(function (i) {
						if (i.types[0] in cForm) {
							return (cForm[i.types[0]] = i[cForm[i.types[0]]]);
						}
					});
				}
				var pacs = document.querySelectorAll('.pac-container');
				var l = pacs.length;

				while (l--) {
					document.body.removeChild(pacs[l]);
				}

				panel.form2[0].val = panel.form[0].val;
				panel.form = panel.form2;
				panel.form.map(function (pi) {
					if (Array.isArray(pi)) {
						pi.map(function (i) {
							switch (i.name) {
								case 'street':
									if (cForm.street_number !== 'short_name') {
										i.valid = 1;
										i.val = cForm.street_number + ' ' + cForm.route;
									} else {
										i.val = cForm.route;
									}
									return makeForm(i, true);
								case 'state':
									if (cForm.administrative_area_level_1 !== 'short_name') {
										i.valid = 1;
										i.val = cForm.administrative_area_level_1;
									} else {
										i.val = '';
									}
									return makeForm(i, true);
								case 'zip':
									if (cForm.postal_code !== 'short_name') {
										i.valid = 1;
										i.val = cForm.postal_code;
									} else {
										i.val = '';
									}
									return makeForm(i, true);
								case 'city':
									if (cForm.locality !== 'long_name') {
										i.valid = 1;
										i.val = cForm.locality;
									} else {
										i.val = '';
									}
									return makeForm(i, true);
								default:
									break;
							}
						});
					}
				});
			} catch (ex) {
				// more linting stuff
			}
		}

		function iM(masks, max, event) {
			var c = event.target;
			var v = c.value.replace(/\D/g, '');
			var m = c.value.length > max ? 1 : 0;

			window.VMasker(c).unMask();
			window.VMasker(c).maskPattern(masks[m]);
			c.value = window.VMasker.toPattern(v, masks[m]);
		}

		function makeForm(inputCfg, reUseExisting) {
			var el;

			if (reUseExisting) {
				var $existingInput = dom.$form.find('.afi-v2-input [data-pos="' + inputCfg.pos + '"]');
				if ($existingInput) {
					el = $existingInput.parent('.afi-v2-input').get(0);
					el.innerHTML = '';
				}
			}

			if (!el) {
				el = autofi.utils.div('.afi-v2-input');
				reUseExisting = false;
			}

			if (inputCfg.hidden) {
				el.style.display = 'none';
			}

			switch (inputCfg.type) {
				case 'radiobtn':
					return fradio(el, panel, inputCfg);
				case 'dropdown':
					return fdropdown(el, panel, inputCfg);
			}

			var input = autofi.utils.div('input.afi-v2-apply', {
				name: inputCfg.name,
				onkeyup: keyup,
				onfocus: focus,
				onblur: blur,
				oninput: validate,
				onpaste: inputCfg.addr && window.isIE ? paste : function () {},
				'data-pos': inputCfg.pos,
				'data-addr': inputCfg.addr ? inputCfg.addr : '',
				value: inputCfg.val,
				type: inputCfg.type,
				maxlength: inputCfg.maxlength,
				disabled: inputCfg.disabled,
			});
			var label = autofi.utils.div('label.afi-v2-c', inputCfg.placeholder);

			/*
			 * input masks and other visual setup
			 */
			switch (inputCfg.mask) {
				case 'phone':
					setupMask(['(999) 999-9999', '(999) 999-9999'], 10);
					break;
				case 'money':
					setupMoneyMask();
					break;
				default:
					break;
			}

			function setupMoneyMask() {
				moneyMask();
				$(input).keyup(function () {
					moneyMask();
				});
			}

			function moneyMask() {
				var val = $(input).val().replace(/\D+/g, '').substring(0, 8);
				val.length ? $(input).val(autofi.utils.money(val)) : $(input).val('');
			}

			function setupMask(m, max) {
				window.VMasker(input).maskPattern(m[0]);
				input.addEventListener('input', iM.bind(undefined, m, max), false);
			}

			var border = autofi.utils.div('.afi-v2-input-border.afi-v2-bg');
			var hint = autofi.utils.div('.afi-v2-hint');
			var place = autofi.utils.div(
				'.af-placeholder',
				{
					onclick: function () {
						input.focus();
					},
				},
				inputCfg.placeholder
			);

			place.style.display = inputCfg.val ? 'none' : 'inline';

			if (inputCfg.hint && inputCfg.hint.length) {
				if (inputCfg.hintStyle) {
					$(hint).html('<p style="' + inputCfg.hintStyle + '">' + inputCfg.hint + '</p>');
				} else {
					$(hint).html('<p>' + inputCfg.hint + '</p>');
				}
			}

			if (inputCfg.hintbtns) {
				var hbtn = autofi.utils.div('.afi-v2-hint-btn');

				inputCfg.hintbtns.map(function (b) {
					hbtn.appendChild(
						autofi.utils.div(
							'p.afi-v2-bg-fast',
							{
								onclick: function (e) {
									if (e.currentTarget.textContent === 'Confirm') {
										hint.style.display = 'none';
										inputCfg.valid = 1;
										inputCfg.confirmed = 1;

										validate({ currentTarget: input }, 1);
									}
								},
							},
							b
						)
					);
				});

				hint.appendChild(hbtn);
			}

			if (inputCfg.val) {
				label.classList.add('afi-v2-label-val');

				validate({ currentTarget: input }, 1);
			}

			if (inputCfg.width) {
				el.classList.add('afi-v2-width-' + inputCfg.width);
			}

			el.appendChild(label);
			el.appendChild(place);
			el.appendChild(input);
			el.appendChild(border);

			el.appendChild(hint);

			if (!reUseExisting) {
				dom.$form.append(el);
			}

			function focus(e) {
				border.classList.add('afi-v2-input-focus');
				label.classList.remove('afi-v2-label-val');
				label.classList.add('afi-v2-label-focus');
				if (e.currentTarget.getAttribute('data-addr')) {
					if (window.isIE && p.form.length > 2) {
						return;
					}
					if (google) {
						maps = new google.maps.places.Autocomplete(e.currentTarget, {
							types: ['address'],
						});
						maps.addListener('place_changed', fillInAddress);
						place.style.display = 'none';
						if (!window.isIE) {
							e.currentTarget.placeholder = '';
						} else {
							if ('Enter a location' === e.currentTarget.value) {
								e.currentTarget.value = '';
							}
						}
					} else {
						// offline support
						if (panel.form.length < 3) {
							panel.form2[0].val = panel.form[0].val;
							panel.form = panel.form2;
							autofi.utils.formBuilder(panel, dom, results);
						}
					}
				}
			}
			function blur(e) {
				border.classList.remove('afi-v2-input-focus');
				label.classList.remove('afi-v2-label-focus');

				if (e.currentTarget.value) {
					label.classList.add('afi-v2-label-val');
				} else {
					if (e.currentTarget.name === 'ssnlast4') {
						place.textContent = inputCfg.placeholder;
					}
				}

				// show any non-immediate input validation errors
				var pos = e.currentTarget.getAttribute('data-pos');
				var formItem = pos.length > 1 ? panel.form[Number(pos[0])][Number(pos[2])] : panel.form[Number(pos)];

				if (!formItem.valid) {
					label.classList.add('afi-v2-label-val-err');
					border.classList.add('afi-v2-input-err');
				}

				hint.style.display = formItem.valid ? 'none' : 'block';

				validate(e);
			}
			function keyup(e) {
				place.style.display = e.currentTarget.value ? 'none' : 'inline';

				switch (e.currentTarget.name) {
					case 'address':
						if (!google) {
							return;
						}
						if ('Enter a location' === e.currentTarget.value) {
							e.currentTarget.value = '';
							return;
						}
						if (e.currentTarget.value.length > 5) {
							var pacs = document.querySelectorAll('.pac-container');
							if (pacs[0] && pacs[0].style.display === 'none') {
								var l = pacs.length;
								while (l--) {
									document.body.removeChild(pacs[l]);
								}
								if (panel.form.length > 2) {
									return;
								}
								panel.form2[0].val = panel.form[0].val;
								panel.form = panel.form2;
								panel.form[1][0].val = e.currentTarget.value;
								render(panel, { autofocus: false });
								setTimeout(function () {
									var input = $form[1] || $form[0];
									if (input) {
										var field = $(input.querySelector('input[name="address"]'));
										field.focus().val(field.val());
									}
								}, 90);
							}
						}
						break;
				}

				validate(e);
			}
			function paste(e) {
				e.currentTarget.blur();

				setTimeout(function () {
					if (e.currentTarget) {
						e.currentTarget.focus();
					}
				}, 50);
			}
			function validate(e) {
				var pos = e.currentTarget.getAttribute('data-pos');
				var formItem = pos.length > 1 ? panel.form[Number(pos[0])][Number(pos[2])] : panel.form[Number(pos)];

				formItem.val = e.currentTarget.value;

				switch (typeof formItem.validate) {
					case 'function':
						formItem.valid = formItem.validate(formItem.val);
						break;
					default:
						formItem.valid = true;
						break;
				}

				// render error state on input only if it's valid or has an error to display
				// all other error states are rendered on blur
				if (formItem.valid || hint.style.display === 'none') {
					label.classList.remove('afi-v2-label-val-err');
					border.classList.remove('afi-v2-input-err');
					hint.style.display = 'none';
				} else if (hint.style.display === 'block') {
					label.classList.add('afi-v2-label-val-err');
					border.classList.add('afi-v2-input-err');
				}

				// always check for panel validity so cont button can turn green
				panel.valid = !panel.form.filter(function (input) {
					// validate nested address inputs
					if (input.length) {
						return !!input.filter(function (i) {
							return i.validate && !i.valid;
						}).length;
					}
					return input.validate && !input.valid;
				}).length;

				dom.$continue[0].classList[panel.valid ? 'remove' : 'add']('disabled');
			}
		}

		function fradio(el, pan, rad) {
			el.classList.add('afi-v2-input-radio');
			el.appendChild(autofi.utils.div('.afi-v2-space-20'));
			el.appendChild(autofi.utils.div('h4.title', rad.prompt));

			var btnContainer = autofi.utils.div('.clearfix');
			var btns = rad.btns.map(function (btn) {
				var radio = autofi.utils.div('.secondary-btn.expand', {
					tabindex: btn.tabindex,
					onkeyup: selectRadio.bind(pan),
					onclick: selectRadio.bind(pan),
				});
				var evenOdd = rad.btns.length % 3 ? '6' : '4';
				var col = autofi.utils.div('.col-xs-12.col-sm-' + evenOdd + '.afi-v2-option.clearfix');

				radio.appendChild(autofi.utils.div('p', btn.text));
				col.appendChild(radio);
				btnContainer.appendChild(col);

				if (results[pan.name][rad.name]) {
					if (new RegExp(results[pan.name][rad.name], 'i').test(btn.text)) {
						setTimeout(function () {
							radio.click();
						}, 100);
					}
				}
				return radio;
			});

			function selectRadio(e) {
				if (e.type === 'keyup' && e.which !== 13) {
					return false;
				}
				btns.map(function (i) {
					i.classList.remove('active');
				});
				e.currentTarget.classList.add('active');
				rad.valid = 1;
				rad.val = e.currentTarget.textContent;
				pan.valid = !pan.form.filter(function (f) {
					return !f.valid;
				}).length;
				dom.$continue[0].classList[pan.valid ? 'remove' : 'add']('disabled');
			}

			el.appendChild(btnContainer);

			// attach hint to radio field
			if (rad.hint) {
				var hintWithDealer = rad.hint.replace('{{DEALER}}', window.autofi.data.dealer.name);
				el.appendChild(autofi.utils.div('.afi-v2-hint', hintWithDealer));
			}

			dom.$form.append(el);
		}

		function fdropdown(el, pan, sel) {
			el.classList.add('afi-v2-input-dropdown');
			el.appendChild(autofi.utils.div('.afi-v2-space-20'));
			el.appendChild(autofi.utils.div('h4.title', sel.prompt));

			var selectContainer = autofi.utils.div('.clearfix');
			var opts = sel.opts.map(function (opt) {
				var option = autofi.utils.div('.secondary-btn.expand', {
					tabindex: opt.tabindex,
					onkeyup: selectOption.bind(pan),
					onclick: selectOption.bind(pan),
				});
				var col = autofi.utils.div('.col-xs-12.afi-v2-option.clearfix');

				option.appendChild(autofi.utils.div('p', opt.text));
				col.appendChild(option);
				selectContainer.appendChild(col);

				if (results[pan.name][sel.name]) {
					if (new RegExp(results[pan.name][sel.name], 'i').test(opt.text)) {
						setTimeout(function () {
							option.click();
						}, 100);
					}
				}
				return option;
			});

			function selectOption(e) {
				if (e.type === 'keyup' && e.which !== 13) {
					return false;
				}
				opts.map(function (i) {
					i.classList.remove('active');
				});
				e.currentTarget.classList.add('active');
				sel.valid = 1;
				sel.val = e.currentTarget.textContent;
				pan.valid = !pan.form.filter(function (f) {
					return !f.valid;
				}).length;
				dom.$continue[0].classList[pan.valid ? 'remove' : 'add']('disabled');
			}

			el.appendChild(selectContainer);

			dom.$form.append(el);
		}

		function disclosure(d) {
			// reset the disclosure page to force esign agreement
			d.reset();

			var notices = autofi.utils.div('.afi-v2-notices');
			var textWrap = autofi.utils.div('.afi-v2-text');
			textWrap.innerHTML = autofi.utils.unescapeHtml();
			notices.appendChild(textWrap);
			dom.$disclosure.append(notices);
			dom.$innerTop.addClass('disclo-review');

			// TODO: this needs to be fixed, having issues with scroll handler
			d.valid = 1;
		}
	},

	/* istanbul ignore next */
	escapeHTML: function (str) {
		if (str && str.replace) {
			var escapedStr = str
				.replace(/&/g, '&amp;')
				.replace(/"/g, '&quot;')
				.replace(/'/g, '&#x27;')
				.replace(/</g, '&lt;')
				.replace(/>/g, '&gt;')
				.replace(/\//g, '&#x2F;')
				.replace(/\\/g, '&#x5C;')
				.replace(/`/g, '&#96;');
			return escapedStr;
		}
		return str;
	},

	/* istanbul ignore next */
	unescapeHtml: function (encodedHtml) {
		var el = document.createElement('textarea');
		el.innerHTML = encodedHtml;
		return el.value;
	},

	/* istanbul ignore next */
	logValues: function (values) {
		try {
			if (typeof values === 'string') {
				console.log(values);
			} else {
				Object.keys(values).map(function (a) {
					console.log(a, values[a]);
				});
			}
		} catch (ex) {
			return void 0;
		}
	},

	/* istanbul ignore next */
	isEmail: function (str) {
		// http://stackoverflow.com/a/46181
		return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			str
		);
	},

	/* istanbul ignore next */
	isURL: function (str) {
		var pattern = new RegExp(
			'^(https?:\\/\\/)?' + // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
				'(\\#[-a-z\\d_]*)?$',
			'i'
		); // fragment locator

		return pattern.test(str);
	},
	testPassword: testPassword,

	/* istanbul ignore next */
	addModule: function (module, obj) {
		autofi[module] = obj;
	},

	/* istanbul ignore next */
	setCurrencyCode: function (currencyCode) {
		autofi.currencyCode = currencyCode || autofi.utils.getCurrencyCode();
	},
	camelize: function camelize(str) {
		return str
			.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
				return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
			})
			.replace(/\s+/g, '');
	},
	normalizeString: function (str) {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	},
	getQueryParam: function getQueryParam(name, query) {
		name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
		var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		var results = regex.exec(query);
		return results === null ? undefined : decodeURIComponent(results[1].replace(/\+/g, ' '));
	},
};

/* istanbul ignore next */
function nthOfMonth(dt) {
	var date;

	if (!dt) {
		return '';
	}

	if (typeof dt === 'string') {
		dt = new Date(dt);
	}

	date = dt.getDate();

	function nth(d) {
		if (d > 3 && d < 21) {
			return 'th';
		}

		switch (d % 10) {
			case 1:
				return 'st';
			case 2:
				return 'nd';
			case 3:
				return 'rd';
			default:
				return 'th';
		}
	}

	return date + nth(date);
}

/* istanbul ignore next */
function testPassword(password) {
	var policyPasswordStrength = [
		// require at least 8 characters
		function (password) {
			if (password.length < 8) {
				return 'Password must be at least 8 characters long.';
			}
		},

		// require at least one lowercase letter
		function (password) {
			if (!/[a-z]/.test(password)) {
				return 'Password must contain at least one lowercase letter.';
			}
		},

		// require at least one number
		function (password) {
			if (!/[0-9]/.test(password)) {
				return 'Password must contain at least one number.';
			}
		},

		// require at least one uppercase letter or special character
		function (password) {
			if (!/[A-Z]/.test(password) && !/[^A-Za-z0-9]/.test(password)) {
				return 'Password must contain at least one uppercase letter or special character.';
			}
		},
	];

	var errors = [];

	policyPasswordStrength.forEach(function (testPasswordStrength) {
		var validation = testPasswordStrength(password);
		if (typeof validation === 'string') {
			errors.push(validation);
		}
	});

	return errors;
}

autofi.utils.formatDate = function formatDate(date, dateFormat) {
	return date ? autofi.globalize.formatDate(date, { raw: dateFormat }) : date;
};

autofi.utils.formatNumber = function formatNumber(num, n, currencyCode) {
	// eslint-disable-next-line no-console
	console.warn('utils.formatNumber is deprecated. Please use utils.money');
	return autofi.utils.money(num, n, currencyCode);
};
/**
 * formatDistance
 * @param {Float} distance - (optional) default 0
 * @param {Object} options - (optional)
 *				`form`: long (default)|short|narrow
 *				`unit`: miles (default)|kilometers
 * @returns {String} - the formatted distance e.g. "12,500 miles"
 */
autofi.utils.formatDistance = function formatDistance(distance, options) {
	distance = distance || 0;
	options = options || {};
	var unit = options.unit || 'mile';

	// determine if locale.json file has an override for the distance unit
	try {
		unit = autofi.globalize.messageFormatter('unit/distance')();
	} catch (ex) {
		// linting some stuff
	}

	// use `globalize` to do the formatting for us
	// default uses the locale's number formatting (e.g. "en" uses comma-grouping)
	return autofi.globalize.unitFormatter(unit, options)(distance);
};

autofi.utils._setGlobalizeContext = function _setGlobalizeContext(Globalize) {
	autofi.globalize = Globalize;
};

/* istanbul ignore next */
if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
	module.exports = autofi.utils;
	autofi.calculator = require('@autofidev/finance').calculator;
	autofi.globalize = typeof window !== 'undefined' ? window.Globalize : null;
} else {
	autofi.calculator = autofi.calculator || {};
	autofi.globalize = autofi.globalize || null;
}
