import React from 'react';

import { formatMessage } from '@public/js/loan-application/utils';
import type { Account } from '@client/graphql/generated';
import { connect } from '@client/loanAppStore';
import { Header } from '@components/Panel';
import * as globalSelectors from '@client/selectors/global';

/**
 * PartnerBackButton hides the back button for partner flows, such as the Ford Model-e. These are embedded flows
 * that start from the PII panel and have noting to go back to.
 * If the application doesn't have an account, it indicates that it's an AutoFi application. And although there
 * might be some demo applications with accounts, they remain as edge cases that we can ignore.
 */

type Props = {
	isVisible?: boolean;
	// eslint-disable-next-line @typescript-eslint/ban-types
	onBack: Function;
};

const globalPanels = globalSelectors.getPanels() as {
	pii?: {
		getPrevPanel: () => string;
	};
};

const PartnerBackButton = ({ isVisible, onBack }: Props) =>
	isVisible && <Header.BackButton onClick={onBack}>{formatMessage('common/back')}</Header.BackButton>;

const mapStateToProps = ({ account }: { account: Account }) => {
	return {
		isVisible: !account || !!globalPanels.pii?.getPrevPanel(),
	};
};

export default connect(mapStateToProps)(PartnerBackButton);
