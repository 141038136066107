import isNil from 'lodash/isNil';

import { n100 } from '@root/lib/helpers/format';
import { DEFAULT_OFFER_RANGES } from '@client/lib/constants';
import { getTermOptions, getMileageOptions } from '@client/lib/helpers/offer-range-helpers';

export const defaults = {
	finance: {
		term: DEFAULT_OFFER_RANGES.finance.termMonths[0],
		downPayment: 0,
		creditBand: 'EXCELLENT',
	},
	lease: {
		term: DEFAULT_OFFER_RANGES.lease.termMonths[0],
		annualMiles: DEFAULT_OFFER_RANGES.lease.annualMiles.US[0],
		downPayment: 0,
		creditBand: 'EXCELLENT',
	},
	tab: 'financing',
};

const FALLBACK = {
	finance: {
		term: 60,
	},
	lease: {
		term: 48,
		annualMiles: 12000,
	},
};

/* HELPERS TO PRESET DATA */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'loanAppModel' implicitly has an 'any' t
export const getCreditBandPreset = (loanAppModel, type) =>
	// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre
	loanAppModel.getCreditBand(type) || defaults[type].creditBand;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'loanAppModel' implicitly has an 'any' t
export const getTermMonthsPreset = (loanAppModel, type) =>
	loanAppModel.getTermMonths(type) ||
	getTermOptions(loanAppModel.dealer.offerRanges, type)[0] ||
	// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre
	FALLBACK[type]?.term;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'loanAppModel' implicitly has an 'any' t
export const getDownPaymentPreset = (loanAppModel, type) => {
	const terms = loanAppModel.getExpressCheckoutPreferences(type);

	if (!isNil(terms.downPaymentPercentage)) {
		return n100(loanAppModel.inclusivePrice * (terms.downPaymentPercentage / 100));
	}

	if (!isNil(terms.downPayment)) {
		return n100(terms.downPayment);
	}

	// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre
	return n100(defaults[type].downPayment);
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'loanAppModel' implicitly has an 'any' t
export const getAnnualMilesPreset = (loanAppModel, type, country) => {
	if (type !== 'lease') {
		return null;
	}

	return (
		loanAppModel.getExpressCheckoutPreferences('lease').annualMileage ||
		getMileageOptions(loanAppModel.dealer.offerRanges, type, country)[0] ||
		// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre
		FALLBACK[type].annualMiles
	);
};
