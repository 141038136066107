import type { OfferType } from '@client/types';
import api from './api';
import { createEstimatorPayload } from '../../../../lib/helpers/estimates';
import type LoanAppModel from '../models/loanapp-model';
import type { FinanceEndpointOverrides, Offer } from '../Estimator/types';

/**
 * gets new estimate
 *
 * @param {any} offerData - flat object per financeType
 * @param {LoanAppModel} loanAppModel
 * @param {OfferType} offerType
 * @param {object} [opts={}]
 *
 * @returns {Promise<Estimate>}
 */
export const getEstimate = (
	offerData: Offer,
	loanAppModel: LoanAppModel,
	offerType: OfferType,
	opts = {}
): Promise<any> =>
	api.post(loanAppModel.estimatorEndpointUrl, createEstimatorPayload(loanAppModel.data, offerType, offerData, opts), {
		prefix: false,
	});

/**
 * calculates new numbers using the the finance endpoint
 *
 * @param {object} opts
 * @param {LoanAppModel} opts.loanAppModel
 * @param {object} opts.offer
 * @param {object} [opts.loanApp] loanApplication overrides
 * @param {object} [opts.overrides] endpoint overrides
 *
 * @returns {Promise<any>}
 */
export const getNumbers = (
	loanAppModel: LoanAppModel,
	{
		offer,
		loanApp = {},
		overrides = {},
	}: {
		loanApp?: any;
		offer: Offer;
		overrides?: FinanceEndpointOverrides;
	}
): Promise<any> =>
	api.post(
		`${loanAppModel.financeEndpointURL.href}/${loanAppModel.id}/finance`,
		{ offer, loanApp, overrides },
		{ prefix: false }
	);
