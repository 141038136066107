import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const mapPropsToClassNames = {
	active: 'active',
	disabled: 'disabled',
};

const booleanProps = Object.keys(mapPropsToClassNames);

export default class Button extends React.Component {
	handleClick = (e) => {
		const { disabled, onClick } = this.props;
		if (disabled) {
			return e.preventDefault();
		}

		onclick && onClick(e);
	};

	render() {
		const { innerRef, className, href, children, ...props } = this.props;
		const ElementType = href ? 'a' : 'button';

		// build className from props
		const filteredClassNames = [];
		booleanProps.forEach((name) => {
			if (props[name]) {
				filteredClassNames.push(mapPropsToClassNames[name]);
			}
			// delete only if not button && disabled
			if (!(ElementType === 'button' && name === 'disabled')) {
				delete props[name];
			}
		});

		return (
			<ElementType
				ref={innerRef}
				className={classnames('panel-btn', filteredClassNames, className)}
				href={href}
				onClick={this.handleClick}
				{...props}
			>
				{children}
			</ElementType>
		);
	}
}

Button.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]),
	className: PropTypes.string,
	disabled: PropTypes.bool,
	href: PropTypes.string,
	onClick: PropTypes.func,
	innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
};
