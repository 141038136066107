export const CASH = 'CASH';

export default {
	CASH: 'CASH',
	cash: 'CASH',
	LEASE: 'LEASE',
	lease: 'LEASE',
	FINANCE: 'FINANCE',
	finance: 'FINANCE',
};

export const DEFAULT_OFFER_RANGES = {
	finance: {
		termMonths: [36, 48, 60, 72],
		creditBand: ['FAIR', 'AVERAGE', 'GOOD', 'EXCELLENT'],
	},
	lease: {
		termMonths: [24, 36, 39, 48],
		annualMiles: { CA: [16000, 20000, 24000], US: [10500, 12000, 13500, 15000, 16500, 18000] },
		creditBand: ['FAIR', 'AVERAGE', 'GOOD', 'EXCELLENT'],
	},
};

export const INCENTIVE_OPTIONS = [
	{
		value: 'LOWEST_MONTHLY',
		labelText: 'Lowest Payment',
		labelPretext: 'Recommended',
		// labelSubtext: `${money(offer.monthlyPayment)}/mo | ${toPercent(
		// 	offer.apr,
		// 	2
		// )} APR | ${money(offer.rebates)} Rebates`
	},
	{
		value: 'LOWEST_APR',
		labelText: 'Lowest APR',
		// labelSubtext: `${money(offer.monthlyPayment)}/mo | ${toPercent(
		// 	offer.apr,
		// 	2
		// )} APR | ${money(offer.rebates)} Rebates`
	},
	{
		value: 'LARGEST_REBATE',
		labelText: 'Lowest Price',
		// labelSubtext: `${money(offer.monthlyPayment)}/mo | ${toPercent(
		// 	offer.apr,
		// 	2
		// )} APR | ${money(offer.rebates)} Rebates`
	},
];

export const LOCALES_WITH_PREFIX = ['en', 'en-CA'];

export const USLocales = ['en', 'en-US'];
export const UNLOCKED_PRICING_TIMEOUT = 10000;

export const STATES = {
	US: [
		'AK',
		'AL',
		'AR',
		'AZ',
		'CA',
		'CO',
		'CT',
		'DC',
		'DE',
		'FL',
		'GA',
		'HI',
		'IA',
		'ID',
		'IL',
		'IN',
		'KS',
		'KY',
		'LA',
		'MA',
		'MD',
		'ME',
		'MI',
		'MN',
		'MO',
		'MS',
		'MT',
		'NC',
		'ND',
		'NE',
		'NH',
		'NJ',
		'NM',
		'NV',
		'NY',
		'OH',
		'OK',
		'OR',
		'PA',
		'RI',
		'SC',
		'SD',
		'TN',
		'TX',
		'UT',
		'VA',
		'VT',
		'WA',
		'WI',
		'WV',
		'WY',
	],
	CA: ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'],
};

export const TOASTER_TIMEOUT = 6000;

// NOTE: These currently get populated where this is exported in v3/main
// example is imageBase
export const SERVER_CONSTANTS = {};

export const TRADE_IN_EVENTS = {
	BEGUN: 'trade_in_begun',
	SAW_ESTIMATE: 'trade_in_saw_estimate',
	REMOVED: 'trade_in_removed',
	APPLIED: 'trade_in_applied',
	EXITED: 'trade_in_exited_to_dealmaker',
};
