import React from 'react';
import PropTypes from 'prop-types';

const ExpandIcon = ({ color = '#c4c4c4' }) => (
	<svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M20 5a1 1 0 00-1-1h-5a1 1 0 000 2h2.57l-3.28 3.29a1 1 0 00.325 1.639.999.999 0 001.095-.219L18 7.42V10a1 1 0 002 0V5zM10.71 13.29a1.002 1.002 0 00-1.42 0L6 16.57V14a1 1 0 10-2 0v5a1 1 0 001 1h5a1 1 0 000-2H7.42l3.29-3.29a1.002 1.002 0 000-1.42z"
			fill={color}
		/>
	</svg>
);
ExpandIcon.propTypes = {
	color: PropTypes.string,
};

export default ExpandIcon;
