import LoanAppModel from '@client/lib/models/loanapp-model';

export { default as connect } from './connect';
export { StoreProvider } from './context';
export { LOAN_APP_DEFAULT, LOAN_APP_DESKING, StoreKey } from './storeKey';
export { useLoanApp, useLoanAppStore, useUpdateLoanApp } from './useLoanApp';

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
export const createStore = (data = undefined) => {
	/*
	 * Private fields, not accessible outside of the store implementation
	 */

	// An instance of a LoanAppModel which backs this store
	const model = new LoanAppModel(data);

	// A list of all functions which are subscribed to store updates
	// @ts-expect-error ts-migrate(7034) FIXME: Variable 'listeners' implicitly has type 'any[]' i
	const listeners = [];

	// Listeners are only notified once per batch of updates, not every update
	let isBatchedNotificationPending = false;

	/*
	 * Private methods, not accessible outside of the store implementation
	 */

	// Notify all listeners that a store update has occurred
	const queueListenerNotification = () => {
		// If we've already queued a notification, no need to continue
		if (isBatchedNotificationPending) {
			return;
		}

		const notifyListeners = () => {
			// In here, we're performing the notification, so reset the pending state
			isBatchedNotificationPending = false;
			// @ts-expect-error ts-migrate(7005) FIXME: Variable 'listeners' implicitly has an 'any[]' typ
			listeners.forEach((fn) => fn());
		};

		// Queue a notification to listeners once the current batch of updates is done
		setTimeout(notifyListeners, 0);
		isBatchedNotificationPending = true;
	};

	/*
	 * Public API
	 */
	class LoanAppStore {
		// Seed the store with initial data
		// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
		hydrate = (data) => {
			model.data = data;
		};

		// Reset the store—primarily avaliable for resetting state in tests
		reset = () => {
			// Remove all subscriptions
			listeners.length = 0;
			// Reset the model data
			model.data = undefined;
		};

		// Register a listener with the store, and receieve back a function to
		// unsubscribe
		// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'listener' implicitly has an 'any' type.
		subscribe = (listener) => {
			const unsubscribe = () => {
				// indexOf performs a strict equality check to find this listener
				// @ts-expect-error ts-migrate(7005) FIXME: Variable 'listeners' implicitly has an 'any[]' typ
				const index = listeners.indexOf(listener);

				// Prevent errors calling `unsubscribe` multiple times
				if (index === -1) {
					return;
				}

				// @ts-expect-error ts-migrate(7005) FIXME: Variable 'listeners' implicitly has an 'any[]' typ
				listeners.splice(index, 1);
			};

			listeners.push(listener);

			return unsubscribe;
		};

		// Get the current state of the store
		getState = () => model.data;

		// Perform an update of the store
		// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'updateHandler' implicitly has an 'any'
		update = (updateHandler) => {
			updateHandler(model.data);
			queueListenerNotification();
		};

		// Perform an update of the store using the model as an interface
		//
		// This is provided as a convenience to ease migrating code which currently
		// uses the LoanAppModel
		//
		// TODO: Remove me
		// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'updateHandler' implicitly has an 'any'
		updateModel = (updateHandler) => {
			// eslint-disable-next-line no-console
			console.warn('Use of `updateModel` is discouraged, please use `update` instead.');

			updateHandler(model);
			queueListenerNotification();
		};
	}

	return new LoanAppStore();
};
