// @ts-nocheck
var stateProvincesAbbreviations = {
	US: [
		'AL',
		'AK',
		'AZ',
		'AR',
		'CA',
		'CO',
		'CT',
		'DC',
		'DE',
		'FL',
		'GA',
		'HI',
		'ID',
		'IL',
		'IN',
		'IA',
		'KS',
		'KY',
		'LA',
		'ME',
		'MD',
		'MA',
		'MI',
		'MN',
		'MS',
		'MO',
		'MT',
		'NE',
		'NV',
		'NH',
		'NJ',
		'NM',
		'NY',
		'NC',
		'ND',
		'OH',
		'OK',
		'OR',
		'PA',
		'RI',
		'SC',
		'SD',
		'TN',
		'TX',
		'UT',
		'VT',
		'VA',
		'WA',
		'WV',
		'WI',
		'WY',
		'XX',
	],
	CA: ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'],
};

function validateText(str, min, max) {
	if (!str) {
		return false;
	}
	min = min || 1;
	max = max || 256;
	var len = str.length;
	return len >= min && len <= max;
}

function validateState(state, country) {
	if (!state) {
		return false;
	}

	country = (country || 'US').toUpperCase();
	return (stateProvincesAbbreviations[country] || []).indexOf(state.toUpperCase()) > -1;
}

function validateZip(zip) {
	if (!zip) {
		return false;
	}
	var re = /(^\d{5}$)|(^[A-Z]\d[A-Z]\s?\d[A-Z]\d$)/i;
	return re.test(zip);
}

function validateZipOrZipPlus4(zip) {
	if (!zip) {
		return false;
	}
	var re = /(^\d{5}(-\d{4})?$)|(^[A-Z]\d[A-Z]\s?\d[A-Z]\d$)/i;
	return re.test(zip);
}

function validateDOB(dob, justNumbers, minAge, maxAge) {
	if (!dob) {
		return false;
	}
	if (justNumbers) {
		var re = /^\d{8}$/;
		if (!re.test(dob)) {
			return false;
		}
		var year = dob.substring(0, 4);
		var month = dob.substring(4, 6);
		var day = dob.substring(6, 8);
		dob = month + '/' + day + '/' + year;
	}
	minAge = minAge === undefined ? 18 : minAge;
	maxAge = maxAge === undefined ? 120 : maxAge;

	var newValue = dob.replace(/[-]/g, '/');

	// validate to prevent funky behavior on IE
	var reg = /([0-9]{2}\/){2}[0-9]{4}/;
	if (newValue.length !== 10 || !reg.test(newValue)) {
		return false;
	}

	var date = new Date(newValue);
	var now = new Date();
	if (isNaN(date * 1)) {
		return false;
	}
	var age = Math.floor((now - date) / 365.25 / 24 / 60 / 60 / 1000);
	if (age < minAge || age > maxAge) {
		return false;
	}
	return true;
}

function validateDOBUTC(dob, justNumbers, minAge, maxAge) {
	if (!dob) {
		return false;
	}

	if (justNumbers) {
		var regex = /^\d{8}$/;
		if (!regex.test(dob)) {
			return false;
		}

		dob = dob.substring(0, 4) + '/' + dob.substring(4, 6) + '/' + dob.substring(6, 8);
	}

	minAge = minAge === undefined ? 18 : minAge;
	maxAge = maxAge === undefined ? 120 : maxAge;
	var newValue = dob.replace(/[-]/g, '/');

	// validate to prevent funky behavior on IE
	var reg = /[0-9]{4}(\/[0-9]{2}){2}/;
	if (newValue.length !== 10 || !reg.test(newValue)) {
		return false;
	}

	var date = new Date(newValue);
	var now = new Date();
	if (isNaN(date * 1)) {
		return false;
	}
	var age = Math.floor((now - date) / 365.25 / 24 / 60 / 60 / 1000);
	if (age < minAge || age > maxAge) {
		return false;
	}
	return true;
}

function validateSSN(ssn, justNumbers) {
	if (!ssn) {
		return false;
	}
	var re = justNumbers ? /^\d{9}$/ : /^\d{3}-?\d{2}-?\d{4}$/;
	return re.test(ssn);
}

function validateSIN(sin, justNumbers) {
	if (!sin) {
		return false;
	}
	sin = sin.replace(/-/g, '');

	if (justNumbers) {
		return /^\d{9}$/.test(sin);
	}

	var luhnChecksum = function (sin) {
		var len = 9;
		var mul = 0;
		var luhnArr = [
			[0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
			[0, 2, 4, 6, 8, 1, 3, 5, 7, 9],
		];
		var sum = 0;
		while (len--) {
			sum += luhnArr[mul][parseInt(sin.charAt(len), 10)];
			mul = mul ^ 1;
		}
		return sum % 10;
	};

	return luhnChecksum(sin) === 0;
}

function validateSSNLast4(last4) {
	if (!last4) {
		return false;
	}
	var re = /^[0-9]\d{3}$/;
	return re.test(last4);
}

function validateEmail(email) {
	if (!email) {
		return false;
	}
	var re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
	return re.test(email.trim());
}

function validatePhone(phone, justNumbers) {
	if (!phone) {
		return false;
	}
	var re = justNumbers ? /^[2-9]\d{9}$/ : /^(\+\d{1,2}\s)?\(?[2-9]\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
	return re.test(phone);
}

function validatePhoneNumbersOnly(phone) {
	return validatePhone(phone, true);
}

function validateNumberAsDate(numdate) {
	if (!numdate) {
		return false;
	}
	// format YYYYMMDD - test checks for [1970-2029][01-12][01-31], not perfect for days
	var re = /^(19[7-9]\d|20[0-2]\d)((0[1-9])|1[0-2])(0[1-9]|1\d|2\d|3[01])$/;
	return re.test(numdate);
}

function validateUrl(url) {
	// eslint-disable-next-line max-len
	// var re = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\x{00a1}-\x{ffff}0-9]+-?)*[a-z\x{00a1}-\x{ffff}0-9]+)(?:\.(?:[a-z\x{00a1}-\x{ffff}0-9]+-?)*[a-z\x{00a1}-\x{ffff}0-9]+)*(?:\.(?:[a-z\x{00a1}-\x{ffff}]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/iu;
	// var re = /\b(([\w-]+:\/\/?|www[.])[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|\/)))/i;
	// var re = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
	var re = /^((http|https):)?\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
	return re.test(url);
}

function validatePassword(password) {
	var policyPasswordStrength = [
		// require at least 8 characters
		function (password) {
			if (password.length < 8) {
				return 'Password must be at least 8 characters long.';
			}
		},
		// require at least one lowercase letter
		function (password) {
			if (!/[a-z]/.test(password)) {
				return 'Password must contain at least one lowercase letter.';
			}
		},
		// require at least one number
		function (password) {
			if (!/[0-9]/.test(password)) {
				return 'Password must contain at least one number.';
			}
		},
		// require at least one uppercase letter or special character
		function (password) {
			if (!/[A-Z]/.test(password) && !/[^A-Za-z0-9]/.test(password)) {
				return 'Password must contain at least one uppercase letter or special character.';
			}
		},
	];

	function testPassword(pw) {
		var errors = [];
		policyPasswordStrength.forEach(function (testPasswordStrength) {
			var validation = testPasswordStrength(pw);
			if (typeof validation === 'string') {
				errors.push(validation);
			}
		});

		return !errors.length;
	}

	return testPassword(password);
}

function validateDobYear(yyyy) {
	if (!yyyy) {
		return false;
	}
	var currentYear = new Date().getFullYear();
	return yyyy > currentYear - 101 && yyyy < currentYear - 17;
}

function validateDobMonth(mm) {
	return mm > 0 && mm < 13;
}

function validateDobDay(dd) {
	return dd > 0 && dd < 32;
}

function validateRoutingNumber(s) {
	return /^\d{9}$/.test(s);
}

function validateAccountNumber(s) {
	return /^\d+$/.test(s) && s.length > 3 && s.length < 18;
}

function validateMoney(s) {
	var r = /(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{1,2})?$/;
	return r.test(s.trim().replace(' ', ''));
}
function validateIncome(s) {
	return !isNaN(s) && s >= 0;
}

function validatePOBoxStreet(street) {
	var re = /^(p\.? ?o\.? box|p\.? ?b\.?) *\d+/i;
	return re.test(street);
}

function validateStreetNumber(street) {
	var streetNumber = street.split(/\s+/)[0];

	if (/st|nd|rd|th/i.test(streetNumber)) {
		return false;
	}

	return /\d/.test(streetNumber) && /^\w+(\s+\w+|[-\w]+)/.test(street);
}

function validateStreetAddress(street) {
	if (!street) {
		return false;
	}

	if (street.length <= 1) {
		return false;
	}

	return validateStreetNumber(street);
}

function validateAddress(address, country) {
	return Boolean(
		address &&
			address.city &&
			address.city.length > 1 &&
			validateStreetAddress(address.street, country) &&
			validateState(address.state, country) &&
			validateZip(address.zip)
	);
}

function validateMileagePenalty(value) {
	if (!value && value !== 0) {
		return false;
	}
	var num = Number(value);
	return 0 <= num && num <= 1;
}

function validateHasNoNumbers(value) {
	return !/\d/.test(value);
}

function validateName(value) {
	return /^[^\d]{2,}$/.test(value || '');
}

function validateMiddleName(value) {
	return /^[^\d]{1,}$/.test(value || '') || value === '';
}

function validateVin(value) {
	return /[A-HJ-NPR-Z0-9]{17}/.test(value || '');
}

function validateLicensePlate(value) {
	return /^[a-zA-Z0-9]{5,8}$/g.test(value || '');
}

var validate = {
	address: validateAddress,
	bankAccountNumber: validateAccountNumber,
	bankRoutingNumber: validateRoutingNumber,
	contactEmail: validateEmail,
	dob: validateDOB,
	dobUTC: validateDOBUTC,
	dobday: validateDobDay,
	dobmonth: validateDobMonth,
	dobyear: validateDobYear,
	email: validateEmail,
	hasNoNumbers: validateHasNoNumbers,
	income: validateIncome,
	licensePlate: validateLicensePlate,
	mileagePenalty: validateMileagePenalty,
	money: validateMoney,
	name: validateName,
	middlename: validateMiddleName,
	numAsDate: validateNumberAsDate,
	password: validatePassword,
	phone: validatePhone,
	phoneNumsOnly: validatePhoneNumbersOnly,
	sin: validateSIN,
	ssn: validateSSN,
	ssnlast4: validateSSNLast4,
	state: validateState,
	street: validateStreetAddress,
	streetNumber: validateStreetNumber,
	streetPOBox: validatePOBoxStreet,
	text: validateText,
	url: validateUrl,
	validStates: stateProvincesAbbreviations,
	vin: validateVin,
	zip: validateZip,
	zipOrZipPlus4: validateZipOrZipPlus4,
};

/* istanbul ignore next */
if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
	module.exports = validate;
} else {
	window.autofi = window.autofi || {};
	window.autofi.validate = validate;
}
