import { formatMessage } from '@public/js/loan-application/utils';

export const footerText = (dealer: string): string => {
	const termsLink = formatMessage('links/terms');
	const privacyLink = formatMessage('links/privacy-policy');

	const disclosureText = formatMessage('pii/privacy-disclosure', { dealer });
	const links = formatMessage('pii/view-our-privacy-policy-and-terms-of-service', {
		termsLink,
		privacyLink,
	});

	return `${disclosureText} ${links}`;
};
