import type { Action } from '@client/store/actions';
import { types } from '@client/store/actions';

export interface ProductsState {
	groups: object[];
	products: object[];
}

const initialState: ProductsState = {
	groups: [],
	products: [],
};

export function productsReducer(state: ProductsState = initialState, action: Action): ProductsState {
	switch (action.type) {
		case types.ADD_PRODUCTS:
		case types.SELECT_PRODUCT: {
			return {
				...state,
				...action.payload,
			};
		}
		default:
			return state;
	}
}

declare module 'react-redux' {
	interface DefaultRootState {
		products: ProductsState;
	}
}
