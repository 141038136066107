import React from 'react';
import PropTypes from 'prop-types';

const CarIcon = ({ color = '#C4C4C4' }) => (
	<svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17 8h-1V6.11a4 4 0 10-8 0V8H7a3 3 0 00-3 3v8a3 3 0 003 3h10a3 3 0 003-3v-8a3 3 0 00-3-3zm-7-1.89A2.06 2.06 0 0112 4a2.06 2.06 0 012 2.11V8h-4V6.11zM18 19a1 1 0 01-1 1H7a1 1 0 01-1-1v-8a1 1 0 011-1h10a1 1 0 011 1v8z"
			fill={color}
		/>
		<path d="M12 12a3 3 0 100 5.999A3 3 0 0012 12zm0 4a1 1 0 110-2 1 1 0 010 2z" fill={color} />
	</svg>
);

CarIcon.propTypes = {
	color: PropTypes.string,
};

export default CarIcon;
