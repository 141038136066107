// this is just a wrapper for https://github.com/JedWatson/react-select

import React from 'react';
import type { DropdownIndicatorProps } from 'react-select';
import ReactSelect, { components } from 'react-select';

import styles from './index.scss';

// This replicates a similar dropdown indicator from the 1.0 version of react-select
// maintains UI consistency when upgrading to react-select 5.0
const DropdownIndicator = (props: DropdownIndicatorProps) => {
	return (
		<components.DropdownIndicator {...props}>
			<span className="Select-arrow-zone">
				<span className="Select-arrow" />
			</span>
		</components.DropdownIndicator>
	);
};

type SelectInputProps = {
	isDisabled?: boolean;
	isSearchable?: boolean;
	name: string;
	onChange?: (value: any) => void;
	options: any[];
	placeholder?: string;
	value?: any;
};

const SelectInput = ({
	isDisabled = false,
	isSearchable = true,
	name,
	options,
	onChange = undefined,
	placeholder = undefined,
	value,
	...selectProps
}: SelectInputProps) => (
	<div className={styles.selectInput}>
		<ReactSelect
			classNamePrefix="Select"
			components={{ DropdownIndicator }}
			isDisabled={isDisabled}
			isSearchable={isSearchable}
			name={name}
			onChange={onChange}
			options={options}
			placeholder={placeholder}
			unstyled
			value={options.find((option: any) => option.value === value)}
			{...selectProps}
		/>
	</div>
);

export { SelectInput };
