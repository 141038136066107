import type { ThemeName } from '@client/types';
import type { AutoFiLDFlagSet } from '@root/lib/launchDarkly';

export const ADD_PRODUCTS = 'add products from the backend to the store';
export const ADD_CUSTOMER = 'add customer from the backend to the store';
export const HYDRATE_ENV = 'hydrate with environment information from window';
export const HYDRATE_META = 'hydrate general info about the session';
export const HYDRATE_USER = 'hydrate with user information from window';
export const SELECT_PRODUCT = 'mark a product as user selected';

export type Action =
	| {
			payload: {
				_id: string;
				dealerName: string;
				email: string;
				name: string;
			};
			type: typeof ADD_CUSTOMER;
	  }
	| {
			payload: {
				accutrade: AccuTradeConfig;
				dealURL: string;
				debugFullStory: boolean;
				isFullStoryEnabled: boolean;
				isInStore: boolean;
				launchDarklyFeatureFlags: AutoFiLDFlagSet;
				orgId: string;
				truecar: AccuTradeConfig;
			};
			type: typeof HYDRATE_ENV;
	  }
	| {
			payload: {
				theme: ThemeName;
			};
			type: typeof HYDRATE_META;
	  }
	| {
			payload: {
				dealers: [];
				email: string;
				isAuthenticated: boolean;
				isAutoFiUser: boolean;
				isDealerAdmin: boolean;
				name: { first: string; full: string; last: string };
				role: string;
			};
			type: typeof HYDRATE_USER;
	  }
	| {
			payload: {
				groups: object[];
				products: object[];
			};
			type: typeof ADD_PRODUCTS;
	  }
	| {
			payload: {
				products: object[];
			};
			type: typeof SELECT_PRODUCT;
	  };
