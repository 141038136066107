import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Fade } from '@components/AFComponentsAnimations';
import defaultStyles from './index.scss';

export default class Popover extends Component {
	static defaultProps = {
		Animation: Fade,
		arrowMiddle: false,
		timeout: 3000,
		neutralTitle: false,
		iconStyles: {},
		styles: {
			icon: {},
			box: {},
			iconBlock: {},
		},
	};

	state = {
		isOpen: false,
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			isOpen: nextProps.isOpen,
		});
	}

	componentDidUpdate() {
		if (this.props.timeout && this.state.isOpen) {
			setTimeout(() => {
				this.setState({
					isOpen: false,
				});
			}, this.props.timeout);
		}
	}

	renderArrow = () => {
		const { arrow, arrowMiddle, styles } = this.props;

		return (
			<div
				className={classnames('arrow-selector', {
					[defaultStyles.arrow]: true,
					[defaultStyles[`arrow-${arrow}`]]: true,
					[defaultStyles['arrow-middle']]: arrowMiddle,
				})}
				style={styles.arrow}
			/>
		);
	};

	renderTitle = (title, color) => {
		return (
			<div
				className={classnames('media-title', {
					[defaultStyles.header]: true,
					[defaultStyles['header-info']]: true,
					[defaultStyles['header-neutral']]: this.props.neutralTitle,
				})}
				style={{
					color,
				}}
			>
				{title}
			</div>
		);
	};

	renderContent = (content) => <div className={classnames('media-content', defaultStyles.body)}>{content}</div>;

	renderAction = (action) => {
		let { href, text } = action;
		href = href || '#';

		return (
			<a className={classnames('media-action', defaultStyles.action)} href={href} onClick={this.handleAction}>
				{text}
			</a>
		);
	};

	handleAction = (e) => {
		const { action } = this.props;

		if (action.href || !action.handler) {
			return;
		}

		e.preventDefault();

		action.handler(e);
	};

	render() {
		const { isOpen } = this.state;
		const { action, arrow, content, title, Icon, styles, Animation, duration, color } = this.props;

		if (!title && !content) {
			return null;
		}
		return (
			<Animation key="popover" duration={duration} in={isOpen}>
				{isOpen && (
					<div
						className={classnames('media', {
							[defaultStyles.popover]: isOpen,
							[defaultStyles[`popover-${arrow}`]]: !!arrow,
						})}
						style={styles.box}
					>
						{arrow && this.renderArrow()}
						{Icon && (
							<div
								className={classnames('media-left media-middle', defaultStyles.leftSide, defaultStyles['icon-col'], {
									[defaultStyles.withBackgroundColor]: !!styles.iconBlock.backgroundColor,
								})}
								style={styles.iconBlock}
							>
								<Icon {...styles.icon} />
							</div>
						)}
						<div className={classnames('media-body media-middle', defaultStyles.rightSide, defaultStyles['text-col'])}>
							{title && this.renderTitle(title, color)}
							{content && this.renderContent(content)}
							{action && this.renderAction(action)}
						</div>
					</div>
				)}
			</Animation>
		);
	}
}

Popover.propTypes = {
	Animation: PropTypes.func,
	duration: PropTypes.number,
	arrowMiddle: PropTypes.bool,
	styles: PropTypes.object,
	action: PropTypes.shape({
		handler: PropTypes.func,
		href: PropTypes.string,
		text: PropTypes.string,
	}),
	arrow: PropTypes.string,
	content: PropTypes.string,
	Icon: PropTypes.any,
	neutralTitle: PropTypes.bool,
	isOpen: PropTypes.bool,
	title: PropTypes.string,
	timeout: PropTypes.number,
	color: PropTypes.string,
};
