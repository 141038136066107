const _ = require('lodash');

const { DEFAULT_CREDIT_BANDS } = require('../constants');

module.exports = (loanApp, creditBand) => {
	const lenderSettings = _.get(loanApp, 'dealer.settings.lenderSettings', []);
	const captiveLenderSetting = lenderSettings.find((lenderSetting) => lenderSetting.isCaptive);
	const captiveLenderId = _.get(captiveLenderSetting, 'lender', '').toString();
	const lenders = _.get(loanApp, 'dealer.settings.lenders', []);
	const captiveLender = captiveLenderSetting
		? lenders.find((lender) => captiveLenderId === lender._id.toString())
		: null;
	const lenderToLookAt = captiveLender || lenders.find((lender) => (lender?.settings?.ficoBands ?? []).length > 0);
	const allCreditBands = lenderToLookAt ? _.get(lenderToLookAt, 'settings.ficoBands', []) : DEFAULT_CREDIT_BANDS;
	const creditBandDetails = allCreditBands.find(
		(band) => band.label.toUpperCase() === (creditBand || '').toUpperCase()
	);
	return creditBandDetails ? { bottom: creditBandDetails.minFico, top: creditBandDetails.maxFico } : null;
};
