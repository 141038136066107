import classnames from 'classnames';
import type { HTMLProps } from 'react';
import React from 'react';

import { isEmbedded } from '@client/lib/helpers/isEmbedded';
import controller from '@public/v3/controller';
import styles from './index.scss';

interface ContentProps {
	children: React.ReactNode;
	className?: string;
	style?: object;
}

export const Content = ({ children, className, style }: ContentProps) => (
	<div className={classnames('panel-content', className)} style={style}>
		{children}
	</div>
);

interface PanelProps extends HTMLProps<HTMLDivElement> {
	showCloseIcon?: boolean;
}

const Panel = ({ className, children, showCloseIcon = false, ...restProps }: PanelProps) => {
	const isInIframe = window.self !== window.top;
	const embedded = isEmbedded();
	return (
		<div
			className={classnames('af-panel', className, {
				[styles['iframe-padding']]: isInIframe,
				[styles.isEmbedded]: embedded,
			})}
			{...restProps}
		>
			{showCloseIcon && (
				<div
					className={styles.tradeinClose}
					onClick={() => {
						controller.exit();
					}}
				>
					✕
				</div>
			)}
			{children}
		</div>
	);
};

export default Panel;
