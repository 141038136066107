import type { Action } from '@client/store/actions';
import { types } from '@client/store/actions';

export interface CustomerState {
	_id: string;
	dealerName: string;
	email: string;
	name: string;
}

const initialState: CustomerState = {
	_id: '',
	name: '',
	email: '',
	dealerName: '',
};

export function customerReducer(state: CustomerState = initialState, action: Action): CustomerState {
	switch (action.type) {
		case types.ADD_CUSTOMER: {
			const newState = {
				...state,
				...action.payload,
			};

			return newState;
		}
		default:
			return state;
	}
}

declare module 'react-redux' {
	interface DefaultRootState {
		customer: CustomerState;
	}
}
