import type { Action } from '@client/store/actions';
import { types } from '@client/store/actions';
import type { ThemeName } from '@client/types';

export interface MetaState {
	theme: ThemeName;
}

const initialState: MetaState = {
	theme: 'consumer',
};

export function metaReducer(state: MetaState = initialState, action: Action): MetaState {
	switch (action.type) {
		case types.HYDRATE_META: {
			const newState = {
				...state,
				...action.payload,
			};

			return newState;
		}
		default:
			return state;
	}
}

declare module 'react-redux' {
	interface DefaultRootState {
		meta: MetaState;
	}
}
