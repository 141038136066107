import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Info } from '@components/AFComponentsIcons';
import Popover from '@components/AFComponentsPopover';

class Tooltip extends Component {
	state = {
		isOpen: false,
	};

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside, false);
	}

	handleClick = (evt) => {
		evt && evt.stopPropagation();
		const { isOpen } = this.state;

		if (!isOpen) {
			document.addEventListener('click', this.handleClickOutside, false);
		} else {
			document.removeEventListener('click', this.handleClickOutside, false);
		}

		this.setState((prevState) => ({
			isOpen: !prevState.isOpen,
		}));
	};

	handleClickOutside = (e) => {
		if (!this.focus.contains(e.target)) {
			this.handleClick(e);
		}
	};

	render() {
		const { isOpen } = this.state;
		const { arrow = '', color, content, infoIcon = true, title } = this.props;
		return (
			<>
				<span
					key="button"
					ref={(node) => (this.focus = node)}
					className={classnames('info-button', { active: isOpen })}
					onClick={this.handleClick}
				>
					<Info />
				</span>

				<div style={{ position: 'absolute' }}>
					<Popover
						Icon={infoIcon && Info}
						arrow={arrow}
						color={color}
						content={content}
						isOpen={isOpen}
						styles={{
							box: {
								position: 'absolute',
								top: 'auto',
								bottom: arrow ? '30px' : '25px',
							},
							iconBlock: {},
							icon: {},
						}}
						timeout={0}
						title={title}
					/>
				</div>
			</>
		);
	}
}

Tooltip.propTypes = {
	arrow: PropTypes.string,
	color: PropTypes.string,
	content: PropTypes.string,
	infoIcon: PropTypes.bool,
	title: PropTypes.string,
};

export default Tooltip;
