import React from 'react';
import PropTypes from 'prop-types';

const ArrowDown = ({ fill = '#2693E9' }) => (
	<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fillRule="evenodd">
			<rect height="16" rx="2" width="16" />
			<path
				d="M6.99 4h2.02v4.545l1.768-1.767L12 8l-4 4-4-4 1.222-1.222L6.99 8.545z"
				fill={fill}
				fillRule="nonzero"
				opacity=".4"
			/>
		</g>
	</svg>
);

ArrowDown.propTypes = {
	fill: PropTypes.string,
};

export default ArrowDown;
