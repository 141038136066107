import React from 'react';
import classNames from 'classnames';

import styles from './index.scss';
import CheckboxIcon from '@client/svg/CheckboxIcon';

type Props = {
	bigger?: boolean;
	hint?: string;
	isSelected?: boolean;
	label?: string;
	onClick?: (...args: any[]) => any;
};

const Checkbox = ({ bigger = false, label, onClick, hint, isSelected = false }: Props) => (
	<>
		<button
			className={classNames({
				[styles.checkbox]: true,
				[styles.active]: isSelected,
				[styles.bigger]: bigger,
			})}
			onClick={onClick}
			type="button"
		>
			<CheckboxIcon className={styles.checkboxIcon} />
		</button>
		{label && (
			<label className={classNames({ [styles.checkboxLabel]: bigger, [styles.bigger]: true })} onClick={onClick}>
				{label}
				{hint && <div className={styles.hintText}>{hint}</div>}
			</label>
		)}
	</>
);

export default Checkbox;
