import React from 'react';

import { EstimateConsumer } from '@client/components/EstimateProvider';

const BackendPath = () => (
	<EstimateConsumer>
		{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'loanAppModel' does not exist on type '{} */}
		{({ loanAppModel = {} }) => {
			const { backendPath } = loanAppModel;
			const showBackendPath = Boolean(backendPath && backendPath !== 'PROD');

			if (!showBackendPath) {
				return null;
			}

			return (
				<div className="afi-application-backend">
					<div className="afi-application-backend--text">{backendPath}</div>
				</div>
			);
		}}
	</EstimateConsumer>
);

export default BackendPath;
