import type { AutoFiLDFlagSet } from '@root/lib/launchDarkly';
import type { Action } from '@client/store/actions';
import { types } from '@client/store/actions';

export interface EnvState {
	accutrade: AccuTradeConfig;
	dealURL: string;
	debugFullStory: boolean;
	isFullStoryEnabled: boolean;
	isInStore: boolean;
	launchDarklyFeatureFlags: AutoFiLDFlagSet;
	orgId: string;
	truecar: AccuTradeConfig;
}

const initialState: EnvState = {
	accutrade: {
		apiKey: '',
		iframeUrl: '',
	},
	dealURL: 'http://localhost:3001/t2',
	debugFullStory: false,
	isFullStoryEnabled: false,
	isInStore: false,
	launchDarklyFeatureFlags: {},
	orgId: '',
	truecar: {
		apiKey: '',
		iframeUrl: '',
	},
};

export function envReducer(state: EnvState = initialState, action: Action): EnvState {
	switch (action.type) {
		case types.HYDRATE_ENV: {
			const newState = {
				...state,
				...action.payload,
			};

			return newState;
		}
		default:
			return state;
	}
}

declare module 'react-redux' {
	interface DefaultRootState {
		env: EnvState;
	}
}
