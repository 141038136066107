import get from 'lodash/get';

import { matchPath } from 'react-router-dom';
import { isEmbedded } from '@client/lib/helpers/isEmbedded';
import { PostMessageTypes, sdkPostMessage } from '@client/lib/helpers/analytics';
import header from './header';
import utils from '../js/loan-application/utils.js';
import common from './common.js';
import panels from './panels.js';

const REACT_PANELS = [
	'bestoffer',
	'handoff',
	'home',
	'paymentselector',
	'pii',
	'processeducation',
	'products',
	'receipt',
	'review',
	'contact',
	'waitingforapprovalv2',
	'incomeCalculator',
	'identity',
	'residence',
	'educationLevel',
	'previousResidence',
	'employment',
	'previousEmployment',
	'credit',
	'maritalStatus',
	'spouseIdentity',
	'spouseContact',
	'spouseEmployment',
	'spouseIncome',
	'disclosure',
	'piiReview',
	'piiModal',
	'standAloneThankYou',
];

const REACT_PANELS_W_NAV = [
	'bestoffer',
	'handoff',
	'paymentselector',
	'pii',
	'products',
	'receipt',
	'contact',
	'waitingforapprovalv2',
	'incomeCalculator',
	'identity',
	'residence',
	'educationLevel',
	'previousResidence',
	'employment',
	'previousEmployment',
	'credit',
	'maritalStatus',
	'spouseIdentity',
	'spouseContact',
	'spouseEmployment',
	'spouseIncome',
	'disclosure',
	'piiReview',
	'piiModal',
	'standAloneThankYou',
];

// panels that handle their own tracking events
const NO_TRACKING_PANELS = ['congrats', 'products', 'review'];

window.autofi = get(window, 'autofi', {});

// this needs to go in the database, and allow dealer to customize
let activePanelId;
let $pageLoading;
const fetchProducts = true;
let autofiData;
let autofiFlow;
let autofiTrack;
let autofiPanels;
let resetProducts;
let previousPanelId;
let panelStep = {};
let userExperience = {};

/**
 * For use in tests to reset module-scoped variables. This will be fixed when these are private
 * members of a controller class, and we can test on class instances while exporting a controller
 * singleton from this file to preserve compatibility.
 */
export const __reset = () => {
	activePanelId = undefined;
	$pageLoading = undefined;
	autofiData = undefined;
	autofiFlow = undefined;
	autofiTrack = undefined;
	autofiPanels = undefined;
	resetProducts = undefined;
	previousPanelId = undefined;
	panelStep = {};
	userExperience = {};
};

function updateNav(opts) {
	const $content = $('#content');
	const $navMenu = $('#nav-panel');
	$navMenu.toggleClass('show-nav', opts.showNav);
	$navMenu.find('.loan-application-payment-info').toggleClass('hide-container', !opts.showNavDetails);

	if (opts.isV2 || typeof opts.showNav !== 'undefined') {
		$content.toggleClass('hide-nav', !opts.showNav);
	} else {
		$content.addClass('hide-nav');
	}
}

function getPanel(id) {
	// TODO: clean up once V2 flow is completely refactored to react
	let panel = get(autofiPanels, id, null);
	if (panel === null) {
		panel = Object.assign(get(window, `autofi.panels.${id}`, {}), {
			isV2: true,
		});
	}

	panel.configs = {
		animations: {},
		...panel.configs,
		...get(window, `autofi.data.flow.steps.${id}.configs`, {}),
	};

	return panel;
}

function setActivePanelId(panelId) {
	activePanelId = panelId;
}

function getActivePanelId() {
	return activePanelId;
}

function getPrevPanelId() {
	return previousPanelId;
}

function legacyToggleNav(showNav) {
	if ((autofiData && autofiData.isStandAlone && isEmbedded()) || !get(userExperience, 'global.showNav', true)) {
		showNav = false;
	}
	// hide all other things
	$('.nav')[showNav ? 'addClass' : 'removeClass']('show-nav');
	$('#content')[showNav ? 'addClass' : 'removeClass']('show-nav')[showNav ? 'removeClass' : 'addClass']('hide-nav');
}

function showPanel(id, opts) {
	const aPId = getActivePanelId();

	if (id === aPId && !opts) {
		return;
	}

	// TODO: This `if` fixes an issue with a jQuery-delegated event causing the
	// `back` button on PII to hide the processeducation panel after navigating
	// to it. This should no longer be an issue when handling routing and nav
	// with the router component.
	if (id !== 'processeducation') {
		// hide all other panel
		$('.af-panel:visible').not(`#${id}, #${id} .af-panel, .product-brochure-loading`).fadeOut(100);
	}
	// centralize react app

	if (aPId !== 'receipt') {
		// set prev panel id if we're on receipt to know where to go back to
		previousPanelId = aPId;
	}

	if (REACT_PANELS.includes(id)) {
		const showNavConfig = get(autofiPanels, `${id}.configs.showNav`);
		if (showNavConfig === undefined) {
			legacyToggleNav(REACT_PANELS_W_NAV.includes(id));
		} else {
			legacyToggleNav(showNavConfig);
		}
		setActivePanelId(id);
		if (id === 'pii' && get(opts, 'subpanel')) {
			const activePanel = getPanel(activePanelId);
			activePanelId = 'pii';
			return activePanel.panel.fadeOut(300, () => {
				autofiPanels.pii.goToSubpanel(opts.subpanel);
			});
		}

		if (window.autofi.nav) {
			window.autofi.nav.setCurrentStep(id);
		}

		return;
	}

	const panel = getPanel(id);
	const hideExit = get(panel, 'configs.hideExit', false);
	const showNav = get(panel, 'configs.showNav', false);
	const showNavDetails = get(panel, 'configs.showNavLoanInfo', false);

	$(document).scrollTop(0);

	if (window.self !== window.top && window.postMessageTarget) {
		window.top.postMessage('autofi-scroll-to-top', window.postMessageTarget);
		sdkPostMessage(PostMessageTypes.ScrollToTop);
	}

	function show() {
		const $navExit = $('#exit-btn');

		// perform any initialization that must happen before the panel is displayed
		// to the customer
		if (typeof panel.preShow === 'function') {
			panel.preShow();
		}

		if (panel.isV2 || ['processeducation', 'esign', 'incomeCalculator', 'paymentselector'].includes(id)) {
			header.hideStickyHeader();
			$navExit.toggleClass('afi-u-hidden', hideExit);
		} else {
			$navExit.toggleClass('afi-u-hidden', true);
		}

		const showPanel = panel.panel && panel.panel.length && panel.panel[0] && $(panel.panel[0]);

		showPanel &&
			showPanel.fadeIn &&
			showPanel.fadeIn(400, () => {
				updateNav({
					isV2: get(panel, 'isV2', false),
					hideExit,
					showNav,
					showNavDetails,
				});

				if (typeof panel.postShow === 'function') {
					panel.postShow();
				}
			});

		if (typeof panel.reflow === 'function') {
			panel.reflow();
		}

		if (panel.isV2 || typeof get(panel, 'configs.showNav') !== 'undefined') {
			const getPanelCurrentStep = get(panel, 'currentStep');
			let panelCurrentStep = null;

			if (typeof getPanelCurrentStep === 'function') {
				panelCurrentStep = getPanelCurrentStep();
			} else {
				panelCurrentStep = getPanelCurrentStep;
			}
			if (window.autofi.nav) {
				window.autofi.nav.setCurrentStep(id, panelCurrentStep);
			}
		}

		activePanelId = id;
	}

	if (typeof panel.init === 'function' && id !== 'pii' && id !== 'review') {
		// review is initialized in-component, no need to do so here
		panel.init({
			data: autofiData,
			navigation: {
				next: controller.next,
			},
			// TODO: deprecate the key below once we remove all v2 panels we will
			// be scoping navigation handlers inside of its own nested key/object
			next: controller.next,
		});
	}

	show();

	const trackName = get(autofiFlow, `steps.${id}.track`, id);

	if (!['pii', ...NO_TRACKING_PANELS].includes(trackName)) {
		const { getExpressCheckoutTrackingData, isMatchedPanel } = utils;
		window.autofi.page(
			{
				panel: trackName,
				action: 'load',
			},
			getExpressCheckoutTrackingData({}, isMatchedPanel(trackName, 'trade'))
		);
	}

	if (autofiData.vehicle.status === 'INVALIDVEHICLE' && ['passtodealer', 'passtodealerford'].indexOf(id) < 0) {
		if (
			autofiData.dealer.settings &&
			autofiData.dealer.settings.notifications &&
			autofiData.dealer.settings.notifications.invalidVehicle
		) {
			common.notifyDealer('InvalidVehicle', () => {});
		}
		const offerType = autofiData.requestedOfferType === 'LEASE' ? 'leasing' : 'financing';
		utils.alert(
			'Our apologies',
			`The vehicle selected does not qualify for ${offerType} with AutoFi. You will not be able to continue unless you select a different vehicle.`,
			'error',
			() => {
				exit();
			}
		);
	}
}

function showPreviousPanel(opts) {
	controller.showPanel(previousPanelId, opts);
}

function save(opts, callback) {
	opts = opts || {};

	const currentStepName = activePanelId;
	const data = autofiPanels[currentStepName].results || {};

	// override or go directly to the panel
	if (typeof opts === 'string') {
		return controller.showPanel(opts);
	}

	// otherwise save data
	data.direction = opts.direction || 0;

	// v2 save
	common.savev2(autofiData, currentStepName, data, (err) => {
		if (err) {
			return utils.alert('Oops', get(err, 'message', JSON.stringify(err)), 'error');
		}

		if (callback) {
			return callback();
		}

		controller.showPanel('home');
	});
}

// TODO: remove once V2 flow is removed since we will be using the update endpoint
// instead of the V2 save method and revert to the save method renamed
function getNextStep(step) {
	const nextStep = get(panelStep, step);

	// do not reset when going to review, so that
	// the back button from review goes to the _last_ product not the first
	if (nextStep && nextStep !== 'review') {
		controller.resetProducts = true;
	}

	return nextStep;
}

function next(opts = {}, callback) {
	const saveStep = get(opts, 'saveStep', activePanelId);

	if (saveStep) {
		autofiData.recentCurrentStep = get(window, `autofi.flow.steps.${saveStep}.step`);
	}

	// display panel id passed
	if (typeof opts === 'string') {
		return controller.showPanel(opts);
	}

	const panel = getPanel(saveStep);

	const data = get(panel, 'results', {});

	// save data
	data.direction = opts.direction || 1;

	if (opts.dataOverrides) {
		data.overrides = opts.dataOverrides;
	}

	// use the v2 save method which hits the /step endpoint instead of /update
	// updates the currentStep based on direction, which old panels still rely on
	common.savev2(autofiData, saveStep, data, (err, newLoanApp) => {
		if (err) {
			if (!opts.handleErrorsInCallback) {
				utils.alert('Oops', get(err, 'message', JSON.stringify(err)), 'error');
			}
			if (callback) {
				callback(err);
			}
			return;
		}

		const nextStep = getNextStep(newLoanApp.currentStep);

		if (nextStep === 'review' && autofiData.showScorpionReviewPanel) {
			window.location.href = `/t2/${autofiData.urlToken}/review`;
			return;
		}

		if (callback) {
			return callback(null, nextStep);
		}

		controller.showPanel(nextStep);
	});
}

function previous() {
	const panelName = autofiData.currentStepName;
	if (!NO_TRACKING_PANELS.includes(panelName)) {
		autofiTrack(
			{
				panel: panelName,
				action: 'click',
				meta: 'back',
			},
			utils.getExpressCheckoutTrackingData()
		);
	}

	const panel = getPanel(activePanelId);

	if (typeof panel.back === 'function' && panel.back(null) === false) {
		// since the panel's back function returned false, it means that we should
		// let the function perform it's own events for sub panels.
		return true;
	}

	const activeStep = autofiFlow.steps[activePanelId];
	let prevStepName = activeStep.prev;

	if (activePanelId) {
		autofiData.recentCurrentStep = autofiFlow.steps[activePanelId].step;
	}

	// logic check to determine if products are available, which allows us to skip
	// this specific panel since nothing will be rendered
	// TODO THIS SHOULD BE MOVED TO FLOW CONFIG
	if (prevStepName === 'products' && autofiData.productsOffered.length === 0) {
		prevStepName = autofiFlow.steps.products.prev;
	}

	// check if prev is an object to pass to rules engine
	if (prevStepName && typeof prevStepName === 'object') {
		prevStepName = common.flowRules('prev', activePanelId);
	}

	if (prevStepName) {
		autofiData.currentStep = autofiFlow.steps[prevStepName].step;
		autofiData.currentStepName = prevStepName;
		controller.showPanel(prevStepName);
	} else {
		window.autofi.appName = 'Dealmaker';
		controller.showPanel('home');
	}
}

function getView(id) {
	const panel = controller.getPanel(id);

	return get(panel, 'panel', null);
}

function showLoading(id) {
	const $view = getView(id);

	if ($view) {
		$view.find(`.afi-v2-continue${id === 'tradein' ? ':visible' : ''}`).addClass('disabled');
		$view
			.find('.inner')
			.append(
				'<div class="af-panel-loading"><p><i class="af af-spinner af-pulse"></i></p><div class="overlay"></div></div>'
			);
	}
}

function hideLoading(id) {
	const $view = getView(id);

	if ($view) {
		$view.find('.afi-v2-continue').removeClass('disabled');
		$view.find('.af-panel-loading').remove();
	}
}

function mapPanelSteps(obj = {}) {
	Object.keys(autofiFlow.steps).forEach((v) => {
		obj[autofiFlow.steps[v].step] = v;
	});
	return obj;
}

function exit() {
	if (window.self !== window.top) {
		// inside iframe
		// eslint-disable-next-line no-console
		console.log('--- closing iframe');
		common.closeIframe();
	} else {
		// eslint-disable-next-line no-console
		console.log('--- closing window');
		window.close();
	}
}

function init(cfg) {
	return new Promise((resolve) => {
		$(function () {
			autofiData = cfg.data;
			autofiFlow = cfg.flow;
			autofiTrack = cfg.track;
			autofiPanels = cfg.panels || panels;
			userExperience = cfg.userExperience || {};
			panelStep = mapPanelSteps();

			// jQuery callbacks/setup
			{
				// bind alert box
				$('.sp-alert .close-modal-icon').on('click', () => {
					$('.sp-alert').hide();
				});

				// bind exit button
				$('#sp-exit-btn').on('click', exit);
				const $navExit = $('#exit-btn');
				$navExit.on('click', exit);

				// bind click events with attribute data-track
				$('[data-track]').on('click', function () {
					const name = $(this).data('track');
					if (name) {
						autofiTrack(name, utils.getExpressCheckoutTrackingData());
					}
				});

				// temp change steve bar appearance if this is ford/fmcc flow, use config in future
				// more overrides in tradein, pii, and products panels
				if (autofiData.settings.decisioning === 'fmcc') {
					$('.steves-bar:not(.ignore-js)').addClass('ford-no-love-steve');
					$('.steves-bar:not(.ignore-js) h5').text('You should know');
				}

				$('.terms-link').attr('href', utils.formatMessage('links/terms'));
				$('.privacy-link').attr('href', utils.formatMessage('links/privacy-policy'));
			}

			setTimeout(async () => {
				let stepName = 'home';
				$pageLoading = $('#page-loading');

				await new Promise((r) => $pageLoading.fadeOut('slow', r));

				// check what state the app is in - route accordingly
				if (autofiData.currentStep >= get(autofiFlow, 'steps.pii.step', 0)) {
					stepName = panelStep[autofiData.currentStep];
					window.autofi.appName = 'loanapp';
				} else {
					window.autofi.appName = 'Dealmaker';
				}

				// Is this a navigation event with an override
				const panelMatch = matchPath('/t2/:token/*', new URL(window.location.href).pathname);
				const currentStepOverride = new URL(window.location.href).searchParams.get('currentStep');
				if (panelMatch && typeof currentStepOverride === 'string') {
					setActivePanelId(currentStepOverride);
					stepName = currentStepOverride;
					// TODO: Do we need to set the `currentStep` on the loanApp as well?
				}

				controller.showPanel(stepName);
				common.sendIframeReady();
				resolve();
			}, 0);
		});
	});
}

const controller = {
	init,
	save,
	next,
	previous,
	setActivePanelId,
	getActivePanelId,
	getPrevPanelId,
	panelStep,
	showPanel,
	showPreviousPanel,
	fetchProducts,
	exit,
	resetProducts,
	getPanel,
	showLoading,
	hideLoading,
	mapPanelSteps,
	legacyToggleNav,
};

export default controller;
window.autofi.controller = controller;
