import { getRollbarInstance } from './getRollbarInstance';

const rollbar = getRollbarInstance();

export const rollbarEvents = {
	debug: (message: string, metadata: Record<string, unknown>) => rollbar?.debug(message, metadata),
	info: (message: string, metadata: Record<string, unknown>) => rollbar?.info(message, metadata),
	warn: (message: string, metadata: Record<string, unknown>) => rollbar?.warn(message, metadata),
	warning: (message: string, metadata: Record<string, unknown>) => rollbar?.warning(message, metadata),
	error: (message: Error | string, metadata: Record<string, unknown>) => rollbar?.error(message, metadata),
	critical: (message: string, metadata: Record<string, unknown>) => rollbar?.critical(message, metadata),
};
