import React from 'react';
import PropTypes from 'prop-types';

const TextInput = (props) => <input {...props} />;

TextInput.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	icon: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	readOnly: PropTypes.bool,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
};

TextInput.defaultProps = {
	type: 'text',
	disabled: false,
	required: false,
	readOnly: false,
};

export default TextInput;
