import React from 'react';
import type { SingleValue } from 'react-select';
import Select from 'react-select';

interface Props {
	className?: string;
	disabled?: boolean;
	isClearable?: boolean;
	label: string;
	onSelect: (option: { label: string; value: string } | null) => void;
	options: { label: string; value: string }[];
	placeholder?: string;
	value: { label: string; value: string } | null;
}

const Dropdown: React.FC<Props> = ({
	className,
	disabled,
	isClearable = true,
	label,
	options,
	placeholder,
	onSelect,
	value,
}) => {
	const handleChange = (option: SingleValue<{ label: string; value: string }>) => {
		onSelect(option || null);
	};

	return (
		<div className={className}>
			<label className="dropdownLabel">{label}</label>
			<Select
				className="searchable-dropdown"
				classNamePrefix="searchableDropDown"
				isClearable={isClearable}
				isDisabled={disabled}
				onChange={handleChange}
				options={options}
				placeholder={placeholder}
				value={value}
			/>
		</div>
	);
};

export default Dropdown;
