import React from 'react';
import PropTypes from 'prop-types';

const PlusIcon = ({ color = '#2693E9' }) => (
	<svg fill="none" height="32" width="32" xmlns="http://www.w3.org/2000/svg">
		<rect fill="#fff" fillOpacity=".01" height="32" rx="4" width="32" />
		<path d="M16.855 8.37h-1.709v6.775H8.37v1.71h6.776v6.776h1.71v-6.776h6.776v-1.71h-6.777V8.37z" fill={color} />
	</svg>
);
PlusIcon.propTypes = {
	color: PropTypes.string,
};

export default PlusIcon;
