import React, { Component } from 'react';
import classnames from 'classnames';
import type MaskedInput from 'react-text-mask';

import TextInput from '@components/TextInput';
import Tooltip from '@components/Tooltip';
import styles from './index.scss';

const zeroWidthSpace = '\u200b';

type OwnProps = {
	[key: string]: any;
	InputComponent?: typeof MaskedInput | typeof TextInput;
	autoFocus?: boolean;
	className?: string;
	confirm?: string;
	defaultValue?: string | number;
	disabled?: boolean;
	hint?: string;
	inputClassNames?: string;
	label?: string;
	mask?: (string | RegExp)[];
	name?: string;
	onChange?: (...args: any[]) => any;
	onFocus?: (...args: any[]) => any;
	placeholder?: string;
	readOnly?: boolean;
	required?: boolean;
	tooltipContent?: string;
	tooltipTitle?: string;
	type?: string;
	useInfoTooltip?: boolean;
	validator?: (...args: any[]) => any;
	value?: string | number;
};

type State = any;

type Props = OwnProps;
class StyledInput extends Component<Props, State> {
	static defaultProps = {
		InputComponent: TextInput,
	};

	state = {
		hasFocus: false,
		isValid: this.props.validator ? this.props.validator(this.props.defaultValue) : true,
		hasError: false,
	};

	handleFocus = () => {
		this.setState({
			hasFocus: true,
		});
	};

	handleBlur = () => {
		this.setState({
			hasFocus: false,
		});
	};

	handleChange = (e: React.ChangeEvent<HTMLInputElement>, valid: boolean) => {
		const { onChange, validator } = this.props;
		let isValid;

		if (validator) {
			isValid = validator(e.target.value);
		} else if (valid !== undefined) {
			// allow passing validation status from a validator in a child component
			isValid = valid;
		} else {
			isValid = true;
		}

		this.setState({
			isValid,
			hasError: !isValid,
		});
		onChange?.(e, isValid);
	};

	render() {
		const {
			InputComponent,
			name,
			label,
			hint,
			confirm,
			inputClassNames,
			useInfoTooltip,
			tooltipTitle,
			tooltipContent,
			...childProps
		} = this.props;

		const { hasFocus, isValid, hasError } = this.state;

		delete childProps.validator;
		delete childProps.onChange;
		if (hasFocus) {
			childProps.placeholder = '';
		}
		return (
			<div
				className={classnames('afi-v2-input', inputClassNames, {
					hasFocus,
					isValid,
					hasError,
				})}
			>
				<label
					className={classnames('afi-v2-c', {
						'afi-v2-label-focus': hasFocus,
						'afi-v2-label-val-err': hasError,
						'afi-v2-label-val': (this.props.value || this.props.defaultValue) && !hasFocus,
					})}
					htmlFor={name}
					style={{ display: 'flex' }}
				>
					{label}
					{useInfoTooltip && (
						<div className={styles['tooltip-container']}>
							<Tooltip arrow="top-left" content={tooltipContent} infoIcon={false} title={tooltipTitle} />
						</div>
					)}
				</label>
				<InputComponent
					className={classnames('afi-v2-apply', styles['afi-v3-apply'])}
					name={name}
					onBlur={this.handleBlur}
					onChange={this.handleChange}
					onFocus={this.handleFocus}
					{...childProps}
				/>
				<div
					className={classnames('afi-v2-input-border afi-v2-bg', {
						'afi-v2-input-focus': hasFocus,
						'afi-v2-input-err': hasError,
					})}
				/>
				<div className="afi-v2-hint" />
				<div className="afi-v2-action" />
				<div className="af-hint">{hint || zeroWidthSpace}</div>
				{confirm && (
					<div className="af-hint">
						<p>{confirm}</p>
						<button className="af-confirm-btn">Confirm</button>
					</div>
				)}
			</div>
		);
	}
}
export default StyledInput;
