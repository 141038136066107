/* eslint-disable @typescript-eslint/naming-convention */
export enum DeskingType {
	CLICK_CANCEL_EDIT_BUTTON = 'user cancels deal edits',
	CLICK_UPDATE_DEAL_BUTTON = 'user clicks on Update Deal when customizing a deal',
	// Address
	DEALER_EDIT_ADDRESS = 'user edits/adds address',
	// APR
	DEALER_EDIT_APR = 'dealer edits the APR value',

	// PricePlan / AXZD
	DEALER_EDIT_AXZ = 'dealer add or remove, edit an axz plan',
	// Customer/Contact
	DEALER_EDIT_CONTACT = 'dealer edits the dealership contact',
	// Credit Range
	DEALER_EDIT_CREDIT_RANGE = 'dealer changes the value of the customer credit range',

	DEALER_EDIT_CUSTOMER = 'dealer edits the customer',

	// discounts
	DEALER_EDIT_DISCOUNT = 'dealer add, remove, edit a discount or accessory',

	// downPayment
	DEALER_EDIT_DOWN_PAYMENT = 'change the value of downPayment/cashDown in customize deal',

	// annual mileage
	DEALER_EDIT_MILEAGE = 'dealer changes the value of the term annual mileage',
	// rates
	DEALER_EDIT_RATE = 'dealer changes the value of the requested rate type',

	// special rebates
	DEALER_EDIT_SPECIAL_REBATES = 'dealer add, remove, special rebates',

	// term
	DEALER_EDIT_TERM = 'dealer changes the value of the term in month',

	// Trade_in
	DEALER_EDIT_TRADE_IN = 'change the value of trade-in in customize deal',

	// vehicle price
	DEALER_EDIT_VEHICLE_PRICE = 'edit the dealer price directly',

	// lock
	DEALER_LOCK_DEAL = 'dealer click on the lock button',
	DEALER_UNLOCK_DEAL = 'dealer click on the unlock button',

	// InStore pre-Desking
	IN_STORE_ADD_CUSTOMER = 'dealer inStore adds customer on DM',

	IN_STORE_ADD_SALESPERSON = 'dealer inStore adds a salesPerson on DM',

	IN_STORE_CONSUMER_VIEW = 'dealer inStore clicks on Consumer View',

	IN_STORE_CUSTOMIZE_DEAL = 'dealer inStore clicks on Customize Deal',

	// DESKING
	LOGIN_DESKING = 'mark the user as logged-in for desking',
}

export enum DealTerm {
	APRChanged = 'APR Changed',
	AXZAdded = 'AXZ Added',
	AXZRemoved = 'AXZ Removed',
	AccessoryAdded = 'Accessory Added',
	AddressAddedUpdated = 'Address Added/Updated',
	ContactAddedUpdated = 'Dealership Contact Added/Updated',
	CreditRangeChanged = 'Credit Range Changed',
	CustomerAddedUpdated = 'Customer Added/Updated',
	DealLocked = 'Deal Locked',
	DealUnlocked = 'Deal Unlocked',
	DiscountAdded = 'Discount Added',
	DiscountChanged = 'Discount Changed',
	DiscountRemoved = 'Discount Removed',
	DownPaymentChanged = 'DownPayment Changed',
	MileageChanged = 'Mileage Changed',
	RatesChanged = 'Rates Changed',
	SpecialRebateAdded = 'Special Rebate Added',
	SpecialRebateRemoved = 'Special Rebate Removed',
	TermChanged = 'Term Changed',
	TradeInChanged = 'TradeIn Value Changed',
	VehiclePriceChanged = 'Vehicle Price Changed',
}

export type DefaultDeskingTrackingOpts = { inStore: boolean };
export type CustomizeDealTrackingOpts<T, U = DefaultDeskingTrackingOpts> = {
	meta: 'Deal Term Customized';
	opts: DefaultDeskingTrackingOpts & { dealTerm: T } & U;
	panel: 'Desking';
};

export type DeskingAction =
	| { type: typeof DeskingType.LOGIN_DESKING }
	| {
			track: {
				meta: 'Add Customer Button Clicked';
				opts: DefaultDeskingTrackingOpts;
				panel: 'Desking';
			};
			type: typeof DeskingType.IN_STORE_ADD_CUSTOMER;
	  }
	| {
			track: {
				meta: 'Add Sales Person Button Clicked';
				opts: DefaultDeskingTrackingOpts;
				panel: 'Desking';
			};
			type: typeof DeskingType.IN_STORE_ADD_SALESPERSON;
	  }
	| {
			track: {
				meta: 'Consumer View Button Clicked';
				opts: DefaultDeskingTrackingOpts;
				panel: 'Desking';
			};
			type: typeof DeskingType.IN_STORE_CONSUMER_VIEW;
	  }
	| {
			track: {
				meta: 'Customize Deal Button Clicked';
				opts: DefaultDeskingTrackingOpts;
				panel: 'Desking';
			};
			type: typeof DeskingType.IN_STORE_CUSTOMIZE_DEAL;
	  }
	| {
			track: {
				meta: 'Update Deal Button Clicked';
				opts: DefaultDeskingTrackingOpts;
				panel: 'Desking';
			};
			type: typeof DeskingType.CLICK_UPDATE_DEAL_BUTTON;
	  }
	| {
			track: {
				meta: 'Revert Deal Button Clicked';
				opts: DefaultDeskingTrackingOpts;
				panel: 'Desking';
			};
			type: typeof DeskingType.CLICK_CANCEL_EDIT_BUTTON;
	  }
	| {
			track: CustomizeDealTrackingOpts<DealTerm.AddressAddedUpdated>;
			type: typeof DeskingType.DEALER_EDIT_ADDRESS;
	  }
	| {
			track: CustomizeDealTrackingOpts<DealTerm.APRChanged>;
			type: typeof DeskingType.DEALER_EDIT_APR;
	  }
	| {
			track: CustomizeDealTrackingOpts<DealTerm.ContactAddedUpdated>;
			type: typeof DeskingType.DEALER_EDIT_CONTACT;
	  }
	| {
			track: CustomizeDealTrackingOpts<DealTerm.CustomerAddedUpdated>;
			type: typeof DeskingType.DEALER_EDIT_CUSTOMER;
	  }
	| {
			track: CustomizeDealTrackingOpts<DealTerm.AXZAdded | DealTerm.AXZRemoved>;
			type: typeof DeskingType.DEALER_EDIT_AXZ;
	  }
	| {
			track: CustomizeDealTrackingOpts<DealTerm.CreditRangeChanged>;
			type: typeof DeskingType.DEALER_EDIT_CREDIT_RANGE;
	  }
	| {
			track: CustomizeDealTrackingOpts<
				DealTerm.AccessoryAdded | DealTerm.DiscountAdded | DealTerm.DiscountChanged | DealTerm.DiscountRemoved
			>;
			type: typeof DeskingType.DEALER_EDIT_DISCOUNT;
	  }
	| {
			track: CustomizeDealTrackingOpts<DealTerm.DownPaymentChanged, { downPayment: number }>;
			type: typeof DeskingType.DEALER_EDIT_DOWN_PAYMENT;
	  }
	| {
			track: CustomizeDealTrackingOpts<DealTerm.MileageChanged>;
			type: typeof DeskingType.DEALER_EDIT_MILEAGE;
	  }
	| {
			track: CustomizeDealTrackingOpts<DealTerm.RatesChanged>;
			type: typeof DeskingType.DEALER_EDIT_RATE;
	  }
	| {
			track: CustomizeDealTrackingOpts<DealTerm.SpecialRebateAdded | DealTerm.SpecialRebateRemoved>;
			type: typeof DeskingType.DEALER_EDIT_SPECIAL_REBATES;
	  }
	| {
			track: CustomizeDealTrackingOpts<DealTerm.TermChanged>;
			type: typeof DeskingType.DEALER_EDIT_TERM;
	  }
	| {
			track: CustomizeDealTrackingOpts<DealTerm.TradeInChanged>;
			type: typeof DeskingType.DEALER_EDIT_TRADE_IN;
	  }
	| {
			track: CustomizeDealTrackingOpts<DealTerm.VehiclePriceChanged, { vehiclePrice: number }>;
			type: typeof DeskingType.DEALER_EDIT_VEHICLE_PRICE;
	  }
	| {
			track: CustomizeDealTrackingOpts<DealTerm.DealLocked>;
			type: typeof DeskingType.DEALER_LOCK_DEAL;
	  }
	| {
			track: CustomizeDealTrackingOpts<DealTerm.DealUnlocked>;
			type: typeof DeskingType.DEALER_UNLOCK_DEAL;
	  };
