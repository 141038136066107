import { postMessage } from '../postMessage';

enum PostMessageTypes {
	Clip = 'autofi-clip',
	Close = 'autofi-close',
	Hide = 'autofi-hide',
	Ready = 'autofi-ready',
	/** Used for custom DM flow for partners (E.g. Rocket) */
	SaveNext = 'autofi-save-next',
	ScrollToTop = 'autofi-scroll-to-top',
}

// This format is for the dealers / partners who use
// the callback option in the Pathways API.
const sdkPostMessage = (eventType: PostMessageTypes, data?: { text?: string }) => {
	const {
		autofi: {
			data: { _id: loanAppId, urlToken },
		},
		location,
	} = window;
	postMessage({
		data: { loanAppId, loanAppUrl: `${location.origin}/t2/${urlToken}`, ...data },
		type: eventType,
	});
};

export { sdkPostMessage, PostMessageTypes };
