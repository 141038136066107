import controller from '../controller';
import htmlStickyHeader from './sticky.pug';
import htmlHeader from './main.pug';
import utils from '../../js/loan-application/utils.js';

let initialized = false;
let skipScrollCheck = false;
let autofiData;
const $elms = {};

function getStickyHeader() {
	return $elms.stickyHeader;
}

function hideStickyHeader() {
	const header = getStickyHeader();

	header && header.addClass('sp-sticky-header--transition sp-sticky-header--scrolled-hidden');
}

function showStickyHeader() {
	const header = getStickyHeader();
	header && header.removeClass('sp-sticky-header--transition sp-sticky-header--scrolled-hidden');
}

function renderStickyHeader() {
	$elms.stickyHeaderContainer = $('nav[data-section="header-sticky"]');
	$elms.stickyHeaderContainer.html(htmlStickyHeader());

	$elms.stickyHeader = $elms.stickyHeaderContainer.find('.sp-sticky-header');
}

function renderHeader() {
	$elms.header = $('header[data-section="header"]');
	$elms.header.html(
		htmlHeader({
			formatMessage: utils.formatMessage,
			data: autofiData,
		})
	);

	$elms.headerPriceBox = $elms.header.find('.sp-home-price-box');
}

function setPricingTotal(value) {
	$elms.stickyHeader.find('[data-pricing="total"]').text(value);
	$elms.header.find('[data-pricing="total"]').text(value);
}

function setPricingMonth(value) {
	$elms.stickyHeader.find('[data-payment="any"]').text(value);
	$elms.header.find('[data-payment="any"]').text(value);
}

function setPricingUnlocked(value) {
	const prefix = utils.getCurrencySymbol();
	const amount = utils.addCommas(value);

	$elms.header.find('[data-unlocked="price-prefix"]').text(prefix);
	$elms.header.find('[data-unlocked="price"]').text(amount);
}

function updateApplicantInfo() {
	if (autofiData.applicant.name.full) {
		$elms.header
			.find('.sp-panel-header')
			.find('.sp-applicant-name')
			.text(
				utils.formatMessage('home/users-deal', {
					fullName: autofiData.applicant.name.full,
				})
			);
		$elms.header.find('.sp-panel-header').find('.sp-applicant-secondary').text(autofiData.applicant.email);
	}
}

function bind() {
	$(window).on('scroll', () => {
		const activePanelId = controller.getActivePanelId();
		const currentPanel = controller.getPanel(activePanelId);
		const isNotHomePanel = activePanelId !== 'home' || currentPanel.isV2;

		if (isNotHomePanel || skipScrollCheck) {
			return false;
		}

		if (!$elms.stickyHeader.length) {
			return;
		}

		window.requestAnimationFrame(() => {
			$elms.stickyHeader.toggleClass(
				'sp-sticky-header--scrolled-hidden',
				$(document).scrollTop() <= $elms.headerPriceBox.offset().top
			);

			skipScrollCheck = false;
		});

		skipScrollCheck = true;
	});
}

function init(cfg) {
	if (!initialized) {
		autofiData = cfg.data;

		renderStickyHeader();
		renderHeader();
		bind();
	}

	initialized = true;
	return $elms;
}

const header = {
	init,
	getStickyHeader,
	hideStickyHeader,
	showStickyHeader,
	setPricingTotal,
	setPricingMonth,
	setPricingUnlocked,
	updateApplicantInfo,
};

export default header;
