import find from 'lodash/fp/find';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import pipe from 'lodash/fp/pipe';

import type { DealerSettingsDeposit } from '@autofidev/models/generated';

import type { LoanApp } from '@client/types';
import { getIsEnabled } from '../../../selectors/account';
import type { Account } from '@client/graphql/generated';

// child object selectors
export const getDealerSettings = getOr({}, 'dealer.settings');
export const getDealer = getOr({}, 'dealer');
export const getLoanInfo = getOr({}, 'loanInfo');
export const getVehicle = getOr({}, 'vehicle');
export const getTradeIn = getOr({}, 'tradeIn');
export const getSteps: (loanApp: LoanApp) => Record<string, unknown> = getOr({}, 'steps');
export const getExpressCheckoutPreferences = getOr({}, 'expressCheckout.preferences');

// individual value selectors

export const getApr = pipe(getLoanInfo, getOr(0, 'apr'));

export const getFeesEnabled = pipe(getDealerSettings, get('fees.enabled'));

export const getCurrencyCode = pipe(getDealerSettings, get('currencyCode'));

export const getDepositSettings: (loanApp: LoanApp) => DealerSettingsDeposit = pipe(
	getDealerSettings,
	get('depositSettings')
);

export const getGoogleAnalyticsClientId = getOr(null, 'analytics.gaClientId');

export const getGoogleAnalyticsMeasurementIds = pipe(
	getDealerSettings,
	getOr(null, 'analytics.googleAnalyticsMeasurementIds')
);

export const getId = get('_id');
export const getDepositPaymentStatus = get('isDepositPaid');

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'loanApp' implicitly has an 'any' type.
export const getOfferType = (loanApp) => {
	const selectedId = getSelectedOfferId(loanApp);
	const selectedType = getSelectedOfferType(loanApp);
	return selectedId ? selectedType : getRequestedOfferType(loanApp);
};

export const getPaymentInterval = getOr('MONTHLY', 'requestedPaymentInterval');

export const getProductFlow = pipe(getDealerSettings, getOr('LEGACY', 'consumerFlow.productFlow'));

export const getProducts = getOr([], 'productsOffered');
export const getProductGroups = getOr([], 'productGroups');

export const getRequestedOfferType = get('requestedOfferType');

export const getSelectedOfferId = pipe(getLoanInfo, getOr(null, 'selectedOfferId'));

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'loanApp' implicitly has an 'any' type.
export const getSelectedOffer = (loanApp) =>
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'el' implicitly has an 'any' type.
	loanApp.pricing.matrix.find((el) => el.id === getSelectedOfferId(loanApp));

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'loanApp' implicitly has an 'any' type.
export const getSelectedOfferLender = (loanApp) => {
	const selectedOffer = getSelectedOffer(loanApp);
	if (!selectedOffer) {
		return null;
	}

	return loanApp.lenders[selectedOffer.lender.id];
};

export const getSelectedOfferType = pipe(getLoanInfo, getOr(null, 'selectedOfferType'));

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'loanApp' implicitly has an 'any' type.
export const getTaxesEnabled = (loanApp) => {
	const offerType = getRequestedOfferType(loanApp);

	// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre
	const propPath = {
		CASH: 'taxes.enabledForCash',
		FINANCE: 'taxes.enabledForFinance',
		LEASE: 'taxes.enabledForLease',
	}[offerType];
	const settings = getDealerSettings(loanApp);
	return getOr(false, propPath)(settings);
};

export const getTermMonths = pipe(getLoanInfo, get('termMonths'));

export const getVehiclePhotoUrl = pipe(getVehicle, get('photoUrl'));

export const getTradeInFormData = pipe(getTradeIn, get('formData'));

export const getTradeInYear = pipe(getTradeIn, get('year'));

export const getTradeInMake = pipe(getTradeIn, get('make'));

export const getTradeInModel = pipe(getTradeIn, get('model'));

export const getTradeInSeries = pipe(getTradeIn, get('series'));

export const getTradeInStyle = pipe(getTradeIn, get('style'));

export const getCountry = pipe(getDealer, getOr('US', 'address.contracts.country'));

export const getPhone: (loanApp: LoanApp) => string = pipe(
	getDealer,
	getOr([], 'phones'),
	find((e) => e.type === 'GENERAL'),
	getOr(null, 'number')
);

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getZipcode = (state) => {
	const getOrNull = getOr(null);

	const zip = getOrNull('applicant.address.zip', state);
	if (zip) {
		return zip;
	}

	if (getOr(false, 'expressCheckout.useDefaultTaxes', state)) {
		return getOrNull('dealer.address.tax.zip', state);
	}

	return null;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'loanApp' implicitly has an 'any' type.
export const getXCTermMonths = (loanApp) => {
	const xcPrefs = getExpressCheckoutPreferences(loanApp);
	const offerType = getOfferType(loanApp).toLowerCase();

	// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre
	return xcPrefs[offerType] && xcPrefs[offerType].termMonths;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'loanApp' implicitly has an 'any' type.
export const isInStore = (loanApp) => loanApp.isInStore;

export const getCurrentStepName: (loanApp: LoanApp) => string = getOr('', 'currentStepName');

export const getPrevStepName = (loanApp: LoanApp) => {
	const steps = getSteps(loanApp);
	const currentStepName = getCurrentStepName(loanApp);
	const currentStep = steps[currentStepName];
	return getOr(null, 'prev')(currentStep);
};

export const hasPrevStep = (loanApp: LoanApp) => !!getPrevStepName(loanApp);

export const isPartnerApp = (account: Account) => account && getIsEnabled(account);

export const getReferrer: (loanApp: LoanApp) => string = getOr('', 'data.referrer');

export const getIsOwnedBySource = getOr(false, 'isOwnedBySource');
