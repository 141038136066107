const getOr = require('lodash/fp/getOr');
const flow = require('lodash/fp/flow');

const getName = (account) => getOr('', 'name', account);
const getCode = (account) => getOr(null, 'code', account);
const getIsEnabled = (account) => getOr(true, 'enable', account);
const getSettings = (account) => getOr(null, 'settings', account);
const getIsUsingInternalApp = flow(getSettings, getOr(false, 'isUsingInternalApp'));
const getNotificationsSettings = flow(getSettings, getOr(null, 'notifications'));
const getEmailSubject = flow(getNotificationsSettings, getOr('AutoFi', 'emailSubject'));
const getProviderName = flow(getNotificationsSettings, getOr('AutoFi', 'providerName'));
const getShouldShowAutoFiBranding = flow(getSettings, getOr(true, 'shouldShowAutoFiBranding'));

module.exports = {
	getName,
	getCode,
	getIsEnabled,
	getIsUsingInternalApp,
	getNotificationsSettings,
	getEmailSubject,
	getProviderName,
	getShouldShowAutoFiBranding,
};
