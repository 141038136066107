import React from 'react';
import PropTypes from 'prop-types';

const AvatarIcon = ({ color = '#002951' }) => (
	<svg height="21" width="16" xmlns="http://www.w3.org/2000/svg">
		<g fill={color} fillRule="nonzero">
			<path d="M8 9.143A4.571 4.571 0 108 0a4.571 4.571 0 000 9.143zm0-6.857a2.286 2.286 0 110 4.571 2.286 2.286 0 010-4.571zM8 11.429a8 8 0 00-8 8 1.143 1.143 0 102.286 0 5.714 5.714 0 0111.428 0 1.143 1.143 0 002.286 0 8 8 0 00-8-8z" />
		</g>
	</svg>
);

AvatarIcon.propTypes = {
	color: PropTypes.string,
};

export default AvatarIcon;
