import type { Dealer, Vehicle } from '@autofidev/models/generated';

import { isInIframe } from '../isInIframe';
import type { LoanApplicationDetailed, RequestedOfferType } from '@client/graphql/generated';

type GA4Vehicle = Vehicle | (Omit<Vehicle, 'year'> & { year: number });

// Make websites required in dealer, because it is needed for postMessage
// @TODO: Remove these types, and use Dealer and LoanApplicationDetailed directly
// when and if cobra is ever fixed to return websites with dealer.
export type DealerWithWebsites = Dealer & { websites: string[] };
export type LoanApplicationDetailedWithWebsites = LoanApplicationDetailed & { dealer: DealerWithWebsites };

// Types will need to be adjusted as we add:update more events
// Specifically as part of the GA4 new events project
// Types are currently adjusted to allow current usage to work
export type EventPayloads = {
	asc_cta_interaction: {
		element_type: string;
		event_action:
			| 'pathway_interaction'
			| 'dealmaker_interaction'
			| 'credit_app_interaction'
			| 'post_decisioning_interaction';
		page_type: 'sales' | 'finance';
		vehicle?: GA4Vehicle;
	};
	asc_form_engagement: {
		department: 'sales' | 'trade';
		form_name: 'credit_app' | 'schedule_appt' | 'trade-in';
		form_type: 'consumer_contact' | 'finance_credit' | 'trade';
		vehicle?: GA4Vehicle;
	};
	asc_form_submission: {
		department: string;
		form_name: string;
		form_type: string;
		vehicle?: GA4Vehicle;
	};
	asc_form_submission_sales: {
		department: string;
		form_name: string;
		form_type: string;
		vehicle?: GA4Vehicle;
	};
	asc_form_submission_sales_appt: {
		department?: string;
		form_name: string;
		form_type: string;
		vehicle?: GA4Vehicle;
	};
	asc_page_view: {
		page_type: string;
		vehicle?: GA4Vehicle;
	};
	asc_retail_process: {
		flow_name: 'compare' | 'credit_app' | 'finalize_deal';
		flow_outcome: 'start' | 'close' | 'recalc' | 'submit' | 'approved';
		item_payment?: RequestedOfferType | 'finance' | 'lease' | 'cash' | 'FINANCE' | 'LEASE' | 'CASH' | undefined;
		page_type: 'sales' | 'finance';
		vehicle?: GA4Vehicle;
	};
};

type Events = typeof sourceDataBuilders;
type EventName = keyof Events;

// Common sourceData event payload
const formSubmissionSourceData = (
	payload: EventPayloads[
		| 'asc_form_submission'
		| 'asc_form_submission_sales_appt'
		| 'asc_form_submission_sales'
		| 'asc_form_engagement']
) => ({
	form_name: payload.form_name,
	form_type: payload.form_type,
	department: payload.department,
	comm_type: 'form',
});

// Event payload data builders (use the above standard for identical events such as form submissions)
const sourceDataBuilders = {
	asc_form_submission: formSubmissionSourceData,
	asc_form_submission_sales_appt: formSubmissionSourceData,
	asc_form_submission_sales: formSubmissionSourceData,
	asc_form_engagement: formSubmissionSourceData,
	asc_retail_process: (payload: EventPayloads['asc_retail_process']) => ({
		flow_name: payload.flow_name,
		flow_outcome: payload.flow_outcome,
		item_payment: payload.item_payment?.toLowerCase(),
		page_type: payload.page_type,
	}),
	asc_cta_interaction: (payload: EventPayloads['asc_cta_interaction']) => ({
		element_type: payload.element_type,
		event_action: payload.event_action,
		page_type: payload.page_type,
	}),
	asc_page_view: (payload: EventPayloads['asc_page_view']) => ({
		page_type: payload.page_type,
	}),
};

type VehiclePayload = {
	item_condition?: string;
	item_id?: string;
	item_make?: string;
	item_model?: string;
	item_number?: string;
	item_year?: string | number;
};
export const buildVehiclePayload = (vehicle: GA4Vehicle): VehiclePayload => {
	return {
		...(vehicle?.age && { item_condition: vehicle.age }),
		...(vehicle?.vin && { item_id: vehicle.vin }),
		...(vehicle?.stockNumber && { item_number: vehicle.stockNumber }),
		...(vehicle?.year && { item_year: vehicle.year.toString() }),
		...(vehicle?.make && { item_make: vehicle.make }),
		...(vehicle?.model && { item_model: vehicle.model }),
	};
};

// just forcing to any for now...
const buildEvent = (eventName: EventName, payload: any) => {
	const sourceDataBuilder = sourceDataBuilders[eventName];

	return {
		...sourceDataBuilder(payload),
		...buildVehiclePayload(payload.vehicle),
	};
};

export const trackGoogleAnalytics4Event = <T extends EventName>(
	eventName: EventName,
	eventPayload: EventPayloads[T],
	{ dealer, isInStore }: { dealer: DealerWithWebsites; isInStore: boolean }
) => {
	let measurementIds: string[] = [];

	try {
		const googleAnalyticsMeasurementIds = dealer.settings.analytics?.googleAnalyticsMeasurementIds || [];
		measurementIds = googleAnalyticsMeasurementIds.filter((id) => !id.toLowerCase().startsWith('ua'));
	} catch (e) {
		/* do nothing */
	}

	// no ids found for GA4 events, we are in Store, or gtag has not been set up, move on
	if (!measurementIds.length || isInStore || !window.gtag) {
		return;
	}

	const eventParams = buildEvent(eventName, eventPayload);

	if (!eventParams) {
		return;
	}

	measurementIds.forEach((measurementId) => {
		window.gtag('event', eventName, {
			event_owner: 'AutoFi',
			send_to: measurementId,
			...eventParams,
		});
	});

	if (isInIframe()) {
		measurementIds.forEach((measurementId) => {
			const message = { event: 'autofiGa4Event', name: eventName, measurementId, params: eventPayload };

			dealer.websites.forEach((domain) => {
				window.parent.postMessage(message, `https://${domain}`);
				window.parent.postMessage(message, `https://www.${domain}`);
			});
		});
	}
};
