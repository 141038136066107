import type { ChangeEvent } from 'react';
import React, { useState, useEffect } from 'react';
import MaskedInput from 'react-text-mask';

const PHONE_MASK_PLACEHOLDER_CHAR = `\u2000`;
const PHONE_MASK = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const validatePhone = (phone: string): boolean => {
	const re = /^\d{10}$/;
	return re.test(phone) && !['0', '1'].includes(phone[0]);
};

interface PhoneInputProps {
	[key: string]: any;
	onChange?: (event: ChangeEvent<HTMLInputElement>, isValid: boolean) => void;
	validator?: (phone: string) => boolean;
	value?: string;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ value: propValue = '', onChange, validator, ...props }) => {
	const [value, setValue] = useState<string>(propValue);

	useEffect(() => {
		setValue(propValue);
	}, [propValue]);

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const number = event.target.value.replace(/\D/g, '');

		if (onChange) {
			const validateFn = validator || validatePhone;
			const isValid = validateFn(number);
			onChange(event, isValid);
		}

		setValue(number);
	};

	return (
		<MaskedInput
			{...props}
			mask={PHONE_MASK}
			onChange={handleInputChange}
			placeholderChar={PHONE_MASK_PLACEHOLDER_CHAR}
			value={value}
		/>
	);
};

export default React.memo(PhoneInput);
