import React from 'react';
import PropTypes from 'prop-types';

const CheckboxIcon = ({ className = 'CheckboxIcon' }) => (
	<svg className={className} height="25" viewBox="0 0 25 25" width="25" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fillRule="evenodd">
			<path d="M0 0h25v25H0z" />
			<path d="M7 13.957L10.004 17 19 7" stroke="#FFF" strokeLinecap="square" strokeWidth="2" />
		</g>
	</svg>
);

CheckboxIcon.propTypes = {
	className: PropTypes.string,
};

export default CheckboxIcon;
