import type { TradeInAction } from '@client/store/actions/tradeIn/types';
import { TradeInType } from '@client/store/actions/tradeIn/types';

export interface TradeInState {
	previouslyDeniedICO: string;
}

const initialState: TradeInState = {
	previouslyDeniedICO: null,
};

export function tradeInReducer(state: TradeInState = initialState, action: TradeInAction): TradeInState {
	switch (action.type) {
		case TradeInType.DENY_ICO: {
			return {
				...state,
				previouslyDeniedICO: action.deniedOfferId,
			};
		}
		case TradeInType.RESTART_TRADE_IN: {
			return {
				...state,
				previouslyDeniedICO: null,
			};
		}
		default:
			return state;
	}
}

declare module 'react-redux' {
	interface DefaultRootState {
		tradeIn: TradeInState;
	}
}
