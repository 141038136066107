export const trackFourEyesLoanAppSubmission = ({
	email,
	firstName,
	lastName,
	phone,
	urlToken,
}: {
	email?: string;
	firstName?: string;
	lastName?: string;
	phone?: string;
	urlToken?: string;
}) => {
	const { postMessageTarget, self, top } = window;

	let targetOrigin = postMessageTarget;

	// postMessageTarget will be === '' in SCA flows,
	// attempt to get our parent window's URL from document.referrer
	// if dealer has a Referrer-Policy set to "no-referrer" this will be empty
	// @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Referrer-Policy
	// possible solution if this is a problem use the dealer websites for our targetOrigin
	if (!targetOrigin && document.referrer) {
		targetOrigin = document.referrer;
	}

	// let's get the origin from the URL if we can
	if (targetOrigin) {
		try {
			targetOrigin = new URL(targetOrigin).origin;
		} catch (e) {
			targetOrigin = '';
		}
	}

	if (self !== top && targetOrigin) {
		top.postMessage(
			{ 'autofi-id': urlToken, nameSpace: 'foureyes-autofi', email, firstName, lastName, phone },
			targetOrigin
		);
	}
};
