/* eslint-disable max-classes-per-file */
import React from 'react';

import type { LOAN_APP_DESKING } from './storeKey';
import { LOAN_APP_DEFAULT } from './storeKey';

export type StoreContextStorage = {
	[LOAN_APP_DEFAULT]: any;
	[LOAN_APP_DESKING]?: any;
};

// Create the context which will hold a reference to the store
export const StoreContext = React.createContext<StoreContextStorage>({
	[LOAN_APP_DEFAULT]: {},
});

type OwnStoreProviderProps = {
	store?: any;
	storeKey?: string;
};

type StoreProviderProps = OwnStoreProviderProps & typeof StoreProvider.defaultProps;

export class StoreProvider extends React.Component<StoreProviderProps> {
	static defaultProps = {
		storeKey: LOAN_APP_DEFAULT,
	};

	render() {
		const { store, storeKey, children } = this.props;

		// Since there may be multiple `Store`s, in order to provide this one in
		// context without overriding, we mix in existing context values
		return (
			<StoreContext.Consumer>
				{(context) => (
					<StoreContext.Provider
						value={{
							...context,
							[storeKey]: store,
						}}
					>
						{children}
					</StoreContext.Provider>
				)}
			</StoreContext.Consumer>
		);
	}
}

type OwnStoreConsumerProps = {
	storeKey?: string;
};

type StoreConsumerProps = OwnStoreConsumerProps & typeof StoreConsumer.defaultProps;

export class StoreConsumer extends React.Component<StoreConsumerProps> {
	static defaultProps = {
		storeKey: LOAN_APP_DEFAULT,
	};

	render() {
		const { storeKey, children } = this.props;
		return (
			<StoreContext.Consumer>
				{(context) => {
					// Provide only only the store for the given key
					// @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin
					const store = context[storeKey];
					// @ts-expect-error ts-migrate(2349) FIXME: No constituent of type 'ReactNode' is callable.
					return children(store);
				}}
			</StoreContext.Consumer>
		);
	}
}
