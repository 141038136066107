import qs from 'qs';

// helper to get URL query params and return true or false
// used to read things like af-instore=true
// this should be considered deprecated, was a lift and shift to ease migration.

const override = {
	isEnabled(flag: string): boolean {
		const query = qs.parse(window.location.search.replace(/^\?/, ''));

		const queryOverride = query[flag];

		if (typeof queryOverride !== 'undefined') {
			return queryOverride === 'true';
		}
		return false;
	},
};

export { override };
