import { getExpressCheckoutTrackingData, isMatchedPanel } from '@public/js/loan-application/utils';

window.autofi = window.autofi || {};

export default window.autofi.track;

export const trackWithDefaults = (trackingData, opts) => {
	const { panel } = trackingData;
	const defaultExpressCheckoutTrackingData = getExpressCheckoutTrackingData({}, isMatchedPanel(panel, 'trade'));

	window.autofi.track(trackingData, {
		...defaultExpressCheckoutTrackingData,
		...opts,
	});
};
