import type { ComponentState } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

import type { LoanApplicationDiscounts, LoanApplicationTaxesAndFees } from '@autofidev/models/generated';

import type {
	Address,
	ExpressCheckoutPreferences,
	LeaseOfferPreferences,
	OfferTypePreferences,
	Rebate,
} from '@client/types';
import { IncentiveOption, OfferType } from '@client/types';
import { RowType } from '@components/PricingBlock/types';
import type LoanAppModel from '@client/lib/models/loanapp-model';
import { selectContact, selectCustomer } from '@client/selectors/loanAppModel';
import type { APR } from '@client/routes/Desking/DealerEdit/pricingBlocks/AmountFinancedBlock/types';

/**
 * this file regroups all mutations that can be applied to the local LoanAppModel
 * when changing any of the terms in the customizeDeal view
 */

type Mutation<T> = (mutationsParams: MutationParams, data: T) => boolean;

interface MutationParams {
	financeType: OfferType;
	loanAppModel: LoanAppModel;
}

export interface HandleMutationsParams extends MutationParams {
	currentState: ComponentState;
}

interface UserInfo {
	email?: string;
	name?: string;
	phone?: string;
}

export type MutationOrder =
	| [RowType.Address, Address]
	| [RowType.AnnualMiles, number]
	| [RowType.APR, APR]
	| [RowType.CashDown, number]
	| [RowType.CreditRange, string]
	| [RowType.Customer, UserInfo]
	| [RowType.Contact, UserInfo]
	| [RowType.Discount, LoanApplicationDiscounts[]]
	| [RowType.DownPayment, number]
	| [RowType.Fee, LoanApplicationTaxesAndFees[]]
	| [RowType.Lock, boolean]
	| [RowType.PaymentInterval, string]
	| [RowType.Rate, IncentiveOption]
	| [RowType.Rebate, string[]]
	| [RowType.Term, number]
	| [RowType.TradeIn, number];

/*
 * Selectors
 */

// @ts-expect-error ts-migrate(2551) FIXME: Property '_data' does not exist on type 'LoanAppMo
const getExpressCheckout = (loanAppModel: LoanAppModel) => loanAppModel._data.expressCheckout;

function getPreferences(loanAppModel: LoanAppModel): ExpressCheckoutPreferences;
function getPreferences(loanAppModel: LoanAppModel, type: OfferType.Lease): LeaseOfferPreferences;
function getPreferences(loanAppModel: LoanAppModel, type: OfferType): OfferTypePreferences;
function getPreferences(
	loanAppModel: LoanAppModel,
	type?: OfferType
): ExpressCheckoutPreferences | OfferTypePreferences {
	const { preferences } = getExpressCheckout(loanAppModel);

	if (type) {
		return preferences[type];
	}

	return preferences;
}

/*
 * Utils
 */

const mutateApp = (hasChange: boolean, mutation: () => void) => {
	if (hasChange) {
		mutation();
	}
	return hasChange;
};

const arraysMatch = <T>(arrA: T[], arrB: T[], sortFields: string[]) => {
	if (arrA.length !== arrB.length) {
		// if the length is different then we know that there has been dealer editing discounts
		return false;
	}

	const sortedA = sortBy(arrA, sortFields);
	const sortedB = sortBy(arrB, sortFields);

	return isEqual(sortedA, sortedB);
};

export const saveAddress = ({ loanAppModel }: MutationParams, address: Address) =>
	mutateApp(!isEqual(address, loanAppModel.applicant.address), () =>
		loanAppModel.setAttribute('applicant.address', {
			...loanAppModel.applicant.address,
			...address,
		})
	);

const saveAPR: Mutation<APR> = ({ financeType, loanAppModel }, data) => {
	const { apr, isExplicit, isSubvented } = data;
	const preferences = getPreferences(loanAppModel, financeType);
	if (!preferences) {
		return false;
	}

	return mutateApp((apr !== undefined && preferences.apr !== apr) || loanAppModel.isSubvented !== isSubvented, () => {
		loanAppModel.apr = apr;
		loanAppModel.isSubvented = isSubvented;
		// there's no way to change hasLockingOverride if it's true
		// isExplicit means that the dealer has explicitly changed the APR manually
		loanAppModel.hasLockingOverride = loanAppModel.hasLockingOverride || !!isExplicit;
	});
};

const saveContact: Mutation<UserInfo> = ({ loanAppModel }: MutationParams, contact: UserInfo) => {
	let shouldUpdate = false;

	if (!isEqual(selectContact(loanAppModel), contact)) {
		shouldUpdate = true;
	}

	const mutation = () => {
		loanAppModel.data.dealerAssociate.name = contact.name;
		loanAppModel.data.dealerAssociate.email = contact.email;
		loanAppModel.data.dealerAssociate.phone = contact.phone;
	};

	return mutateApp(shouldUpdate, mutation);
};

const saveCreditRange = ({ loanAppModel }: MutationParams, data: string) =>
	mutateApp(getPreferences(loanAppModel).creditBand !== data, () =>
		loanAppModel.setAttribute(`expressCheckout.preferences.creditBand`, data)
	);

const saveCustomer: Mutation<UserInfo> = ({ loanAppModel }: MutationParams, customer: UserInfo) => {
	let shouldUpdate = false;

	if (!isEqual(selectCustomer(loanAppModel), customer)) {
		shouldUpdate = true;
	}

	const mutation = () => {
		loanAppModel.data.applicant.name = { full: customer.name };
		loanAppModel.data.applicant.email = customer.email;
		loanAppModel.data.applicant.phone = customer.phone;
	};

	return mutateApp(shouldUpdate, mutation);
};

const saveDiscounts: Mutation<LoanApplicationDiscounts[]> = ({ loanAppModel }, data) =>
	mutateApp(
		!arraysMatch<LoanApplicationDiscounts>(loanAppModel.discounts, data, ['amount', 'include', 'taxable']),
		() => (loanAppModel.discounts = data)
	);

const saveDownValue: Mutation<number> = ({ financeType, loanAppModel }, data) =>
	mutateApp(data !== getPreferences(loanAppModel, financeType).downPayment, () => {
		loanAppModel.setAttribute(`expressCheckout.preferences.${financeType}.downPayment`, data);
		loanAppModel.setAttribute('expressCheckout.preferences.dealerOverrides.downPayment', data);
	});

export const saveFees = ({ loanAppModel }: MutationParams, taxesAndFees: LoanApplicationTaxesAndFees[]) => {
	const getFees = ({ code }: LoanApplicationTaxesAndFees) => code !== 'tax';
	const currentTaxes = loanAppModel.taxesAndFees.filter(({ code }: LoanApplicationTaxesAndFees) => code === 'tax');
	const currentFees = loanAppModel.taxesAndFees.filter(getFees);
	const newFees = taxesAndFees.filter(getFees);

	return mutateApp(!arraysMatch(currentFees, newFees, ['amount', 'offerType']), () =>
		loanAppModel.setAttribute('taxesAndFees', [...currentTaxes, ...newFees])
	);
};

const saveLockState: Mutation<boolean> = ({ loanAppModel }, shouldLock) =>
	mutateApp(loanAppModel.isLocked !== shouldLock, () => (loanAppModel.isLocked = shouldLock));

const saveMileage: Mutation<number> = ({ loanAppModel }, annualMiles) =>
	mutateApp(
		// this should not have been called if we're not on a lease app
		// eslint-disable-next-line no-restricted-globals
		!isNaN(annualMiles) && annualMiles !== getPreferences(loanAppModel, OfferType.Lease).annualMiles,
		() => {
			loanAppModel.setAttribute(`expressCheckout.preferences.${OfferType.Lease}.annualMiles`, annualMiles);
			loanAppModel.setAttribute(`expressCheckout.preferences.${OfferType.Lease}.annualMileage`, annualMiles);
		}
	);

const savePaymentInterval: Mutation<string> = ({ loanAppModel }, data) =>
	mutateApp(
		// not changing the current logic here
		// but might be slightly more optimized to check if there is a change
		true,
		() => (loanAppModel.requestedPaymentInterval = data)
	);

const saveRateType: Mutation<IncentiveOption> = ({ loanAppModel }, data) => {
	const ratePath = '_data.applicant.preferences.incentiveOption';
	return mutateApp(data && data !== get(loanAppModel, ratePath, IncentiveOption.LowestMonthly), () => {
		set(loanAppModel, ratePath, data);
	});
};

const saveSpecialRebates: Mutation<string[]> = ({ loanAppModel }, selectedIds) => {
	const specialRebatesIds = loanAppModel.getApplicableRebates().map((r: Rebate) => r.programId.toString());

	let hasChange = false;
	const newRebatesOffered = loanAppModel.rebatesOffered.map((rebate: Rebate) => {
		const programId = rebate.programId.toString();
		const isSelected = specialRebatesIds.includes(programId) && selectedIds.includes(programId);

		if (isSelected !== rebate.isSelected) {
			hasChange = true;
		}

		return { ...rebate, isSelected };
	});

	return mutateApp(hasChange, () => loanAppModel.setAttribute('rebatesOffered', newRebatesOffered));
};

const saveTerm: Mutation<number> = ({ financeType, loanAppModel }, data) => {
	const offerType = financeType || (loanAppModel.requestedOfferType.toLowerCase() as OfferType);
	return mutateApp(data !== getPreferences(loanAppModel, offerType).termMonths, () =>
		loanAppModel.setAttribute(`expressCheckout.preferences.${offerType}.termMonths`, data)
	);
};

const saveTradeIn = ({ loanAppModel }: MutationParams, bookValue: number) =>
	mutateApp(bookValue !== undefined && bookValue !== getPreferences(loanAppModel)?.tradeIn?.bookValue, () => {
		const { payoff = 0 } = get(loanAppModel.preferences, ['tradeIn'], { payoff: 0 });
		loanAppModel.setAttribute(`tradeIn.amount`, bookValue - payoff);
		loanAppModel.setAttribute(`tradeIn.bookValue`, bookValue);
		loanAppModel.setAttribute(`expressCheckout.preferences.tradeIn.amount`, bookValue - payoff);
		loanAppModel.setAttribute(`expressCheckout.preferences.tradeIn.bookValue`, bookValue);
	});

/**
 * Handles multiple mutations on the loanApp object
 * Then performs a single state update
 *
 * @param {HandleMutationsParams}
 */
export const handleMutations =
	({ financeType, loanAppModel, currentState }: HandleMutationsParams) =>
	(mutations: MutationOrder[] = []) => {
		const mutationParams = { financeType, loanAppModel };

		const stateChanges: [string, any][] = [];
		let hasMutations = false;
		mutations.forEach((mutation) => {
			switch (mutation[0]) {
				case RowType.Address:
					hasMutations = saveAddress(mutationParams, mutation[1]) || hasMutations;
					break;
				case RowType.AnnualMiles:
					if (saveMileage(mutationParams, mutation[1])) {
						stateChanges.push(['annualMiles', Number(mutation[1])]);
					}
					break;
				case RowType.APR:
					hasMutations = saveAPR(mutationParams, mutation[1]) || hasMutations;
					break;
				case RowType.Contact:
					hasMutations = saveContact(mutationParams, mutation[1]) || hasMutations;
					break;
				case RowType.CreditRange:
					if (saveCreditRange(mutationParams, mutation[1])) {
						stateChanges.push(['creditBand', mutation[1]]);
					}
					break;
				case RowType.Customer:
					hasMutations = saveCustomer(mutationParams, mutation[1]) || hasMutations;
					break;
				case RowType.Discount:
					hasMutations = saveDiscounts(mutationParams, mutation[1]) || hasMutations;
					break;
				case RowType.DownPayment:
					if (saveDownValue(mutationParams, mutation[1])) {
						stateChanges.push(['downPayment', Number(mutation[1])]);
					}
					break;
				case RowType.Fee:
					hasMutations = saveFees(mutationParams, mutation[1]) || hasMutations;
					break;
				case RowType.Lock:
					hasMutations = saveLockState(mutationParams, mutation[1]) || hasMutations;
					break;
				case RowType.PaymentInterval:
					if (savePaymentInterval(mutationParams, mutation[1])) {
						stateChanges.push(['paymentInterval', mutation[1]]);
					}
					break;
				case RowType.Rate:
					if (saveRateType(mutationParams, mutation[1])) {
						stateChanges.push(['incentiveOption', mutation[1]]);
					}
					break;
				case RowType.Rebate:
					hasMutations = saveSpecialRebates(mutationParams, mutation[1]) || hasMutations;
					break;
				case RowType.Term:
					if (saveTerm(mutationParams, mutation[1])) {
						stateChanges.push(['termMonths', mutation[1]]);
					}
					break;
				case RowType.TradeIn:
					hasMutations = saveTradeIn(mutationParams, mutation[1]) || hasMutations;
					break;
				default:
					// eslint-disable-next-line no-console
					console.warn(`Possibly unexpected user input event: ${mutation[0]}`);
					stateChanges.push(mutation);
					break;
			}
		});

		if (!hasMutations && !stateChanges.length) {
			return null;
		}

		const changes = stateChanges.reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
		const newState: any = {
			// potentially empty object if there was no stateChange required
			...changes,
			// if we have a loanApp mutation we need to call back fetch estimate
			refetchEstimate: hasMutations,
		};

		if (stateChanges.length) {
			newState.offersData = {
				...currentState.offersData,
				[financeType]: { ...currentState.offersData[financeType], ...changes },
			};
		}

		return newState;
	};
