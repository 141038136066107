import type { Action } from '@client/store/actions';
import { types } from '@client/store/actions';

export interface UserState {
	// TODO: What is this actually going to be?
	dealers: string[];
	email: string | null;
	isAuthenticated: boolean;
	isAutoFiUser: boolean;
	isDealerAdmin: boolean;
	isDeskingLoggedIn: boolean;
	name: { first: string; full: string; last: string } | null;
	role: string | null;
}

const initialState: UserState = {
	name: { first: null, last: null, full: null },
	email: null,
	role: null,
	isAuthenticated: false,
	isAutoFiUser: false,
	isDealerAdmin: false,
	isDeskingLoggedIn: false,
	dealers: [],
};

export function userReducer(state: UserState = initialState, action: Action): UserState {
	switch (action.type) {
		case types.HYDRATE_USER: {
			const newState = {
				...state,
				...action.payload,
			};

			if (newState.isAuthenticated && newState.isDealerAdmin) {
				newState.isDeskingLoggedIn = true;
			}

			return newState;
		}
		case types.LOGIN_DESKING:
			return {
				...state,
				isDeskingLoggedIn: true,
			};
		default:
			return state;
	}
}

declare module 'react-redux' {
	interface DefaultRootState {
		user: UserState;
	}
}
