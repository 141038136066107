import { calculator } from '@autofidev/finance';

import { IcoProvider } from '@client/graphql/generated';
import { isProductSelectedByOfferType } from '@public/js/loan-application/utils';

export const getNonApplicableRebates = (rebatesOffered: any[] = [], rebateItems: any[] = []) => {
	const rebateItemIds = rebateItems.map((rebate) => String(rebate.programId));
	return rebatesOffered
		.filter(
			(rebate) => rebate.isSelected && rebate.isQualifiedOffer && !rebateItemIds.includes(String(rebate.programId))
		)
		.map(setRebateDisclaimers);
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rebate' implicitly has an 'any' type.
export const setRebateDisclaimers = (rebate) => {
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rule' implicitly has an 'any' type.
	const disclaimer = (rebate.rules ?? []).find((rule) => rule.type === 'DISCLAIMER');

	rebate.disclaimer = disclaimer && disclaimer.description;

	return rebate;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter '_discounts'
export const getDiscountsWithDealerCash = (_discounts, dealerCash) => {
	if (!dealerCash) {
		return _discounts;
	}

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'discount' implicitly has an 'any' type.
	const discounts = _discounts.map((discount) => ({ ...discount }));
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'discount' implicitly has an 'any' type.
	const dealerDiscount = discounts.find((discount) => discount.code === 'DLR');

	if (dealerDiscount) {
		dealerDiscount.amount += dealerCash;

		return discounts;
	}

	discounts.push({
		amount: dealerCash,
		title: 'Dealer Discount',
		code: 'DLR',
		include: true,
	});

	return discounts;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'termMonths'
export const getNumberOfPayments = (termMonths, paymentInterval) => {
	if (paymentInterval === 'BIWEEKLY') {
		// NOTE: round this up for cases where term months is not evenly divisible
		// this is the case for term options such as 75 months
		return Math.ceil((termMonths / 12) * 26);
	}

	return termMonths;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'loanAppModel'
const createBreakdown = (loanAppModel, offerData, smartOfferData) => {
	if (smartOfferData.type !== 'cash' && !offerData.monthlyPayment) {
		return {};
	}
	// TODO: Rename dataPod variable below
	const dataPod = smartOfferData;
	let zip = loanAppModel.applicant.address.zip || offerData.zipcode;

	if (!zip && dataPod.vehicle?.dealer?.websiteSettings?.ui?.features?.useDefaultTaxes) {
		// handle useDefaultTaxes scenario, use address used in estimator (dealer address)
		const estimatorAppAddress = dataPod.constructedLoanApp?.applicant?.address ?? {};
		zip = estimatorAppAddress.zip;
		loanAppModel.setAttribute('applicant.address', estimatorAppAddress);
	}

	// @autofidev/finance aka "calculator" will not check the status of a trade in,
	// if the trade in is present it is used in the calculation of taxes and fees
	//
	// SmartCow will "normalize" the trade in and when isApplied or complete is false
	// it removes the trade in, so that @autofidev/finance will not consider it
	//
	// This is the same here, remove the trade in when it is not applied or complete
	// TODO stop using @autofidev/finaince.
	//
	// Making a copy of loanAppModel so we don't mutate the original and possibly have missing data
	// elsewhere in the app that expected the "soft deleted" trade in to be there
	const loanAppModelForCalculator = {
		...loanAppModel.data,
		...((!loanAppModel.tradeIn?.isApplied || !loanAppModel.tradeIn?.complete) && { tradeIn: {} }),
	};

	const completeTaxesAndFees = calculator.taxesAndFeesWithRetailTaxCalc(loanAppModelForCalculator, dataPod.offer);

	const {
		baseBiweeklyPayment,
		biweeklyPayment,
		biweeklySalesTax,
		baseMonthlyPayment,
		monthlyPayment,
		monthlySalesTax,
	} = offerData;
	const { loanInfo, productsOffered, requestedOfferType, requestedPaymentInterval } = loanAppModel;
	const grossCap = offerData?.estimate?.grossCap ?? loanInfo.leasing.grossCap;
	const leaseTaxes = offerData?.estimate?.taxItems ?? loanInfo.leasing.taxItems;
	const acqFee = offerData?.acquisitionFee ?? smartOfferData?.loanInfo?.leasing?.acquisitionFee ?? 0;

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
	const financeTaxes = completeTaxesAndFees.filter((item) => ['FINANCE', 'BOTH', undefined].includes(item.offerType));
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
	const leaseTax = completeTaxesAndFees.filter((item) => ['LEASE', 'BOTH'].includes(item.offerType));

	// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'alwaysShow'
	const filterAlwaysShow = ({ alwaysShow }) => alwaysShow;

	const alwaysShowFees = {
		finance: financeTaxes.filter(filterAlwaysShow),
		lease: leaseTax.filter(filterAlwaysShow),
	};

	const numberOfPayments = getNumberOfPayments(dataPod.offer.termMonths, requestedPaymentInterval);

	const tradeInSettings = loanAppModel.dealer.settings.tradeIn;
	const icoProvider = tradeInSettings.subaruProgram?.enabled
		? IcoProvider.Subaru
		: tradeInSettings.instantCashOffer?.provider;

	const tradeInSource = icoProvider || tradeInSettings.source;

	const selectedProducts = productsOffered.filter((product: object) =>
		isProductSelectedByOfferType(product, requestedOfferType)
	);

	return {
		totalRebates: dataPod.rebatesTotal,
		// NOTE: estimator data model's vehiclePrice actually includes discounts
		requestedPaymentInterval,
		baseBiweeklyPayment,
		biweeklySalesTax,
		biweeklyPayment,
		baseMonthlyPayment,
		monthlyPayment,
		monthlySalesTax,
		numberOfPayments,
		grossCap,
		vehiclePriceAfterDiscounts: dataPod.vehiclePrice,
		onlinePrice: dataPod.cashPrice,
		discounts: dataPod.discounts,
		dealerRetailPrice: loanAppModel.vehicle.dealerRetailPrice,
		nonApplicableRebates: getNonApplicableRebates(
			offerData.rebatesOffered ?? loanAppModel.rebatesOffered,
			dataPod.rebateItems
		),
		rebateItems: dataPod.rebateItems,
		tradeIn: loanAppModel.tradeIn,
		tradeInSource,
		tax: {
			taxesAndFees: dataPod.taxesAndFees,
			totalTaxes: financeTaxes.reduce((sum: any, item: any) => sum + (item.amount ?? 0), 0),
			taxesAndFeesList: financeTaxes,
			isExempt: loanAppModel.applicant.isTaxExempt,
			lease: leaseTaxes,
			alwaysShowFees,
		},
		zip,
		countryCode: loanAppModel.countryCode,
		selectedProducts,
		acqFee,
	};
};

export default createBreakdown;
