import React from 'react';
import PropTypes from 'prop-types';

const Back = ({ fill = '#FFF' }) => (
	<svg height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
		<path d="M14 6.116v1.768H3.394l4.861 4.86L7 14 0 7l7-7 1.255 1.255-4.861 4.861z" fill={fill} fillRule="nonzero" />
	</svg>
);

Back.propTypes = {
	fill: PropTypes.string,
};

export default Back;
