import type { ReactElement } from 'react';
import React from 'react';

import { LOAN_APP_DEFAULT } from '@client/loanAppStore';
// eslint-disable-next-line import/no-named-as-default
import EstimateContext from './context';

type Props = {
	children(data: {}[]): ReactElement;
	storeKey?: string;
};

export const EstimateConsumer = ({ children, storeKey = LOAN_APP_DEFAULT }: Props) => (
	<EstimateContext.Consumer>
		{(context) => {
			// Get only the estimate/loanApp data for the given key
			const value = (context as any)[storeKey];
			return children(value);
		}}
	</EstimateContext.Consumer>
);
