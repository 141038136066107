import React from 'react';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';

const duration = 150;

const defaultStyle = {
	transition: `opacity ${duration}ms ease-in-out`,
	opacity: 0,
};

const transitionStyles = {
	entering: { opacity: 0 },
	entered: { opacity: 1 },
};

const Fade = ({ in: inProp, children }) => (
	<Transition in={inProp} timeout={duration}>
		{(state) => (
			<div
				style={{
					...defaultStyle,
					...transitionStyles[state],
				}}
			>
				{children}
			</div>
		)}
	</Transition>
);

Fade.propTypes = {
	in: PropTypes.bool,
	children: PropTypes.any,
};

export default Fade;
