import React from 'react';
import PropTypes from 'prop-types';

const CollapseIcon = ({ color = '#c4c4c4' }) => (
	<svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M19 9h-2.58l3.29-3.29a1.004 1.004 0 10-1.42-1.42L15 7.57V5a1 1 0 00-2 0v5a1 1 0 001 1h5a1 1 0 000-2zM10 13H5a1 1 0 000 2h2.57l-3.28 3.29a1 1 0 00.325 1.639 1 1 0 001.095-.219L9 16.42V19a1 1 0 102 0v-5a1 1 0 00-1-1z"
			fill={color}
		/>
	</svg>
);
CollapseIcon.propTypes = {
	color: PropTypes.string,
};

export default CollapseIcon;
