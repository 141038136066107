import React from 'react';
import PropTypes from 'prop-types';

const CheckmarkIcon = ({ width = '17', height = '13', stroke = '#48C856' }) => (
	<svg height={height} viewBox="0 0 17 13" width={width} xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2 7l4.495 5L14.94 2"
			fill="none"
			fillRule="evenodd"
			stroke={stroke}
			strokeLinecap="square"
			strokeLinejoin="round"
			strokeWidth="2"
		/>
	</svg>
);

CheckmarkIcon.propTypes = {
	width: PropTypes.string,
	height: PropTypes.string,
	stroke: PropTypes.string,
};

export default CheckmarkIcon;
