import type { Middleware } from 'redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import type { DefaultRootState } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { amplitudeMiddleware } from './middlewares';
import { customerReducer, envReducer, metaReducer, productsReducer, userReducer, tradeInReducer } from './reducers';

const rootReducer = combineReducers({
	customer: customerReducer,
	env: envReducer,
	meta: metaReducer,
	products: productsReducer,
	user: userReducer,
	tradeIn: tradeInReducer,
});

// Include additional middleware here
const middleware: Middleware[] = [amplitudeMiddleware];

export function createStoreWithMiddleware(initialState?: DefaultRootState) {
	return createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));
}

export type Store = ReturnType<typeof rootReducer>;
