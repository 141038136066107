import type { Dealer } from '@autofidev/models/generated';

import { isInIframe } from './isInIframe';

export const postMessage = (payload: { type: string } & Record<string, unknown>) => {
	const { postMessageTarget, top } = window;

	if (!isInIframe()) {
		// Don't track if deal maker is not in an iframe.
		return;
	}

	if (!postMessageTarget) {
		// Don't try to track if postMessageTarget is undefined.
		return;
	}

	top.postMessage(payload, postMessageTarget);
};

export enum PartnerPostMessageEventType {
	AcceptOffer = 'acceptOffer',
	Close = 'close',
	Ready = 'ready',
	Submitted = 'submitted',
}

type PostMessageEventData<T extends PartnerPostMessageEventType> = T extends PartnerPostMessageEventType.AcceptOffer
	? { lenderReferenceId?: string; loanAppId: string }
	: never;

/** Send a message to the site containing the iframe, if this is running in an iframe */
export const postPartnerEventMessage = (
	eventType: PartnerPostMessageEventType,
	dealer: Dealer,
	data?: PostMessageEventData<typeof eventType>
) => {
	if (isInIframe()) {
		const { enabled, eventTypes, targetOrigins } = dealer.websiteSettings.ui.features.postMessage;

		if (enabled && (eventTypes as PartnerPostMessageEventType[]).includes(eventType)) {
			targetOrigins.forEach((origin: string) =>
				window.top.postMessage({ type: `autofi-${eventType}`, ...data }, origin)
			);
		}
	}
};
