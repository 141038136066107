import get from 'lodash/get';

import type { LoanApp } from '@client/types';
import { OfferType } from '@client/types';
import { getEstimate, getNumbers } from '@client/lib/api';
import type LoanAppModel from '@client/lib/models/loanapp-model';
import EstimatorData from './estimator-data';
import { ERROR } from './constants';
import type {
	Estimate,
	EstimatorOpts,
	FetchEstimateArgs,
	Offer,
	EstimateOffer,
	EstimateNumbers,
	EstimateValidOptions,
} from './types';

/**
 * The goal of this helper is to centralize fetching the estimations as well as Finance Numbers
 *
 * Then produce a consistent offer object
 *
 */
export default class Estimator {
	private opts: EstimatorOpts;

	public smartOffer: EstimatorData;

	constructor(opts: EstimatorOpts) {
		this.opts = opts;
		this.smartOffer = new EstimatorData(opts);
	}

	private get financeType() {
		return this.opts.offerType;
	}

	setSmartOffer(payload: any) {
		this.smartOffer = new EstimatorData(payload);
	}

	/**
	 *
	 *
	 * @param {loanAppModel} LoanAppModel
	 *
	 * @private
	 */
	private async fetchEstimate(
		loanAppModel: LoanAppModel,
		{
			annualMiles,
			creditBand,
			downPayment,
			incentiveOption,
			paymentInterval,
			productsAmount,
			rebatesOffered,
			termMonths,
			tradeIn,
		}: FetchEstimateArgs
	) {
		// default payload
		let payload: any = {
			creditBand,
			rebatesOffered,
			tradeIn,
		};

		switch (this.financeType) {
			case OfferType.Finance:
				payload = {
					...payload,
					downPayment,
					incentiveOption,
					paymentInterval,
					productsAmount,
					termMonths,
				};
				break;
			case OfferType.Lease:
				payload = {
					...payload,
					annualMiles,
					downPayment,
					paymentInterval,
					productsAmount,
					termMonths,
				};
				break;
		}

		try {
			const estimate = await getEstimate(payload, loanAppModel, this.financeType);
			return estimate;
		} catch (err) {
			const reason = Error('failed fetchEstimate');
			reason.stack += `\nCaused By:\n ${err.stack}`;
			throw reason;
		}
	}

	private static getNumbersPayload(loanAppModel: LoanAppModel, estimate: Estimate) {
		const { expressCheckout } = loanAppModel.data;
		const { constructedLoanApp } = estimate;
		const { applicant, loanInfo, taxesAndFees, tradeIn } = constructedLoanApp;

		// Overrides from local express checkout
		const { creditBand } = expressCheckout.preferences;
		const { apr, isSubvented } = expressCheckout.preferences.finance;
		// Overrides from loanApp that are not yet persisted to DB
		const { taxRate, totalDiscounts, tieredTaxes } = loanInfo;
		const { address } = applicant;

		// overrides from the received estimate

		return {
			offer: { ...estimate.offer, apr, isSubvented },
			loanApp: {
				applicant: { address },
				expressCheckout: { preferences: { creditBand } },
				loanInfo: { apr, taxRate, totalDiscounts, tieredTaxes },
				taxesAndFees,
				tradeIn,
			},
		};
	}

	private shouldRecalculateOffer(loanAppModel: LoanAppModel) {
		return this.financeType !== OfferType.Cash && Boolean(loanAppModel.dealerOverrides.hasLockingOverride);
	}

	/**
	 * Recalculate the offer based on an estimate and some overrides
	 *
	 * @private
	 */
	private async fetchNumbers(
		loanAppModel: LoanAppModel,
		overrides: {
			loanApp?: any;
			offer: Offer;
		}
	) {
		try {
			const numbers = await getNumbers(loanAppModel, overrides);

			return numbers;
		} catch (err) {
			const reason = Error('failed fetchNumbers');
			reason.stack += `\nCaused By:\n ${err.stack}`;
			throw reason;
		}
	}

	/**
	 * parses potential errors from the estimator response
	 *
	 * @param {object} opts
	 * @param {Estimate} opts.estimate
	 * @param {OfferType[]} opts.unavailableFinanceTypes
	 *
	 * @returns {Error}
	 */
	private validateEstimate = (estimate: Estimate): Error => {
		// @ts-expect-error TODO: fix this began after upgrading lodash types
		const { isInvalidEstimate, message } = get(estimate, 'error', {});

		if (isInvalidEstimate) {
			return Error(ERROR.INVALID_ESTIMATE);
		}

		if (message) {
			if (message.includes('Vehicle is not eligible for')) {
				return Error(ERROR.OFFER_TYPE_UNAVAILABLE);
			}

			if (message.includes('offers found matching requested terms')) {
				return Error(ERROR.NO_OFFERS_MATCHING_REQUESTED_TERM);
			}

			return Error(ERROR.UNHANDLED_ESTIMATOR_ERROR_MESSAGE);
		}

		const isDisabled = get(estimate, 'data.disabled', false);
		// We only check if we have term options for offers that are not cash
		const hasTermOptions =
			this.financeType === OfferType.Cash || get(estimate, 'validOptions.termOptions.length', 0) !== 0;

		if (isDisabled || !hasTermOptions) {
			return Error(ERROR.INVALID_TERM_OPTIONS);
		}
	};

	/**
	 * Send the same offer for unlocked and locked deals
	 *
	 * @param estimate  - original estimate from the estimator
	 * @param offer - offer used to calculate numbers
	 * @param numbers - overriden numbers
	 */
	private normalizeOffer(
		estimate: {
			constructedLoanApp: LoanApp;
			data: EstimateNumbers;
			disabled: boolean;
			offer: EstimateOffer;
			validOptions: EstimateValidOptions;
		},
		offer: any,
		financeResult?: { numbers: EstimateNumbers; taxesAndFees: any[] }
	) {
		if (!financeResult) {
			// numbers have not been recalculated
			// just send the original estimate
			return estimate;
		}

		const { numbers, taxesAndFees } = financeResult;

		estimate.constructedLoanApp.taxesAndFees = taxesAndFees;

		return {
			...estimate,
			data: numbers,
			offer,
		};
	}

	/**
	 * Fetches estimate from estimator endpoint
	 * recalculates finance numbers
	 *
	 * returns a consistent Offer object
	 *
	 * @param {loanAppModel} LoanAppModel
	 * @param {FetchEstimateArgs} opts
	 * @returns {Promise<Offer>}
	 */
	public async fetch(loanAppModel: LoanAppModel, opts: FetchEstimateArgs): Promise<Offer> {
		const estimate = await this.fetchEstimate(loanAppModel, opts);
		const error = this.validateEstimate(estimate);

		if (error) {
			return { ...estimate, error, offerType: this.financeType };
		}

		if (!this.shouldRecalculateOffer(loanAppModel)) {
			return this.normalizeOffer(estimate, opts);
		}

		// we have to call finance
		const overriddenPayload = Estimator.getNumbersPayload(loanAppModel, estimate);
		const financeResult = await this.fetchNumbers(loanAppModel, overriddenPayload);

		return this.normalizeOffer(estimate, overriddenPayload.offer, financeResult);
	}
}
