import api from '@client/lib/api';

export const LOG_URL = '/log';
export const APP_NAME = 'Consumer v3';
export const LOG_TYPES = {
	error: 'Client Error Log',
	info: 'Client Info Log',
	performance: 'Client Performance Log',
};

/**
 * Usage:
 * Import dependency
 * import log from from './log';
 *
 * [log.url = 'some logging endpoint']
 * Optionally change the default logging endpoint
 *
 * log.error({...error data to log ...})
 * log.performance('metric ID') logs the time elapsed since navigation start
 */
export class Log {
	private _url: string;

	appName: string;

	constructor() {
		this._url = LOG_URL;
		this.appName = APP_NAME;
	}

	/**
	 * [log description]
	 * @param  {String} [type='error'] type of log to append to [default 'error']
	 * @param  {Object} [data={}]      Data object to log
	 * @return {Void}
	 */
	log(data = {}, type: keyof typeof LOG_TYPES = 'error') {
		api.post(
			this.url,
			{
				...data,
				app: this.appName,
				logType: LOG_TYPES[type],
			},
			{
				prefix: false,
			}
		);
	}

	/**
	 * Log client side errors to API
	 * @param  {Object} data           Data sent to logging API
	 * @return {void}
	 */
	error(data: unknown) {
		this.log(data, 'error');
	}

	/**
	 * send performance metrics to API
	 * @param  {String} label
	 * 	Name of the performance metric
	 * 	(use dot notation for labels. e.g. 'DM.ready' for use in timeseries analytics)
	 * 	(Note: the label will be prefixed with `performance.`)
	 * @param  {Date}   [eventTimeStamp=new Date()]   Timestamp of the event to log
	 * @param  {Date}   [baseTime]  Timestamp to base calculations on (mainly neede for test atm)
	 * @return {void}
	 */
	performance(label: string, eventTimeStamp = new Date(), baseTime?: Date) {
		label = label.replace(/^performance\./, '');
		label = `performance.${label}`;

		const start = baseTime || (window.performance.timing && window.performance.timing.navigationStart) || 0;

		const data = {
			[label]: +eventTimeStamp - +new Date(start),
		};

		this.log(data, 'performance');
	}

	get url() {
		return this._url;
	}

	set url(url) {
		this._url = url;
	}
}

export default new Log();
