import React from 'react';
import PropTypes from 'prop-types';

const AutoFiIcon = ({ colorTop = '#BA9100', colorMiddle = '#D9A900', colorBottom = '#917100' }) => (
	<svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
		<path
			clipRule="evenodd"
			d="M2.21191 6.61307C2.21191 5.74782 2.91334 5.04639 3.7786 5.04639H10.2207C11.086 5.04639 11.7874 5.74782 11.7874 6.61307C11.7874 7.47833 11.086 8.17976 10.2207 8.17976H3.7786C2.91334 8.17976 2.21191 7.47833 2.21191 6.61307Z"
			fill={colorTop}
			fillRule="evenodd"
		/>
		<path
			clipRule="evenodd"
			d="M0 11.6264C0 10.7612 0.701429 10.0598 1.56668 10.0598H8.00879C8.87404 10.0598 9.57547 10.7612 9.57547 11.6264C9.57547 12.4917 8.87404 13.1931 8.00879 13.1931H1.56668C0.701429 13.1931 0 12.4917 0 11.6264Z"
			fill={colorMiddle}
			fillRule="evenodd"
		/>
		<path
			clipRule="evenodd"
			d="M4.4248 1.56669C4.4248 0.701429 5.12623 0 5.99149 0H12.4336C13.2988 0 14.0003 0.701429 14.0003 1.56669C14.0003 2.43194 13.2988 3.13337 12.4336 3.13337H5.99149C5.12623 3.13337 4.4248 2.43194 4.4248 1.56669Z"
			fill={colorBottom}
			fillRule="evenodd"
		/>
	</svg>
);

AutoFiIcon.propTypes = {
	colorBottom: PropTypes.string,
	colorMiddle: PropTypes.string,
	colorTop: PropTypes.string,
};

export default AutoFiIcon;
