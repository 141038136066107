const filterDiscountsByOfferType = (discounts = [], offerType) => {
	return discounts.filter((discount) => {
		const isCustomDiscount = discount.code === 'DLR_CUSTOM_DISCOUNT';
		if (isCustomDiscount && discount.constraints && discount.constraints.length) {
			const { constraint } = discount.constraints[0];
			if (constraint && constraint.requestedOfferType) {
				const include = constraint.requestedOfferType['%in'].includes(offerType.toLowerCase());
				return include;
			}
		}
		return discount.include;
	});
};

module.exports = { filterDiscountsByOfferType };
