import { onError } from '@apollo/client/link/error';

import { logErrorEvent } from '../../../lib/logEvent/logEvent';

export const rollbarErrorLink = onError(({ graphQLErrors, networkError, ...rest }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, locations, path, extensions }) => {
			const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
				locations
			)}, Path: ${path}`;

			// eslint-disable-next-line no-console
			console.error(errorMessage, rest);
			const { headers } = rest.operation.getContext();
			logErrorEvent(errorMessage, { headers, message, locations, path, extensions });
		});
	}

	if (networkError) {
		const errorMessage = `[Network error]: ${networkError}`;

		// eslint-disable-next-line no-console
		console.error(errorMessage);
		logErrorEvent(errorMessage, { networkError, graphQLErrors });
	}
});
