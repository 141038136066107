export function isFieldEnabled(
	settings: { enabledPIIFields: string[] },
	fieldName: string,
	user: 'cosigner' | 'applicant'
) {
	const fn = user === 'cosigner' ? `co_${fieldName}` : fieldName;
	if (fieldName.includes('prev')) {
		return true;
	}
	return Boolean(settings?.enabledPIIFields.includes(fn));
}
