import React from 'react';
import PropTypes from 'prop-types';

const DocIcon = ({ color = '#002951' }) => (
	<svg height="18" width="15" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fillRule="evenodd">
			<path
				d="M-5.946-7h25.892A3.054 3.054 0 0123-3.946v25.892A3.054 3.054 0 0119.946 25H-5.946A3.054 3.054 0 01-9 21.946V-3.946A3.054 3.054 0 01-5.946-7z"
				opacity=".6"
			/>
			<path
				d="M7.955 6.3h4.862L7.955 1.35V6.3zM1.768 0h7.071l5.304 5.4v10.8c0 .994-.792 1.8-1.768 1.8H1.768C.787 18 0 17.19 0 16.2V1.8C0 .801.787 0 1.768 0zm4.42 1.8h-4.42v14.4h10.607V8.1H6.187V1.8z"
				fill={color}
				fillRule="nonzero"
			/>
		</g>
	</svg>
);

DocIcon.propTypes = {
	color: PropTypes.string,
};

export default DocIcon;
