import React from 'react';

const LockWithCircleIcon = ({ ...props }) => (
	<svg height="45px" version="1.1" viewBox="0 0 45 45" width="45px" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g fill="none" fillRule="evenodd" id="PII" stroke="none" strokeWidth="1">
			<g id="p5" transform="translate(-227.000000, -706.000000)">
				<g id="crl_lock_icon" transform="translate(227.000000, 706.000000)">
					<path
						d="M22.5,44 C34.3741221,44 44,34.3741221 44,22.5 C44,10.6258779 34.3741221,1 22.5,1 C10.6258779,1 1,10.6258779 1,22.5 C1,34.3741221 10.6258779,44 22.5,44 Z M22.5,45 C10.0735931,45 0,34.9264069 0,22.5 C0,10.0735931 10.0735931,0 22.5,0 C34.9264069,0 45,10.0735931 45,22.5 C45,34.9264069 34.9264069,45 22.5,45 Z"
						fill="#D7D7D7"
						fillRule="nonzero"
						id="Oval"
					/>
					<g fill="#818181" id="lock_icon" transform="translate(16.500000, 14.000000)">
						<rect height="9.91666667" id="Rectangle" rx="0.9" width="12" x="0" y="6.78059896" />
						<path
							d="M8.48846154,3.8344845 C8.48846154,3.22872303 8.01197329,2.74720086 7.43247034,2.74720086 L4.56752966,2.74720086 C3.99298807,2.74720086 3.51153846,3.23479255 3.51153846,3.84339176 L3.51153846,7.75311935 L1.71153846,7.75311935 L1.71153846,3.84339176 C1.71153846,2.2438697 2.99559051,0.947200864 4.56752966,0.947200864 L7.43247034,0.947200864 C9.00979073,0.947200864 10.2884615,2.2382603 10.2884615,3.8344845 L10.2884615,7.89776984 L8.48846154,7.8674127 L8.48846154,3.8344845 Z"
							id="Path"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default LockWithCircleIcon;
