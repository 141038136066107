import { createContext } from 'react';

import type { LOAN_APP_DESKING } from '@client/loanAppStore';
import { LOAN_APP_DEFAULT } from '@client/loanAppStore';
import type { EstimateProvided } from './types';

export interface EstimateContext {
	[LOAN_APP_DEFAULT]?: EstimateProvided;
	[LOAN_APP_DESKING]?: EstimateProvided;
}

// Create the context we'll use to store the estimate data
export default createContext<EstimateContext>(
	// This value is initialized as empty but filled as soon as EstimateProvider renders
	{ [LOAN_APP_DEFAULT]: {} } as any
);
