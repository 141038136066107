import React from 'react';

const InfoIcon = () => (
	<svg className="active-svg" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fillRule="evenodd">
			<rect fill="#2693E9" height="16" rx="4" width="16" />
			<path
				d="M9.789 13H6.5v-.65h.962V6.825H6.5v-.65h2.327v6.175h.962V13zM7.176 4.069c0-.251.089-.45.266-.598.178-.147.406-.221.683-.221.295 0 .526.074.695.221.17.147.254.347.254.598s-.084.45-.254.598c-.169.147-.4.221-.695.221-.277 0-.505-.074-.683-.221a.736.736 0 0 1-.266-.598z"
				fill="#FFF"
			/>
		</g>
	</svg>
);

export default InfoIcon;
