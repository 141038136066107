import React from 'react';
import type { ComponentType } from 'react';
import PropTypes from 'prop-types';

import { LOAN_APP_DEFAULT } from './storeKey';
import { StoreContext } from './context';

const isValidMapParam = (param: any) => typeof param === 'function';

const connect =
	(mapState: any, mapUpdate?: any) =>
	<T,>(Component: ComponentType<T>) => {
		return class extends React.Component<T> {
			static contextType = StoreContext;

			static propTypes = {
				storeKey: PropTypes.string,
				children: PropTypes.node,
			};

			static defaultProps = {
				storeKey: LOAN_APP_DEFAULT,
			};

			displayName = `connect(${Component.displayName})`;

			// @ts-expect-error ts-migrate(7008) FIXME: Member 'unsubscribe' implicitly has an 'any' type.
			unsubscribe = null;

			componentDidMount() {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'storeKey' does not exist on type 'Readon
				const store = this.context[this.props.storeKey];

				// Subscribe to store changes
				//
				// In Redux, an optimization would be introduced here where updates would
				// only occur if there were changes in the selections made by `mapState`,
				// but since we're not performing immutable updates we have no cheap way
				// to know which changes have occurred
				this.unsubscribe = store.subscribe(() => {
					this.forceUpdate();
				});
			}

			componentWillUnmount() {
				// Do not continue to listen for store changes for removed components
				if (typeof this.unsubscribe === 'function') {
					this.unsubscribe();
				}
			}

			render() {
				// For each render, we need to get fresh props and update functions and
				// pass them along in addition to the original props passed to the
				// component
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'storeKey' does not exist on type 'Readon
				const store = this.context[this.props.storeKey];
				const state = store.getState();
				const mappedProps = {};

				if (isValidMapParam(mapState)) {
					Object.assign(mappedProps, mapState(state));
				}

				if (isValidMapParam(mapUpdate)) {
					Object.assign(mappedProps, mapUpdate(store.update));
				}

				return <Component {...this.props} {...mappedProps} />;
			}
		};
	};

export default connect;
