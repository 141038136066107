export const setFinanceType = (financeType: any) => (data: any) => {
	data.requestedOfferType = financeType.toUpperCase();
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'zip' implicitly has an 'any' type.
export const setZipcode = (zip) => (data) => {
	data.applicant.address.zip = zip;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'groups' implicitly has an 'any' type.
export const setProductGroups = (groups) => (data) => {
	data.productGroups = groups;
};
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'products' implicitly has an 'any' type.
export const setProductsOffered = (products) => (data) => {
	data.productsOffered = products;
};
