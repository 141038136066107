import React from 'react';
import classNames from 'classnames';

import styles from './index.scss';
import LockWithCircleIcon from '@client/svg/LockWithCircleIcon';

type Props = {
	children?: any;
	text?: string;
	title?: string;
	withLockIcon?: boolean;
};

const Footer = ({ text, title = 'You should know', children, withLockIcon }: Props) => (
	<footer className="afi-v2-steve-the-lawyer">
		<div className="steves-bar ford-no-love-steve clearfix">
			<div
				className={classNames('steve-says', {
					[styles.withLockIcon]: withLockIcon,
				})}
			>
				<h5>{title}</h5>
				{withLockIcon && (
					<div className="icon hidden-xs">
						<LockWithCircleIcon />
					</div>
				)}
				{text && (
					<p
						dangerouslySetInnerHTML={{
							__html: text,
						}}
						className="fine-print-text"
					/>
				)}
				{children}
			</div>
		</div>
	</footer>
);

export default Footer;
