import { parse } from 'url';

const getEndpointBaseUrl = () => {
	// parse current location.href for any query params
	const { hostname, protocol, query } = parse(window.location.href, true);

	// combine queryParams from both iframe (parent) and current window frame
	// this is so that we can collect all the overrides.
	const queryParams = {
		...query,
		...(query.postMessageTarget && { ...parse(query.postMessageTarget, true).query }),
	};

	if (queryParams['af-api']) {
		return queryParams['af-api'];
	}

	// for env* endpoints return the env* api endpoint
	if (hostname.match(/\.env\d+\.core\.autofi/)) {
		const match = hostname.match(/\.env(\d+)\.core\.autofi/)[1];
		return `https://falcon.env${match}.core.autofi.io`;
	}

	const endpointConfigs = [
		[
			'https://api-qa.autofi.com',
			[/^autofi-loanapp-qa/, /^mydeal-qa/, /^lender-qa/, /^app-qa/, /^portal-qa/, /\.qa\.core\.autofi/],
		],
		[
			'https://api-staging.autofi.com',
			[
				/^autofi-staging/,
				/^staging\.autofi\.com/,
				/^mydeal-staging/,
				/\.stage\.core\.autofi/,
				/^loanapp-dev\.pr\.core\.autofi\.io$/,
				/^loanapp-staging-pr-\d+\.pr\.core\.autofi\.io$/,
				/^scorpion-staging-pr-\d+\.pr\.core\.autofi\.io$/,
			],
		],
		[`${protocol}//localhost:3010`, [/^localhost/, /^127\.0\.0\.1/, /^loanapp$/, /autofi.localhost$/]],

		// The following config prevents DM from trying to make requests from localhost to
		// prod when running on devices using BrowserStack.
		[`${protocol}//bs-local.com:3010`, [/^bs-local\.com$/]],

		['https://api-uat.autofi.com', [/^autofi-loanapp-uat/, /^uat\.autofi\.com/, /^mydeal-uat/, /\.uat\.core\.autofi/]],
	];

	const [endpoint] = endpointConfigs.find(([, regexps]) => regexps.some((regex) => regex && regex.test(hostname))) || [
		'https://api.autofi.com',
	]; // default to prod

	return endpoint;
};

export const endpointBaseUrl = getEndpointBaseUrl();
export const get = (opts) => $.get({ ...opts, url: `${endpointBaseUrl}${opts.url}` });
export const post = (opts) => $.post({ ...opts, url: `${endpointBaseUrl}${opts.url}` });
