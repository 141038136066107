import type { Middleware } from 'redux';

import { UserRole } from '@client/types';
import track from '@public/v3/track';
import type { Action } from '../actions';

// These fields are present on every amplitude event
interface CommonAmplitudeFields {
	loggedIn: boolean;
	userEmail: string | null;
	userName: string | null;
}

interface GlobalAmplitudeFields extends CommonAmplitudeFields {
	userRole: UserRole;
}

interface AmplitudeFields extends CommonAmplitudeFields {
	userRole: string | null;
}

const transformOpts = (opts: GlobalAmplitudeFields): AmplitudeFields => {
	const intuitiveRoleNames = {
		[UserRole.Admin]: 'Autofi Admin',
		[UserRole.DealerAdmin]: 'Dealer Admin',
		[UserRole.SalesRep]: 'Sales Person',
		[UserRole.Su]: 'Super Admin',
	};

	const intuitiveRoleName: string = intuitiveRoleNames[opts.userRole];

	return {
		...opts,
		...(opts.userRole && { userRole: intuitiveRoleName || opts.userRole }),
	};
};

export const amplitudeMiddleware: Middleware = (store) => (next) => (action: Action) => {
	if (!('track' in action)) {
		return next(action);
	}

	const { email, isAuthenticated, name, role } = store.getState().user;
	const globalFields: GlobalAmplitudeFields = {
		loggedIn: isAuthenticated,
		...(email && { userEmail: email }),
		...(name && { userName: name.full }),
		...(role && { userRole: role }),
	};

	const { panel, meta, opts: actionOpts } = action.track;

	let opts = globalFields;
	if (actionOpts) {
		opts = { ...opts, ...actionOpts };
	}

	// Send the event to amplitude
	track({ panel, meta }, transformOpts(opts));

	return next(action);
};
