import { ApolloClient, type ApolloLink, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { v4 as uuidV4 } from 'uuid';

import { endpointBaseUrl } from '@public/v3/request';
import fragmentMatcher from '@client/graphql/generatedFragmentMatcher';
import { rollbarErrorLink } from './rollbarErrorLink';
import { formatURL } from '../lib/api/api';
import { name, version } from '../../../package.json';

const retryLink = new RetryLink();

const httpLink = createHttpLink({
	uri: formatURL(`${endpointBaseUrl}/graphql`, { shouldPrefix: false, shouldUseOverrides: true }),
});

/* Generate a client identifier to pass to all GraphQL requests for a client instance. */
export const clientId = uuidV4();

let activeLink: ApolloLink;

if (typeof window === 'undefined') {
	activeLink = httpLink;
} else {
	const { host, protocol } = new URL(endpointBaseUrl);
	const url = `${protocol === 'http:' ? 'ws://' : 'wss://'}${host}/graphql`;

	const wsLink = new GraphQLWsLink(
		createClient({
			url,
			connectionParams: {
				headers: {
					/**
					 * Headers are normally case-insensitive. However, that doesn't apply here
					 * since these are not standard HTTP request headers.
					 */
					Authorization: `Bearer ${window.jwtToken}`,
					'x-client-id': clientId,
					'x-request-id': `${clientId}-${new Date().getTime()}`,
				},
			},
		})
	);

	// The split function takes three parameters:
	//
	// * A function that's called for each operation to execute
	// * The Link to use for an operation if the function returns a "truthy" value
	// * The Link to use for an operation if the function returns a "falsy" value
	activeLink = split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
		},
		wsLink,
		httpLink
	);
}

const authLink = setContext((_request, { headers }: { headers: { [key: string]: string } }) => {
	const { csrfToken, jwtToken, sessionID } = window;

	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			Authorization: `Bearer ${jwtToken}`,
			'X-CSRF-Token': csrfToken,
			'af-sessionid': sessionID,
		},
	};
});

const errorLink = rollbarErrorLink.concat(retryLink).concat(authLink).concat(activeLink);

const client = new ApolloClient({
	name,
	version: process.env.GIT_COMMIT ?? version,
	cache: new InMemoryCache({ possibleTypes: fragmentMatcher.possibleTypes }),
	link: errorLink,
});

export default client;
