const _ = require('lodash');

module.exports = (obj) => {
	return Object.keys(obj)
		.filter((key) => {
			if (Array.isArray(obj[key])) {
				return obj[key].length;
			}
			if (_.isObject(obj[key])) {
				return Object.keys(obj[key]).length;
			}
			if (typeof obj[key] === 'number') {
				return true;
			}
			return Boolean(obj[key]);
		})
		.reduce((newObj, key) => {
			newObj[key] = obj[key];
			return newObj;
		}, {});
};
