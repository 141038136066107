if (process.env.NODE_ENV === 'test') {
	const Globalize = require('globalize');
	const { getData } = require('../../../lib/i18n');

	// Set up `window.Globalize`
	window.locale = 'en';
	window.globalizeData = getData(window.locale);
	window.Globalize = Globalize;
}

window.Globalize.load(window.globalizeData.modules);
window.Globalize.loadMessages(window.globalizeData.messages);
window.Globalize.locale(window.locale);

window.autofi = window.autofi || {};
window.autofi.globalize = window.Globalize;

export default window.Globalize;
