/* eslint-disable */
/* istanbul ignore file */

/**
 * THIS FILE IS AUTO-GENERATED. DO NOT EDIT!
 * Run: `yarn gql-generate` to regenerate.
 */


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AccountPayload": [
      "Account",
      "AutoFiError"
    ],
    "AddCustomerToAnonymousLeadUserPayload": [
      "AddCustomerToAnonymousLeadUserEmailExistsError",
      "AddCustomerToAnonymousLeadUserLeadUserHasCustomerError",
      "LeadUserProfile"
    ],
    "AddManualNewVehicleOfInterestToLeadUserPayload": [
      "AddManualNewVehicleOfInterestToLeadUserFailedError",
      "AddVehicleOfInterestInvalidAddressError",
      "LeadUserProfile"
    ],
    "AddManualUsedVehicleOfInterestToLeadUserPayload": [
      "AddManualUsedVehicleOfInterestToLeadUserFailedError",
      "AddVehicleOfInterestInvalidAddressError",
      "LeadUserProfile"
    ],
    "AddQuoteToLeadUserFromBaseQuotePayload": [
      "AddQuoteToLeadUserForOfferTypeFailedError",
      "LeadUserProfile"
    ],
    "AddQuoteToLeadUserFromLaasQuotePayload": [
      "AddQuoteToLeadUserFromLaasQuoteFailedError",
      "AddQuoteToLeadUserFromLaasQuoteNotLaasError",
      "LeadUserProfile"
    ],
    "AddVehicleOfInterestToLeadUserPayload": [
      "AddVehicleOfInterestInvalidAddressError",
      "LeadUserProfile"
    ],
    "ArchiveLeadUserVehiclePayload": [
      "ArchiveLeadUserVehicleError",
      "ArchiveLeadUserVehicleNotArchivableError",
      "LeadUserProfile"
    ],
    "BaseError": [
      "AddCustomerToAnonymousLeadUserEmailExistsError",
      "AddCustomerToAnonymousLeadUserLeadUserHasCustomerError",
      "AddManualNewVehicleOfInterestToLeadUserFailedError",
      "AddManualUsedVehicleOfInterestToLeadUserFailedError",
      "AddQuoteToLeadUserForOfferTypeDupError",
      "AddQuoteToLeadUserForOfferTypeFailedError",
      "AddQuoteToLeadUserFromLaasQuoteFailedError",
      "AddQuoteToLeadUserFromLaasQuoteNotLaasError",
      "AddVehicleOfInterestInvalidAddressError",
      "ArchiveLeadUserVehicleError",
      "ArchiveLeadUserVehicleNotArchivableError",
      "BookoutFromSourceError",
      "CompleteDealerTradeInOnLoanApplicationError",
      "CopyLeadUserApplicantToAnotherLeadUserAsCosignerSameLeadUserError",
      "CustomLeaseCreationError",
      "CustomLeaseNotNeededError",
      "CustomLeaseSourceApplicationError",
      "CustomLeaseVehicleAgeError",
      "DealerNotFoundError",
      "DealerOfferQuoteBaseOfferError",
      "DealerOfferQuoteCashNotSupportedError",
      "DeskPricedVehicleQuoteError",
      "DeskQuoteDeskingNotEnabledError",
      "DestinationLeadUserEditableLoanAppNotFoundError",
      "DestinationLeadUserLoanAppNotFoundError",
      "EnterpriseDealmakerCreditAppExistsError",
      "EnterpriseDealmakerLeadExistsError",
      "HighlightOfferConstraintError",
      "HighlightOfferInvalidAppError",
      "HighlightOfferUnknownError",
      "LeadUserEditableLoanAppNotFoundError",
      "LeadUserLoanAppNotFoundError",
      "LoanAppCustomerNotFoundError",
      "LoanApplicationNotEditable",
      "LoanApplicationNotFoundError",
      "LoanApplicationWithNoOffersSelectedError",
      "NoOffersGeneratedForQuoteError",
      "PreQualifyApplicantFrozenError",
      "PreQualifyApplicantLockedError",
      "PreQualifyLeadUserFrozenError",
      "PreQualifyLeadUserLockedError",
      "PreQualifyNoAddressError",
      "PreQualifySoftPullFailedError",
      "PricedInventoryError",
      "ProtectPageError",
      "QuoteFlexBuyError",
      "QuotePresentedOfferInvalidAppError",
      "QuotePresentedOfferUnknownError",
      "QuoteRollPriceError",
      "QuoteRollPriceLtvScopeLeaseNotSupportedError",
      "QuoteRollPriceMissingTradeInError",
      "QuoteRollPriceNegativeNumberError",
      "QuoteRollPriceUnsupportedAdjustByForLtvScopeError",
      "RefreshLeadUserQuoteFailedError",
      "RefreshLeadUserQuoteNotExpiredError",
      "RehashApplicationError",
      "RehashApplicationPricingError",
      "RehashApplicationPricingWithoutStructureError",
      "RehashApplicationUpdateError",
      "RehashInventoryVehicleError",
      "RehashUnknownError",
      "RehashUserError",
      "SaveCustomLeaseNotNeededError",
      "SecondProductsSetByLeadUserIdError",
      "SelectOffersIncorrectSubmittedAppStateError",
      "SelectOffersInputMissingOfferIdsError",
      "SelectOffersToPresentNotSuppressedError",
      "SelectQuotePriceMatrixTermByIdInvalidOfferError",
      "SelectQuotePriceMatrixTermByIdInvalidTermIdError",
      "SelectQuotePriceMatrixTermByIdNoPriceMatrixError",
      "SourceLeadUserLoanAppNotFoundError",
      "SwapLoanAppApplicantsForLeadUserCoapplicantNeedsEmailError",
      "SwapLoanAppApplicantsForLeadUserError",
      "SwapLoanAppApplicantsForLeadUserLoanAppNotEditableError",
      "SwitchDeskQuoteCreationError",
      "UnArchiveLeadUserVehicleError",
      "UnArchiveLeadUserVehicleNoVehiclesError",
      "UnArchiveLeadUserVehicleSoldError",
      "UnfilteredRebatesError",
      "ValidateAddressError",
      "VehicleNotFoundError"
    ],
    "BookoutFromSourcePayload": [
      "BookoutFromSource",
      "BookoutFromSourceError"
    ],
    "CompleteDealerTradeInOnLoanApplicationPayload": [
      "CompleteDealerTradeInOnLoanApplicationError",
      "LoanApplicationDetailed",
      "LoanApplicationNotFoundError"
    ],
    "Contact": [
      "ELeadsCrmContact",
      "VinSolutionsCrmContact"
    ],
    "CopyLeadUserApplicantToAnotherLeadUserAsCosignerPayload": [
      "CopyLeadUserApplicantToAnotherLeadUserAsCosignerSameLeadUserError",
      "DestinationLeadUserEditableLoanAppNotFoundError",
      "DestinationLeadUserLoanAppNotFoundError",
      "LeadUserProfile",
      "SourceLeadUserLoanAppNotFoundError"
    ],
    "CreateEnterpriseDealmakerCreditAppPayload": [
      "DealerNotFoundError",
      "EnterpriseDealmakerCreditAppCreated",
      "EnterpriseDealmakerCreditAppExistsError"
    ],
    "CreateEnterpriseDealmakerLeadPayload": [
      "DealerNotFoundError",
      "EnterpriseDealmakerLeadCreated",
      "EnterpriseDealmakerLeadExistsError",
      "VehicleNotFoundError"
    ],
    "CreateOrUpdateInstantCashOfferPayload": [
      "AutoFiError",
      "InstantCashOffer"
    ],
    "CreateRehashUserPayload": [
      "RehashUser",
      "RehashUserError"
    ],
    "CreateTradeInVehiclePayload": [
      "AutoFiError",
      "CreateTradeInVehicleResponse"
    ],
    "DealerPayload": [
      "AutoFiError",
      "DealerCreateResponse"
    ],
    "DeskCustomLeaseQuotePayload": [
      "CustomLeaseCreationError",
      "CustomLeaseNotNeededError",
      "CustomLeaseSourceApplicationError",
      "CustomLeaseVehicleAgeError",
      "Quote"
    ],
    "DeskDealerOfferQuotePayload": [
      "DealerOfferQuoteBaseOfferError",
      "DealerOfferQuoteCashNotSupportedError",
      "Quote"
    ],
    "DeskPricedVehicleQuotePayload": [
      "DeskPricedVehicleQuoteError",
      "Quote"
    ],
    "DeskQuotePayload": [
      "DeskQuoteDeskingNotEnabledError",
      "Quote"
    ],
    "DisableFlexBuyForDeskedQuotePayload": [
      "Quote",
      "QuoteFlexBuyError"
    ],
    "DisableFlexBuyForQuotePayload": [
      "Quote",
      "QuoteFlexBuyError"
    ],
    "EnableFlexBuyForDeskedQuotePayload": [
      "Quote",
      "QuoteFlexBuyError"
    ],
    "EnableFlexBuyForQuotePayload": [
      "Quote",
      "QuoteFlexBuyError"
    ],
    "EnterpriseDealPayloadBase": [
      "CreateEnterpriseDealPayload",
      "FinanceOfferPreferencesPayload",
      "LeaseOfferPreferencesPayload",
      "UpdateDealTradeInPayload",
      "UpdateDealVehiclePayload"
    ],
    "EstimateOfferPayment": [
      "EstimateCashOfferPayment",
      "EstimateFinanceOfferPayment",
      "EstimateFlexBuyOfferPayment",
      "EstimateLeaseOfferPayment"
    ],
    "EstimateOfferPricing": [
      "EstimateFinanceOfferPricing",
      "EstimateLeaseOfferPricing"
    ],
    "EstimatePaymentsOfferPreferences": [
      "FinanceEstimatePaymentsOfferPreferences",
      "LeaseEstimatePaymentsOfferPreferences"
    ],
    "EventPayload": [
      "InstantCashOfferActionPayload",
      "OfferActionPayload",
      "PanelTransitionPayload",
      "SentDealOpenedPayload",
      "SubmitCreditAppPayload",
      "TradeInActionPayload"
    ],
    "Highlight": [
      "AccessoriesHighlight",
      "AppointmentHighlight",
      "BounceHighlight",
      "CommentHighlight",
      "CreditHighlight",
      "CustomerCreatedHighlight",
      "DealCenterConsumerInviteOpenedHighlight",
      "DeliveryHighlight",
      "DepositHighlight",
      "InvalidVehicleHighlight",
      "PreQualificationHighlight",
      "PrivateOfferHighlight",
      "ProductHighlight",
      "RebateHighlight",
      "SentDealHighlight",
      "SentDealOpenedHighlight",
      "ShowroomHighlight",
      "TestDriveAppointmentHighlight",
      "TradeInHighlight",
      "TransferOwnershipHighlight",
      "UploadedDocumentsHighlight"
    ],
    "HighlightOfferError": [
      "HighlightOfferInvalidAppError",
      "HighlightOfferUnknownError"
    ],
    "IncrementDealerOfferQuotePayload": [
      "DealerOfferQuoteBaseOfferError",
      "DealerOfferQuoteCashNotSupportedError",
      "Quote"
    ],
    "LastPushToDms": [
      "LastPushToDmsError",
      "LastPushToDmsSuccess"
    ],
    "LastPushToDmsErrorDetail": [
      "LastPushToDmsErrorStatusClosed",
      "LastPushToDmsErrorUnknown",
      "LastPushToDmsErrorVinNotFoundInAutomate"
    ],
    "LeadUserPreQualification": [
      "LeadUserPreQualificationApproved",
      "LeadUserPreQualificationDeclined",
      "LeadUserPreQualificationSubmitted"
    ],
    "LeadUserProfilePreQualification": [
      "LeadUserProfilePreQualificationApproved",
      "LeadUserProfilePreQualificationDeclined",
      "LeadUserProfilePreQualificationSubmitted"
    ],
    "LoanApplicationBase": [
      "LoanApplicationConstructed",
      "LoanApplicationDetailed"
    ],
    "LoanApplicationDetailedPayload": [
      "AutoFiError",
      "LoanApplicationDetailed"
    ],
    "LoanApplicationPayload": [
      "AutoFiError",
      "LoanApplicationResponse"
    ],
    "MyDealPayload": [
      "AutoFiError",
      "MyDealResponse"
    ],
    "PayoffQuotePayload": [
      "AutoFiError",
      "PayoffQuoteResponse"
    ],
    "PreQualificationDecision": [
      "PreQualificationDecisionApproved",
      "PreQualificationDecisionConstrained",
      "PreQualificationDecisionDeclined",
      "PreQualificationDecisionError",
      "PreQualificationDecisionPending"
    ],
    "PreQualificationHighlightDecision": [
      "PreQualificationHighlightDecisionApproved",
      "PreQualificationHighlightDecisionDeclined",
      "PreQualificationHighlightDecisionSubmitted"
    ],
    "PreQualificationPricingOffer": [
      "PreQualificationPricingApprovedOffer",
      "PreQualificationPricingConstrainedOffer",
      "PreQualificationPricingCounterOfferOffer",
      "PreQualificationPricingDeclinedOffer",
      "PreQualificationPricingErrorOffer"
    ],
    "PreQualifyApplicantPayload": [
      "LoanAppCustomerNotFoundError",
      "LoanApplicationDetailed",
      "LoanApplicationNotFoundError",
      "PreQualifyApplicantFrozenError",
      "PreQualifyApplicantLockedError",
      "PreQualifyNoAddressError",
      "PreQualifySoftPullFailedError"
    ],
    "PreQualifyCoApplicantPayload": [
      "LoanApplicationDetailed",
      "LoanApplicationNotFoundError",
      "PreQualifyApplicantFrozenError",
      "PreQualifyApplicantLockedError",
      "PreQualifyNoAddressError",
      "PreQualifySoftPullFailedError"
    ],
    "PreQualifyCustomerPayload": [
      "Customer",
      "PreQualifyLeadUserFrozenError",
      "PreQualifyLeadUserLockedError"
    ],
    "PreQualifyLeadUserPayload": [
      "LeadUserProfile",
      "PreQualifyLeadUserFrozenError",
      "PreQualifyLeadUserLockedError"
    ],
    "PricedInventoryNode": [
      "PricedInventoryNodeWithoutPricing",
      "PricedInventoryNodeWithPricing"
    ],
    "PricedInventoryPayload": [
      "PricedInventory",
      "PricedInventoryError"
    ],
    "PricedInventoryVehicle": [
      "PricedInventoryCpoVehicle",
      "PricedInventoryNewVehicle",
      "PricedInventoryUsedVehicle"
    ],
    "PricingStack": [
      "CashPricingStack",
      "FinancePricingStack",
      "LeasePricingStack"
    ],
    "ProtectPagePayload": [
      "ProtectPage",
      "ProtectPageError"
    ],
    "PushToDmsForeignInfo": [
      "PushToDmsForeignInfoWithoutProducts",
      "PushToDmsForeignInfoWithProducts"
    ],
    "QuotePresentedOfferError": [
      "QuotePresentedOfferInvalidAppError",
      "QuotePresentedOfferUnknownError"
    ],
    "QuotePresentedOfferNonApproved": [
      "QuotePresentedConstrainedOfferNonApproved",
      "QuotePresentedLenderOfferNonApproved"
    ],
    "QuotePriceMatrixOffer": [
      "QuotePriceMatrixInValidOffer",
      "QuotePriceMatrixValidOffer"
    ],
    "QuotePriceMatrixTermOverrides": [
      "QuotePriceMatrixFinanceTermOverrides",
      "QuotePriceMatrixLeaseTermOverrides"
    ],
    "QuoteRollLtvPayload": [
      "LoanApplicationNotEditable",
      "LoanApplicationNotFoundError",
      "NoOffersGeneratedForQuoteError",
      "QuoteRollLtv",
      "QuoteRollPriceError",
      "QuoteRollPriceLtvScopeLeaseNotSupportedError",
      "QuoteRollPriceNegativeNumberError"
    ],
    "QuoteRollPricePayload": [
      "LoanApplicationNotEditable",
      "LoanApplicationNotFoundError",
      "NoOffersGeneratedForQuoteError",
      "QuoteRollPrice",
      "QuoteRollPriceError",
      "QuoteRollPriceMissingTradeInError",
      "QuoteRollPriceNegativeNumberError"
    ],
    "RefreshLeadUserQuotePayload": [
      "LeadUserProfile",
      "RefreshLeadUserQuoteFailedError",
      "RefreshLeadUserQuoteNotExpiredError"
    ],
    "RehashApplicationPayload": [
      "RehashApplication",
      "RehashApplicationError",
      "RehashUnknownError"
    ],
    "RehashApplicationPricingPayload": [
      "RehashApplicationPricingDecisions",
      "RehashApplicationPricingError",
      "RehashUnknownError"
    ],
    "RehashApplicationPricingWithoutStructurePayload": [
      "RehashApplicationPricingWithoutStructureDecision",
      "RehashApplicationPricingWithoutStructureError",
      "RehashUnknownError"
    ],
    "RehashUserAcceptedTermsPayload": [
      "RehashUser",
      "RehashUserError"
    ],
    "RehashVehicleInventoryPayload": [
      "RehashInventoryVehicleConnection",
      "RehashInventoryVehicleError",
      "RehashInventoryVehicleFail",
      "RehashUnknownError"
    ],
    "SaveCustomLeaseQuotePayload": [
      "Quote",
      "SaveCustomLeaseNotNeededError"
    ],
    "SaveDealerOfferQuotePayload": [
      "DealerOfferQuoteBaseOfferError",
      "DealerOfferQuoteCashNotSupportedError",
      "Quote"
    ],
    "SearchLeadUserEdge": [
      "ContactEdge",
      "LeadUserEdge"
    ],
    "SecondProductsSetByLeadUserIdPayload": [
      "SecondProductsSetByLeadUserId",
      "SecondProductsSetByLeadUserIdError"
    ],
    "SelectOffersToPresentPayload": [
      "Quote",
      "SelectOffersIncorrectSubmittedAppStateError",
      "SelectOffersInputMissingOfferIdsError",
      "SelectOffersToPresentNotSuppressedError"
    ],
    "SelectQuotePriceMatrixTermByIdPayload": [
      "LoanApplicationNotFoundError",
      "Quote",
      "SelectQuotePriceMatrixTermByIdInvalidOfferError",
      "SelectQuotePriceMatrixTermByIdInvalidTermIdError",
      "SelectQuotePriceMatrixTermByIdNoPriceMatrixError"
    ],
    "SendTextMessageToLeadUserPayload": [
      "SendTextMessageError",
      "SendTextMessageToLeadUserResponse"
    ],
    "SoftpullError": [
      "CreditServiceNotAvailableSoftpullError",
      "NoCreditHistorySoftpullError"
    ],
    "SoftpullPayload": [
      "CreditServiceNotAvailableSoftpullError",
      "NoCreditHistorySoftpullError",
      "Softpull"
    ],
    "SubmitQuoteToLendersPayload": [
      "Quote",
      "SubmitQuoteToLendersError"
    ],
    "SwapLoanAppApplicantsForLeadUserPayload": [
      "LeadUserProfile",
      "SwapLoanAppApplicantsForLeadUserCoapplicantNeedsEmailError",
      "SwapLoanAppApplicantsForLeadUserError",
      "SwapLoanAppApplicantsForLeadUserLoanAppNotEditableError"
    ],
    "SwitchDeskQuoteByOfferTypePayload": [
      "Quote",
      "SwitchDeskQuoteCreationError"
    ],
    "TaxAndFeeOutput": [
      "DetailedTaxOutput",
      "FinanceTaxAndFeeOutput",
      "LeaseTaxAndFeeOutput"
    ],
    "TradeInDetails": [
      "InstantCashOfferTradeInDetails",
      "LeaseTradeInDetails",
      "OwnedTradeInDetails"
    ],
    "TradeInPayload": [
      "AutoFiError",
      "TradeInResponse"
    ],
    "UnArchiveLeadUserVehiclePayload": [
      "LeadUserProfile",
      "UnArchiveLeadUserVehicleError",
      "UnArchiveLeadUserVehicleNoVehiclesError",
      "UnArchiveLeadUserVehicleSoldError"
    ],
    "UnfilteredRebatesPayload": [
      "UnfilteredRebates",
      "UnfilteredRebatesError"
    ],
    "UpdateDeskedQuotePayload": [
      "NoOffersGeneratedForQuoteError",
      "Quote"
    ],
    "UpdateRehashApplicationPayload": [
      "RehashApplicationUpdateError",
      "RehashApplicationUpdateSuccess",
      "RehashUnknownError"
    ],
    "UpdateRehashUserPayload": [
      "RehashUser",
      "RehashUserError"
    ],
    "ValidateAddressPayload": [
      "ValidateAddressError",
      "ValidateAddressResponse"
    ]
  }
};
      export default result;
    