import React from 'react';
import PropTypes from 'prop-types';

const SearchIcon = ({ color = '#002951', opacity = '.3' }) => (
	<svg height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fillRule="evenodd" opacity={opacity} transform="translate(-7 -7)">
			<rect height="32" rx="3" width="32" />
			<path
				d="M13.686 7a6.686 6.686 0 0 1 6.685 6.686 6.702 6.702 0 0 1-1.604 4.35l.278.278h.812L25 23.457 23.457 25l-5.143-5.143v-.812l-.277-.278a6.702 6.702 0 0 1-4.351 1.604 6.686 6.686 0 0 1 0-13.371zm0 2.057a4.61 4.61 0 0 0-4.629 4.629 4.61 4.61 0 0 0 4.629 4.628 4.61 4.61 0 0 0 4.628-4.628 4.61 4.61 0 0 0-4.628-4.629z"
				fill={color}
				fillRule="nonzero"
			/>
		</g>
	</svg>
);

SearchIcon.propTypes = {
	color: PropTypes.string,
	opacity: PropTypes.string,
};

export default SearchIcon;
