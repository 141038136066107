import type { Dealer } from '@autofidev/models/generated';

/**
 * Send a new Google Analytics event
 *
 * @param {string} action
 * @memberof DM
 */
const trackGoogleUniversalAnalyticsEvent = (action: string, { dealer }: { dealer: Dealer }) => {
	let measurementId;

	try {
		const googleAnalyticsMeasurementIds = dealer.settings.analytics?.googleAnalyticsMeasurementIds || [];
		measurementId = googleAnalyticsMeasurementIds.find((id) => id.toLowerCase().startsWith('ua'));
	} catch (e) {
		/* do nothing */
	}

	// no id found for universal analytics, move on.
	if (!measurementId) {
		return;
	}

	if (window.ga) {
		window.ga('send', 'event', 'AutoFI', action);

		return;
	}

	if (window.gtag) {
		window.gtag('event', action, {
			event_category: 'AutoFI',
			send_to: measurementId,
		});
	}
};

export { trackGoogleUniversalAnalyticsEvent };
