import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';

import type {
	LoanApplicationApplicant,
	// LoanApplicationVehicle
} from '@autofidev/models/generated';

/**
 * The vehicle type generated in the models is currently incorrectly typed as a string.
 * Using the GraphQL generated type for now.
 * @todo Change back to the model type once fixed.
 */
import type { LoanApplicationVehicle } from '@client/graphql/generated';
import type { LoanApp, VehicleAge } from '@client/types';
import { IncentiveOption } from '@client/types';
import type LoanAppModel from '@client/lib/models/loanapp-model';
import { formatPhone } from '@public/js/loan-application/utils';

// TODO: Just use `loanAppModel.data`
const selectData = (loanAppModel: LoanAppModel): LoanApp => get('data', loanAppModel);

export const selectCustomer = (
	loanAppModel: LoanAppModel
): { email?: string; name?: string; phone?: string } | null => {
	const { applicant } = loanAppModel.data;

	let name = applicant?.name?.full ?? null;
	if (!name && applicant?.name?.first && applicant?.name?.last) {
		name = `${applicant.name.first} ${applicant.name.last}`;
	}

	const email = applicant?.email ?? null;

	let phone = applicant?.phone ?? null;
	if (phone) {
		phone = formatPhone(phone);
	}

	if (name || email || phone) {
		return { name, email, phone };
	}

	return null;
};

export const selectContact = (loanAppModel: LoanAppModel): { email?: string; name?: string; phone?: string } | null => {
	const { name, email } = loanAppModel.data.dealerAssociate;
	let { phone } = loanAppModel.data.dealerAssociate;

	if (phone) {
		phone = formatPhone(phone);
	}

	if (name || email || phone) {
		return { name, email, phone };
	}

	return null;
};

export const selectApplicant = (loanAppModel: LoanAppModel): LoanApplicationApplicant =>
	get('applicant', selectData(loanAppModel));

export const selectIncentiveOption = (loanAppModel: LoanAppModel): IncentiveOption =>
	getOr(IncentiveOption.LowestMonthly, 'preferences.incentiveOption', selectApplicant(loanAppModel));

export const selectVehicle = (loanAppModel: LoanAppModel): LoanApplicationVehicle =>
	// @ts-expect-error -- vehicle is incorrectly typed in the generated model types. (see imports)
	get('vehicle', selectData(loanAppModel));

export const selectVehicleAge = (loanAppModel: LoanAppModel): VehicleAge =>
	get('age', selectVehicle(loanAppModel)) as VehicleAge;
