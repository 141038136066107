/* eslint-disable no-inner-declarations */
import { calculator } from '@autofidev/finance';
import get from 'lodash/get';
import set from 'lodash/set';

import utils from '../js/loan-application/utils.js';
import svg from './svg/v2.0.0/index.js';
import panels from './panels.js';
import * as request from './request';
import { override } from '@client/lib/helpers/urlOverrides';

import logger from '@client/lib/log';

import track from '@public/v3/track';
import { PostMessageTypes, sdkPostMessage } from '@client/lib/helpers/analytics';
import { isPartnerApp } from '@client/selectors/loanApplication';
import { postPartnerEventMessage, PartnerPostMessageEventType } from '@client/lib/helpers/postMessage';

const common = {
	request,
	div: utils.div,
	flowRules(direction, stepName) {
		function doRule(rule) {
			const object = window.autofi.data;
			let dataValue = get(object, rule.prop);

			if (rule.transform) {
				dataValue = dataValue[rule.transform]();
			}

			let result = undefined;
			if (typeof rule.eq !== 'undefined') {
				result = dataValue === rule.eq;
			}

			if (typeof rule.ne !== 'undefined') {
				result = dataValue !== rule.ne;
			}

			return result;
		}

		let step = window.autofi.flow.steps[stepName];
		let nextStepName;
		let dir = step && step[direction];
		if (!step || !dir) {
			return undefined;
		}

		if (typeof dir === 'string') {
			return dir;
		}

		// using for loop so we can break early
		for (let i = 0; i < dir.rules.length; i++) {
			var rule = dir.rules[i];
			if (doRule(rule)) {
				nextStepName = rule.val;
				break;
			}
		}

		return nextStepName || (dir && dir.default);
	},
	throttleClick(panelId, $el, clickHandler) {
		const panel = panels[panelId] || window.autofi.modals[panelId];
		panel.clickready = true;
		$el.click((e) => {
			if (panel.clickready) {
				panel.clickready = false;
				clickHandler(e);
				setTimeout(() => {
					panel.clickready = true;
				}, 1000);
			}
		});
	},
	strup(str) {
		return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
	},
	bindAutoFiPopover($body, style) {
		const infoEls = $body.find('[data-action="afi-popover"]');
		const dismissEls = $body.find('[data-action="afi-popover-dismiss"]');
		infoEls.off('click');
		dismissEls.each((i, v) => {
			$(v).click();
		});
		dismissEls.off('click');
		infoEls.on('click', function (e) {
			dismissEls.each((i, v) => {
				if (infoEls[i] !== e.currentTarget) {
					$(v).click();
				}
			});

			let $this = $(this);
			let state = e.currentTarget.classList.toggle('active');
			let modal = $this.parent().parent().find('.afi-popover').first();
			let height = modal.height();
			let offset = (height + 25) * -1;

			// show underneath if the modal is too tall
			if ($this.offset().top + offset < 40) {
				offset = 30;
			}

			modal.attr('style', `top: ${offset}px;${style || ''}`);
			modal[state ? 'fadeIn' : 'fadeOut']();
		});

		dismissEls.on('click', function () {
			let $this = $(this);
			let toggl = $this.parent().find('[data-action="afi-popover"]');

			toggl.removeClass('active');
			$(this).hide();
		});
	},
	createAutoFiPopover(title, description) {
		const popover = [
			'<div class="afi-popover media" data-action="afi-popover-dismiss">',
			'<div class="icon-col media-left media-middle">',
			'<div class="info-icon">i</div>',
			'</div>',
			'<div class="text-col media-body media-middle">',
			`<div class="afi-popover-header text-info">${title}</div>`,
			`<div class="afi-popover-body">${description}</div>`,
			'</div>',
			'</div>',
		].join('');
		const button = `<div class="info-button" data-action="afi-popover" data-title="${title}">${svg.popover}</div>`;
		return { popover, button };
	},
	form($parent, inputs) {
		$parent.html('');
		inputs.map((i) => {
			if (!Array.isArray(i)) {
				const validate = typeof i.validate === 'function' ? i.validate : function () {};
				const el = common.div('.afi-v2-input.what', { 'data-pii': 'who' });
				const label = common.div('label.afi-v2-c', i.placeholder);
				const input = common.div('input.afi-v2-apply', {
					onkeyup: keyup,
					onfocus: focus,
					onblur: blur,
					onchange: validate,
					autofocus: i.autofocus || false,
					type: i.type || 'text',
				});
				var place = common.div(
					'.af-placeholder',
					{
						onclick() {
							input.focus();
						},
					},
					i.placeholder
				);
				var border = common.div('.afi-v2-input-border.afi-v2-bg-fast');
				const hint = common.div('.afi-v2-error-hint', i.hint);

				el.appendChild(label);
				el.appendChild(place);
				el.appendChild(input);
				el.appendChild(border);
				el.appendChild(hint);
				$parent.append(el);

				function focus() {
					label.classList.remove('afi-v2-label-val');
					label.classList.add('afi-v2-label-focus');
					border.classList.add('afi-v2-input-focus');
					place.style.display = 'none';
				}
				function blur(e) {
					border.classList.remove('afi-v2-input-focus');
					label.classList.remove('afi-v2-label-focus');
					if (e.currentTarget.value) {
						label.classList.add('afi-v2-label-val');
					} else {
						place.style.display = 'inline';
					}

					validate();
				}
				function keyup(e) {
					place.style.display = e.currentTarget.value ? 'none' : 'inline';
					validate();
				}
			}
		});
	},
	lead(applicantData, callback) {
		if (window.autofi.data.leadSent) {
			return callback();
		}
		track({ label: 'Lead Sent' }, utils.getExpressCheckoutTrackingData());

		const unformatPhone = new RegExp(/[^\d]/g);
		const leadGenData = {
			applicant: {
				name: {
					first: applicantData.firstName,
					last: applicantData.lastName,
				},
			},
			contactMethod: applicantData.phone && applicantData.phone.length ? 'phone' : 'email',
			vehicle: window.autofi.data.vehicle,
			status: {
				main: 'CREATED',
				appState: 'Lead',
			},
			sendCRM: applicantData.sendCRM,
			trackId: window.autofi.trackId,
		};

		if (applicantData.email) {
			leadGenData.applicant.email = applicantData.email;
		}

		if (applicantData.phone) {
			leadGenData.applicant.phone = (applicantData.phone || '').replace(unformatPhone, '');
		}

		$.ajax({
			url: '/api/v1/leads',
			headers: {
				authorization: window.autofi.data.dealer.settings.secret,
			},
			type: 'POST',
			contentType: 'application/json',
			dataType: 'json',
			data: JSON.stringify(leadGenData),
			success() {
				window.autofi.data.leadSent = true;
				callback(null);
			},
			error(x) {
				let err;
				try {
					err = JSON.parse(x.responseText);
				} catch (ex) {
					err = x.responseText;
				}
				callback(err);
			},
		});
	},
	// TODO: if the data produced by this methofd is even used anymore
	// then refactor it to not create a side effect of modifying the loanapp
	getStats(loanApp) {
		const timeNow = new Date().getTime();
		const msSinceLastClick = timeNow - loanApp.timeLastClick;
		loanApp.timeLastClick = timeNow;

		return {
			windowSize: {
				w: $(window).width(),
				h: $(window).height(),
			},
			screenSize: {
				w: window.screen.width,
				h: window.screen.height,
			},
			msSinceLastClick,
		};
	},
	save(loanApp, data = {}, callback = () => {}) {
		const isInStore = override.isEnabled('af-instore') || loanApp.isInStore;

		set(data, 'payload.isInStore', isInStore);

		// add window stats logging to data
		data.stats = this.getStats(loanApp);

		const topLevelSearchParams = new URL(window.location.href).searchParams;

		const overrideParams = topLevelSearchParams.has('postMessageTarget')
			? new URL(topLevelSearchParams.get('postMessageTarget')).searchParams
			: topLevelSearchParams;

		if (overrideParams.has('af-cobra-pr')) {
			data['af-cobra-pr'] = overrideParams.get('af-cobra-pr');
		}

		$.ajax({
			url: `/api/v1/financing/${loanApp._id}/update`,
			type: 'PATCH',
			dataType: 'json',
			contentType: 'application/json',
			data: JSON.stringify(data),
			success(resp) {
				if (resp.newLoanApp) {
					common.putLoanAppOnTheWindow(resp.newLoanApp);
				}

				callback(null, resp);
			},
			error(x) {
				let err;
				try {
					err = JSON.parse(x.responseText);
				} catch (ex) {
					err = x.responseText;
				}

				callback(err || new Error('Error saving application'));
			},
		});
	},

	showLoading(panelId) {
		let $panel = panels[panelId];
		$panel = ($panel && $panel.panel) || (window.autofi.modals[panelId] && window.autofi.modals[panelId].modal);
		$panel
			.find('.inner')
			.append(
				'<div class="af-panel-loading"><p><i class="af af-spinner af-pulse"></i></p><div class="overlay"></div></div>'
			);
	},
	hideLoading(panelId) {
		const $panel = get(panels, `${panelId}.panel`) || get(window, `autofi.modals${panelId}.modal`);
		if ($panel) {
			const $loadingPanel = $panel.find('.af-panel-loading');
			if ($loadingPanel) {
				$loadingPanel.remove();
			}
		}
	},

	putLoanAppOnTheWindow(loanApp) {
		const dealerCountry = get(loanApp, 'dealer.address.contracts.country', 'US');
		let enabledPiiFields = get(loanApp, 'settings.enabledPIIFields', []);
		if (dealerCountry === 'US') {
			enabledPiiFields = enabledPiiFields.map((field) => ({ sin: 'ssn', co_sin: 'co_ssn' }[field] || field));
		} else if (dealerCountry === 'CA') {
			enabledPiiFields = enabledPiiFields.map((field) => ({ ssn: 'sin', co_ssn: 'co_sin' }[field] || field));
		}
		set(loanApp, 'settings.enabledPIIFields', enabledPiiFields);
		window.autofi.data = Object.assign(window.autofi.data, loanApp);
		// Leaving this block here for future use to track down what is modifying
		// window.autofi.data so we can stop doing that.
		/* eslint-disable-next-line */
		// window.autofi.data = new Proxy(Object.assign(window.autofi.data, loanApp), {
		// 	set(target, propKey, value) {
		// 		console.warn(`Setting ${propKey}`, {
		// 			from: target[propKey],
		// 			to: value,
		// 		});
		// 		/* eslint-disable-next-line */
		// 		return Reflect.set(target, propKey, value);
		// 	},
		// });
		if (window.autofi.data.cosigner && window.autofi.data.cosigner.use) {
			window.autofi.data.cosigner.use = true;
		}
	},

	savev2(loanApp, panelId, data, callback) {
		logger.log({ message: `deprecated common.savev2 called via ${panelId} panel` }, 'info');

		// show loading indicator
		const loadingTimeoutId = window.setTimeout(() => {
			common.showLoading(panelId);
		}, 500);
		const self = this;
		// disable button?
		data = data || {};
		data.currentStepName = panelId;
		data.multiLender = true;
		data.isInStore = override.isEnabled('af-instore') || loanApp.isInStore;

		// add window stats logging to data
		data.stats = this.getStats(loanApp);
		$.ajax({
			url: `/api/v1/financing/${window.loanAppId}/step`,
			type: 'PATCH',
			dataType: 'json',
			contentType: 'application/json',
			data: JSON.stringify(data),
			success(resp) {
				if (resp.newLoanApp) {
					common.putLoanAppOnTheWindow(resp.newLoanApp);
				}
				callback(null, loanApp, resp.warning);
			},
			error(x) {
				let err;
				try {
					err = JSON.parse(x.responseText);
				} catch (ex) {
					err = x.responseText;
				}
				callback(err || new Error('Error saving application'));
			},
			complete() {
				window.clearTimeout(loadingTimeoutId);
				window.setTimeout(() => {
					self.hideLoading(panelId);
				}, 350);
			},
		});
	},

	datePicker(parentEl, validDates, startDate, selectedDate, clickHandler) {
		let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		let daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
		let $parent = $(parentEl);
		let selectedDay = selectedDate || startDate;

		function addDays(date, days) {
			const result = new Date(date);
			result.setDate(result.getDate() + days);
			return result;
		}

		function format(date) {
			let m = date.getMonth() + 1;
			let d = date.getDate();
			let y = date.getFullYear();

			m = m < 10 ? `0${m}` : m;
			d = d < 10 ? `0${d}` : d;
			return `${m}/${d}/${y}`;
		}

		function generate() {
			if (selectedDay.getDate() > 28) {
				selectedDay = null;
			}
			let html = [];
			let htmlDays = [];
			let dw = -startDate.getDay();
			let first = addDays(startDate, dw);
			let monthText = months[first.getMonth()];
			let last = 14 - dw;
			let lastDayNum = 0;
			let isMultiMonth = false;

			for (let i = 0; i < 21; i++) {
				// TODO: leaving this as var cause otherwise this is undefined
				// outside this loop where it is used. I'd be surprised if it works as the author intended
				var day = addDays(first, i);
				let cls = 'available';
				let dayNum = day.getDate();
				if (selectedDay === null && dayNum === 1) {
					selectedDay = day;
				}
				if (dayNum > 28) {
					cls = 'badday';
				} else if (day < startDate || i > last) {
					cls = 'disabled';
				} else if (validDates.indexOf(format(day)) === -1) {
					cls = 'disabled';
				} else if (selectedDay && day.getTime() === selectedDay.getTime()) {
					cls += ' selected';
				}

				isMultiMonth = isMultiMonth || dayNum < lastDayNum;
				lastDayNum = dayNum;
				htmlDays.push(`<div class="cal-day ${cls}" data-date="${day.toString()}">${dayNum}</div>`);
			}
			if (isMultiMonth) {
				// switched months
				monthText = `${monthText} - ${months[day.getMonth()]}`;
			}

			html.push(`<div class="cal-month">${monthText}</div><div class="cal-header clearfix">`);
			daysOfWeek.forEach((v, ix) => {
				html.push(`<div class="cal-day${ix === 6 ? ' last' : ''}">${v}</div>`);
			});
			html.push('</div><div class="cal-days">');
			html.push(htmlDays.join(''));
			html.push('</div></div>');
			$parent.html(html.join(''));
			$parent.find('.cal-days .cal-day').on('click', dayClick);
		}

		function dayClick() {
			const el = $(this);
			if (el.hasClass('badday')) {
				utils.alert(
					'Oops',
					'This day is not available because days 29, 30 and 31 do not exist in every month.',
					'info'
				);
			} else if (!el.hasClass('disabled')) {
				$parent.find('.cal-days .cal-day').removeClass('selected');
				selectedDay = new Date(el.data('date'));
				el.addClass('selected');
				if (clickHandler) {
					clickHandler(selectedDay);
				}
			}
		}

		generate();

		return {
			getSelectedDay() {
				return selectedDay;
			},
			setDate(dt) {
				selectedDay = new Date(dt);
				generate();
			},
		};
	},
	sendIframeReady() {
		// eslint-disable-next-line no-console
		console.log('--- autofi ready - postMessageTarget:', window.postMessageTarget);
		if (isPartnerApp(window.autofi.account)) {
			postPartnerEventMessage(PartnerPostMessageEventType.Ready, window.autofi.data.dealer);
			return;
		}
		if (window.postMessageTarget) {
			window.top.postMessage('autofi-ready', window.postMessageTarget);
			sdkPostMessage(PostMessageTypes.Ready);
		}
	},
	closeIframe() {
		// eslint-disable-next-line no-console
		console.log('--- autofi close - postMessageTarget:', window.postMessageTarget);
		if (isPartnerApp(window.autofi.account)) {
			postPartnerEventMessage(PartnerPostMessageEventType.Close, window.autofi.data.dealer);
			return;
		}
		if (window.postMessageTarget) {
			window.top.postMessage('autofi-close', window.postMessageTarget);
			sdkPostMessage(PostMessageTypes.Close);
		}
	},
	hideIframe() {
		try {
			if (window.postMessageTarget) {
				window.top.postMessage('autofi-hide', window.postMessageTarget);
				sdkPostMessage(PostMessageTypes.Hide);
			}
		} catch (ex) {
			document.write('');
		}
	},
	updateLoanInfo(loanApp) {
		const { requestedOfferType, loanInfo } = loanApp;
		let { downPayment, termMonths, apr } = loanInfo;
		let selectedOffer;
		let rebateItems = [];

		loanApp.loanInfo.productsAmount = 0;
		loanApp.productsOffered.forEach((product) => {
			if (!product.userSelected) {
				return;
			}
			// TODO: handle non-pretax for lease later
			const productAmount =
				requestedOfferType === 'LEASE'
					? get(product, 'suggestedRetailPricePretax', 0)
					: get(product, 'suggestedRetailPrice', 0);
			loanApp.loanInfo.productsAmount += productAmount;
		});

		if (loanApp.loanInfo.selectedOfferId) {
			selectedOffer = get(loanApp, 'pricing.matrix', []).find((offer) => loanInfo.selectedOfferId === offer.id);
			rebateItems = get(selectedOffer, 'rebates.items', []);
		}

		const offer = utils.getSelectedOffer(loanApp);
		const calculatorNumbers = calculator.getNumbers(
			loanApp.requestedOfferType,
			{
				...loanApp,
				preOfferRebates: rebateItems,
			},
			offer
		);

		const { amountFinanced, monthlyPayment, biweeklyPayment, totalInterest, effectiveRate } = calculatorNumbers;

		if (!monthlyPayment) {
			return;
		}

		if (loanApp.requestedOfferType === 'LEASE') {
			const enableCreditsPlacement = get(loanApp, 'dealer.settings.pricing.leasing.enableCreditsPlacement', false);
			const availableCash = get(loanApp, 'dealer.settings.pricing.leasing.creditsPlacement.availableCash', 'dueAtSigning');
			downPayment = enableCreditsPlacement && availableCash ? calculatorNumbers.dueAtSigning : calculatorNumbers.cashAppliedToCap;

			// calculatorNumbers doesn't include annualMileage which was
			// breaking on the offers panel and elsewhere.
			// Merging these together solves that
			loanApp.loanInfo.leasing = {
				...loanApp.loanInfo.leasing,
				...calculatorNumbers,
			};
		}

		// Persisting in data
		loanApp.loanInfo.apr = apr;
		loanApp.loanInfo.downPayment = downPayment;
		loanApp.loanInfo.termMonths = termMonths;
		loanApp.loanInfo.amountFinanced = amountFinanced;
		loanApp.loanInfo.monthlyPayment = monthlyPayment;
		loanApp.loanInfo.biweeklyPayment = biweeklyPayment;
		loanApp.loanInfo.totalInterest = totalInterest;
		loanApp.loanInfo.effectiveRate = effectiveRate;

		// Update nav
		if (get(window.autofi, 'nav.updateNavData')) {
			window.autofi.nav.updateNavData();
		}
	},
	notifyDealer(msg, callback) {
		$.ajax({
			url: `/api/v1/financing/${window.autofi.data._id}/notify`,
			method: 'POST',
			dataType: 'json',
			data: { type: msg },
			success() {
				callback();
			},
			error(x) {
				let err;
				try {
					err = JSON.parse(x.responseText);
				} catch (ex) {
					err = x.responseText;
				}
				callback(err);
			},
		});
	},
	getXCPrefs() {
		return utils.getExpressCheckoutPreferences(window.autofi.data);
	},
	getBestOffer: function getBestOffer(offers) {
		return utils.getBestOffer(window.autofi.data, offers);
	},
	getOffers: function getOffers(filters) {
		return utils.getOffers(window.autofi.data, filters);
	},
	up100: function up100(value) {
		return utils.up100(value);
	},
	n100(n) {
		return Math.floor(n / 100) * 100 || 0;
	},
	amountFinanced(offerItem) {
		let af = parseFloat(window.autofi.data.vehicle.price);
		af -= offerItem.downPayment;
		af -= window.autofi.data.tradeIn.amount;
		af -= window.autofi.data.loanInfo.totalDiscounts;
		af -= offerItem.rebates ? offerItem.rebates.total : 0;
		af += window.autofi.data.loanInfo.totalTaxes;
		af += window.autofi.data.loanInfo.totalOtherFees;
		return af;
	},
	getAppType() {
		return utils.getLoanAppType(window.autofi.data);
	},
	lowerCaseFirstLetter(string) {
		return string.charAt(0).toLowerCase() + string.slice(1);
	},
};

export default common;
