var pug = require("!../../../node_modules/pug/node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (Globalize, data, formatMessage) {













pug_mixins["infoBtn"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"info-button\" data-action=\"afi-popover\"\u003E\u003Csvg class=\"active-svg\" width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\"\u003E\u003Cg fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Crect fill=\"#2693E9\" width=\"16\" height=\"16\" rx=\"4\"\u003E\u003C\u002Frect\u003E\u003Cpath d=\"M9.789 13H6.5v-.65h.962V6.825H6.5v-.65h2.327v6.175h.962V13zM7.176 4.069c0-.251.089-.45.266-.598.178-.147.406-.221.683-.221.295 0 .526.074.695.221.17.147.254.347.254.598s-.084.45-.254.598c-.169.147-.4.221-.695.221-.277 0-.505-.074-.683-.221a.736.736 0 0 1-.266-.598z\" fill=\"#FFF\"\u003E\u003C\u002Fpath\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E\u003C\u002Fdiv\u003E";
};


























































pug_mixins["bestOfferSVG"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Csvg xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" width=\"17\" height=\"18\" viewbox=\"0 0 17 18\"\u003E\u003Cg fill=\"none\" fill-rule=\"nonzero\"\u003E\u003Cpath class=\"color\" fill=\"#2693E9\" d=\"M.356 7.805a1.865 1.865 0 0 1-.269-1.66 1.81 1.81 0 0 1 1.17-1.184 1.827 1.827 0 0 0 1.255-1.767 1.842 1.842 0 0 1 .739-1.499 1.752 1.752 0 0 1 1.622-.249c.184.064.377.097.57.097.58 0 1.124-.287 1.46-.771C7.238.288 7.782 0 8.362 0c.58 0 1.124.288 1.459.772.335.484.88.771 1.459.77.194 0 .387-.032.57-.096a1.752 1.752 0 0 1 1.63.247c.472.35.748.912.74 1.508-.007.804.499 1.517 1.247 1.76a1.81 1.81 0 0 1 1.166 1.182c.18.564.08 1.181-.266 1.657a1.865 1.865 0 0 0 0 2.182c.348.476.448 1.095.27 1.66a1.81 1.81 0 0 1-1.17 1.183c-.753.241-1.264.96-1.255 1.768a1.842 1.842 0 0 1-.738 1.508c-.472.349-1.08.441-1.63.247a1.766 1.766 0 0 0-2.03.676c-.336.484-.88.772-1.46.772s-1.123-.288-1.458-.772a1.766 1.766 0 0 0-2.03-.676 1.752 1.752 0 0 1-1.63-.247 1.842 1.842 0 0 1-.739-1.508 1.827 1.827 0 0 0-1.24-1.768A1.81 1.81 0 0 1 .09 11.644a1.866 1.866 0 0 1 .266-1.657c.47-.648.47-1.534 0-2.182z\"\u003E\u003C\u002Fpath\u003E\u003Cpath fill=\"#FFF\" d=\"M10.208 9.417H6.792c-1.11 0-2.01-.938-2.01-2.088 0-1.15.9-2.088 2.01-2.088h4.76c.135 0 .245.112.245.25v.879c0 .138-.11.25-.246.25H6.792c-.371 0-.676.317-.676.71 0 .39.305.708.676.708h3.416c.136 0 .136 1.379 0 1.379zm0 2.797h-4.76a.248.248 0 0 1-.245-.251v-.878c0-.139.11-.25.246-.25h4.76c.37 0 .675-.318.675-.71 0-.391-.305-.708-.676-.708H6.792c-.136 0-.136-1.38 0-1.38h3.416c1.11 0 2.01.938 2.01 2.089 0 1.15-.9 2.088-2.01 2.088-.136 0 .136 0 0 0zm-2.13-6.032a.248.248 0 0 1-.245-.251V4.069c0-.139.11-.25.245-.25h.844c.136 0 .246.111.246.25V5.93c0 .138-.11.25-.246.25h-.844zm0 7.454a.248.248 0 0 1-.245-.25v-1.862c0-.138.11-.25.245-.25h.844c.136 0 .246.112.246.25v1.862c0 .138-.11.25-.246.25h-.844z\"\u003E\u003C\u002Fpath\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};








pug_mixins["closeModalIcon"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Csvg" + (pug.attrs(pug.merge([{"class": "close-modal-icon","xmlns": "http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg","width": "12","height": "12","viewBox": "0 0 12 12"},attributes]), true)) + "\u003E\u003Cg transform=\"translate(-10 -10)\"\u003E\u003Cpath fill=\"currentColor\" fill-rule=\"nonzero\" d=\"M22 11.209L20.791 10 16 14.791 11.209 10 10 11.209 14.791 16 10 20.791 11.209 22 16 17.209 20.791 22 22 20.791 17.209 16z\"\u003E\u003C\u002Fpath\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};




































pug_html = pug_html + "\u003Cdiv class=\"sp-sticky-header-cover\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-panel-header\"\u003E\u003Cdiv class=\"sp-nav-home\"\u003E\u003Cdiv class=\"sp-applicant\"\u003E";
if (data.applicant.name.full) {
pug_html = pug_html + "\u003Cdiv class=\"sp-applicant-name\" data-applicant=\"name\"\u003E" + (pug.escape(null == (pug_interp = formatMessage('home/users-deal', {fullName: data.applicant.name.full})) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-applicant-secondary\" data-applicant=\"email\"\u003E" + (pug.escape(null == (pug_interp = data.applicant.email) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"sp-applicant-name\" data-applicant=\"name\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-applicant-secondary\" data-applicant=\"email\"\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-nav-home-btns\"\u003E\u003Ca class=\"sp-nav--link afi-v3-close\" id=\"sp-exit-btn\" href=\"javascript:void(0)\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = Globalize.formatMessage('home/save-exit')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
pug_mixins["closeModalIcon"]();
pug_html = pug_html + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-alert-row\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-header-img-container\"\u003E\u003Cdiv class=\"sp-header-img\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-header-shade-light\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-header-shade\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-vehicle-detail-top\"\u003E\u003Cdiv class=\"sp-vehicle-detail sp-vehicle-year\"\u003E" + (pug.escape(null == (pug_interp = data.vehicle.year) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-vehicle-detail sp-vehicle-make-model\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = data.vehicle.make) ? "" : pug_interp)) + " " + (pug.escape(null == (pug_interp = data.vehicle.model) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-vehicle-detail-row\"\u003E\u003Cdiv class=\"sp-vehicle-detail-bottom\"\u003E\u003Cdiv class=\"sp-vehicle-detail sp-vehicle-year\"\u003E" + (pug.escape(null == (pug_interp = data.vehicle.year) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-vehicle-detail sp-vehicle-make-model\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = data.vehicle.make) ? "" : pug_interp)) + " " + (pug.escape(null == (pug_interp = data.vehicle.model) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-unlocked\"\u003E\u003Cdiv class=\"sp-unlocked--container\"\u003E\u003Cdiv class=\"sp-unlocked--close\"\u003E";
pug_mixins["closeModalIcon"]();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-unlocked--price\"\u003E \u003Cspan class=\"sp-unlocked--price-prefix\" data-unlocked=\"price-prefix\"\u003E\u003C\u002Fspan\u003E\u003Cspan data-unlocked=\"price\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-unlocked--subtext\"\u003E" + (pug.escape(null == (pug_interp = formatMessage('home/congrats-unlocked')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-unlocked--text\"\u003E\u003Cspan data-unlocked=\"discounts\"\u003E\u003C\u002Fspan\u003E in savings!\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-unlocked--arrow\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-vehicle-price-container\"\u003E\u003Cdiv class=\"sp-vehicle-price-taxes\"\u003E\u003Cdiv class=\"sp-home--pricing--taxes\"\u003E\u003Cspan class=\"afi-u-hidden\" data-pricing=\"taxes-included\"\u003E" + (pug.escape(null == (pug_interp = formatMessage('common/taxes-fees')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Ca class=\"sp-home-price-box\" id=\"sp-home-price-box\" href=\"#\" data-modal=\"pricing-details\" style=\"text-decoration: none\"\u003E\u003Cdiv data-pricing=\"total\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-price-monthly-container\"\u003E\u003Cdiv class=\"sp-price-divider\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Cspan data-payment=\"any\"\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"sp-home--per-month\"\u003Eper month\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"sp-vehicle-secondary\"\u003E";
if (data.backendPath && data.backendPath !== 'PROD') {
pug_html = pug_html + "\u003Cdiv class=\"sp-application-backend\" data-application-backend\u003E" + (pug.escape(null == (pug_interp = data.backendPath) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"sp-vehicle-information\"\u003E";
if (data.vehicle.stockNumber) {
pug_html = pug_html + ("Stock #: " + (pug.escape(null == (pug_interp = data.vehicle.stockNumber + ' • ') ? "" : pug_interp)));
}
pug_html = pug_html + "VIN: " + (pug.escape(null == (pug_interp = data.vehicle.vin) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"Globalize" in locals_for_with?locals_for_with.Globalize:typeof Globalize!=="undefined"?Globalize:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"formatMessage" in locals_for_with?locals_for_with.formatMessage:typeof formatMessage!=="undefined"?formatMessage:undefined));;return pug_html;};
module.exports = template;