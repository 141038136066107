import { rollbarEvents } from './rollbarEvents';

enum EVENTS_ENUMS {
	rollbar = 'rollbar',
}

type EventsType = typeof EVENTS_ENUMS;

const getEventType = (type?: EventsType) => {
	if (type && type.rollbar) {
		return rollbarEvents;
	}

	// use rollbar as default type
	return rollbarEvents;
};

const logEvent = getEventType();

export const logDebugEvent = (message: string, metadata: Record<string, unknown>) => logEvent?.debug(message, metadata);
export const logInfoEvent = (message: string, metadata: Record<string, unknown>) => logEvent?.info(message, metadata);
export const logWarnEvent = (message: string, metadata: Record<string, unknown>) => logEvent?.warn(message, metadata);
export const logWarningEvent = (message: string, metadata: Record<string, unknown>) =>
	logEvent?.warning(message, metadata);
export const logErrorEvent = (message: Error | string, metadata: Record<string, unknown>) =>
	logEvent?.error(message, metadata);
export const logCriticalEvent = (message: string, metadata: Record<string, unknown>) =>
	logEvent?.critical(message, metadata);
