import type {
	AccountUserExperience,
	AccountUserExperienceDealmaker,
	AccountUserExperienceDealmakerCtaSection,
	AccountUserExperienceDealmakerTradeIn,
	AccountUserExperienceGlobal,
} from '@client/graphql/generated';

const getCtaSection = (
	ctaSection: AccountUserExperienceDealmakerCtaSection
): AccountUserExperienceDealmakerCtaSection => {
	const { showLockInPayment = true, showSpeakToSpecialist = true } = ctaSection ?? {};
	return {
		showLockInPayment,
		showSpeakToSpecialist,
	};
};

const getTradeIn = (tradeIn: AccountUserExperienceDealmakerTradeIn): AccountUserExperienceDealmakerTradeIn => {
	const { showLeaseTurnIn = true, showIco = true } = tradeIn ?? {};
	return {
		showLeaseTurnIn,
		showIco,
	};
};

export const getDealmaker = (userExperience: AccountUserExperience): AccountUserExperienceDealmaker => {
	const {
		ctaSection,
		showCashTab = true,
		showFooter = true,
		showSaveNextCta = false,
		showStartApplicationCta = true,
		tradeIn,
	} = userExperience?.dealmaker ?? {};

	return {
		ctaSection: getCtaSection(ctaSection),
		showCashTab,
		showFooter,
		showSaveNextCta,
		showStartApplicationCta,
		tradeIn: getTradeIn(tradeIn),
	};
};

export const getGlobal = (userExperience: AccountUserExperience): AccountUserExperienceGlobal => {
	const { showAutoFiLogo = true, showNav = true } = userExperience?.global ?? {};

	return {
		showAutoFiLogo,
		showNav,
	};
};
