import get from 'lodash/get';

import { DEFAULT_OFFER_RANGES } from '@client/lib/constants';

const getRange = (financeType: any, ranges: any, rangeAttr: any, country: any) =>
	get(ranges, `${financeType}.${rangeAttr}${country ? `.${country}` : ''}`, []);

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'offerRanges' implicitly has an 'any' ty
const getDealerRange = (offerRanges, financeType, rangeAttr) =>
	// @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
	getRange(financeType, offerRanges, rangeAttr);

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'financeType' implicitly has an 'any' ty
const getDefaultRange = (financeType, rangeAttr, country) =>
	getRange(financeType, DEFAULT_OFFER_RANGES, rangeAttr, country);

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rangeAttr' implicitly has an 'any' type
const getOfferRangeAttr = (rangeAttr) => {
	switch (rangeAttr) {
		case 'annualMiles':
			return 'mileageOptions';
		case 'termMonths':
			return 'termOptions';
	}
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rangeAttr' implicitly has an 'any' type
const getOfferRange = (rangeAttr, offerRanges, financeType, offer = {}, country) => {
	const offerRangeAttr = getOfferRangeAttr(rangeAttr);
	const offerRange = get(offer, offerRangeAttr, []);
	const defaultRange = getDefaultRange(financeType, rangeAttr, country);
	const dealerRange = getDealerRange(offerRanges, financeType, rangeAttr);

	if (offerRange.length) {
		const intersection = dealerRange.length ? offerRange.filter((opt) => dealerRange.includes(opt)) : offerRange;
		return intersection.length ? intersection : offerRange;
	}

	return dealerRange.length ? dealerRange : defaultRange;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'offerRanges' implicitly has an 'any' ty
export const getTermOptions = (offerRanges, financeType, offer = {}) =>
	// @ts-expect-error ts-migrate(2554) FIXME: Expected 5 arguments, but got 4.
	getOfferRange('termMonths', offerRanges, financeType, offer);

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'offerRanges' implicitly has an 'any' ty
export const getMileageOptions = (offerRanges, financeType, country = 'US', offer = {}) =>
	getOfferRange('annualMiles', offerRanges, financeType, offer, country);
