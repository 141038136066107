const get = require('lodash/get');
const merge = require('lodash/merge');

const { calculator } = require('@autofidev/finance');

const { calculateProductsAmount } = require('./products');
const { filterDiscountsByOfferType } = require('./discounts');
const { n100, setIfPresent } = require('./format');
const removeEmpty = require('../utils/removeEmpty');
const getFicoRange = require('../utils/getFicoRange');

const createEstimatorPayload = (loanApp, offerType = loanApp.requestedOfferType, offerData, opts) => {
	offerType = offerType.toUpperCase();

	const { vehicle, expressCheckout, backendPath } = loanApp;
	const address = get(loanApp, 'applicant.address', {});
	const name = get(loanApp, 'applicant.name', {});
	const preferredLanguage = get(loanApp, 'preferredLanguage');
	const rebatesOffered = get(loanApp, 'rebatesOffered', []);
	const qualifiedOffers = rebatesOffered.filter((r) => r.isQualifiedOffer);
	const selectedDirectOffers = qualifiedOffers.filter((r) => r.isSelected);
	const addOfferRebates = selectedDirectOffers.map((r) => r.programId);
	const excludeOfferRebates = qualifiedOffers.filter((r) => !r.isSelected).map((r) => r.programId);
	const filteredDiscountsByOfferType = filterDiscountsByOfferType(get(loanApp, 'discounts', []), offerType);
	const totalDiscounts = filteredDiscountsByOfferType
		.filter((item) => item.include)
		.reduce((acc, item) => acc + item.amount, 0);

	if (!offerData) {
		const preferences = get(expressCheckout, 'preferences', {});

		offerData = preferences[offerType.toLowerCase()];
		if (offerData.annualMileage) {
			// there is an annoying inconsistency with naming here...
			offerData.annualMiles = offerData.annualMileage;
		}
	}

	const { creditBand, termMonths, annualMiles, paymentInterval } = offerData;
	const downPayment = n100(offerData.downPayment);
	let incentiveOption = offerData.incentiveOption || get(loanApp, 'applicant.preferences.incentiveOption');

	const offer = {
		termMonths,
		downPayment,
		totalDiscounts,
	};

	if (offerType === 'LEASE') {
		offer.annualMiles = annualMiles;
		// always use lowest apr incentive option for lease
		// the selection for incentive option is hidden in the UI for lease
		// but we always want to show the lowest apr payment
		// even if they selected largest rebate for finance
		// https://www.pivotaltracker.com/story/show/175619038
		incentiveOption = 'LOWEST_APR';
	}

	const dealer = get(loanApp, 'dealer._id', loanApp.dealer);
	const getRebates = true;
	const dealerCash = getDealerCash(loanApp);
	const pricePlan = get(loanApp, 'loanInfo.pricePlan.plan');
	const isTaxExempt = get(loanApp, 'applicant.isTaxExempt', false);
	const productsAmount = calculateProductsAmount(loanApp.productsOffered || [], offerType);

	const info = {
		address,
		addOfferRebates,
		dealer,
		dealerCash,
		excludeOfferRebates,
		getRebates,
		isTaxExempt,
		name,
		offer,
		paymentInterval,
		preferredLanguage,
		pricePlan,
		productsAmount,
		rebatesOffered,
		selectedDirectOffers,
		vehicle,
	};

	const tradeIn = getTradeInData(get(loanApp, 'tradeIn', {}), opts);
	const tradeInAmount = get(tradeIn, 'amount');

	if (typeof tradeInAmount !== 'undefined') {
		info.tradeIn = tradeIn;
		info.tradeInAmount = tradeIn;
	}

	return {
		referrer: loanApp.referrer,
		backendPath,
		creditBand: creditBand || expressCheckout.preferences.creditBand,
		info,
		incentiveOption,
		offerType,
	};
};

function getTradeInData(tradeIn, opts = {}) {
	let tradeInData;
	let amount = parseInt(get(tradeIn, 'amount', 0), 10);
	const payoff = parseInt(get(tradeIn, 'payoff', 0), 10);
	const bookValue = parseInt(get(tradeIn, 'bookValue', 0), 10) || amount;
	const complete = get(tradeIn, 'complete', false);
	const isApplied = get(tradeIn, 'isApplied', false);

	amount = amount || bookValue - payoff;

	if (opts.includeIncompleteTradeIn || complete) {
		tradeInData = { bookValue, amount, payoff, complete, isApplied };
	}

	return tradeInData;
}

function getDealerCash(loanApp) {
	if (get(loanApp, 'settings.useDealerCash') && loanApp.preOfferRebates.length) {
		const dealerCash = loanApp.preOfferRebates.reduce((acc, item) => {
			return acc + get(item, 'amount', 0);
		}, 0);

		return dealerCash;
	}
}

function getUpdatedTaxesAndFees(constructedLoanApp, loanApp, offer) {
	const { requestedOfferType, taxesAndFees } = constructedLoanApp;

	return calculator.getUpdatedTaxesAndFees(
		{
			...loanApp,
			requestedOfferType,
			taxesAndFees,
		},
		offer
	);
}

/**
 * create the payload to save estimate response data on a loanApp
 * @param {Object} loanApp object
 * @param {Object} estimateResponse from an estimator request
 * @param {Object} [opts]
 * @param {String} [opts.incentiveOption]
 * @param {String} [opts.creditBand]
 *
 * @returns {Object} payload to persist to estimate, e.g. what is passed to `steps.estimate`
 */

function createSaveEstimatePayload(loanApp, estimateResponse, opts = {}) {
	const offerType = estimateResponse.offerType.toUpperCase();
	const offerTypeLower = offerType.toLowerCase();
	const { constructedLoanApp, data, offer } = estimateResponse;
	const {
		applicant,
		dealerAssociate,
		discounts,
		expressCheckout,
		isInStore,
		loanInfo: { pricePlan },
		productsOffered,
		requestedPaymentInterval,
		tradeIn,
	} = loanApp;
	const { rebatesOffered, bookoutData } = constructedLoanApp;
	const { totalCash, annualMiles } = offer;
	const incentiveOption = offerType === 'LEASE' ? 'LOWEST_APR' : opts.incentiveOption;
	const creditBand = opts.creditBand || constructedLoanApp.selectedCreditBand;
	const totalRebates = get(offer, 'rebates.total', 0);

	const loanInfo = {
		...loanApp.loanInfo,
		...constructedLoanApp.loanInfo,
		...data,
		totalRebates,
		pricePlan,
		selectedOfferType: offerType,
	};
	const taxesAndFees = getUpdatedTaxesAndFees(constructedLoanApp, loanApp, offer);
	const payload = {
		applicant,
		dealerAssociate,
		expressCheckout,
		requestedPaymentInterval,
		offerType,
		productsOffered,
		rebatesOffered,
		isInStore,
		loanInfo,
		taxesAndFees,
		discounts,
		tradeIn,
		bookoutData,
	};

	setIfPresent(payload, 'applicant.address.zip', applicant.address.zip);
	setIfPresent(payload, 'applicant.preferences.incentiveOption', incentiveOption);

	payload.expressCheckout.preferences = {
		...expressCheckout.preferences,
		tradeIn,
		creditBand,
		dealerOverrides: expressCheckout.preferences.dealerOverrides,
	};

	payload.expressCheckout.preferences[offerTypeLower] = {
		...offer,
		estMonthlyPayment: data.monthlyPayment,
		estBiweeklyPayment: data.biweeklyPayment,
		zipcode: applicant.address.zip,
	};

	if (offerTypeLower === 'lease') {
		loanInfo.leasing = data;
		payload.expressCheckout.preferences.lease.downPayment = totalCash;
		payload.expressCheckout.preferences.lease.annualMileage = annualMiles;
	}

	return payload;
}

/**
 * Return ture if bookout data contains an object with matching source
 * @param {Object[]} bookoutData array of bookout out objects
 * @param {String} source source to search for
 */
function hasBookoutDataSource(bookoutData, source) {
	return !!bookoutData.find((b) => b.source === source);
}

/**
 * set estimate data on a loanApp's loanInfo, expressCheckout etc
 * @param {Object} loanApp object
 * @param {Object} data for estimate, e.g. `data` passed into `steps.estimate`
 *
 * @returns {Object} modified loanApp object
 */
function setEstimateOnLoanApp(loanApp, data) {
	const {
		bookoutData = [],
		discounts,
		estimate,
		expressCheckout,
		loanInfo,
		offerType,
		rebatesOffered = [],
		taxesAndFees,
		tradeIn: tradeInData,
	} = data;

	const {
		finance,
		lease,
		tradeIn: xcTradeIn,
		lockedPricing,
		creditBand = 'EXCELLENT',
	} = get(expressCheckout, 'preferences', {});

	const totalRebates = get(estimate, 'loanApp.loanInfo.totalRebates');
	if (loanInfo && loanInfo.leasing) {
		merge(loanApp.loanInfo, removeEmpty(loanInfo));
	}

	const ficoRange = getFicoRange(loanApp, creditBand);
	const tradeIn = merge(loanApp.tradeIn, tradeInData);

	setIfPresent(loanApp, 'expressCheckout.preferences.creditBand', creditBand);
	setIfPresent(loanApp, 'expressCheckout.preferences.ficoRange', ficoRange);
	setIfPresent(loanApp, 'expressCheckout.preferences.finance', finance);
	setIfPresent(loanApp, 'expressCheckout.preferences.lease', lease);
	setIfPresent(loanApp, 'expressCheckout.lockedPricing', lockedPricing);
	setIfPresent(loanApp, 'expressCheckout.preferences.tradeIn', xcTradeIn);
	setIfPresent(loanApp, 'tradeIn', tradeIn);
	setIfPresent(loanApp, 'taxesAndFees', taxesAndFees);
	setIfPresent(loanApp, 'rebatesOffered', rebatesOffered);
	setIfPresent(loanApp, 'discounts', discounts);
	setIfPresent(loanApp, 'loanInfo.totalRebates', totalRebates);
	// setIfPresent(loanApp, 'loanInfo.amountFinanced', loanInfo.amountFinanced);
	// setIfPresent(loanApp, 'loanInfo.totalDiscounts', loanInfo.totalDiscounts);
	setIfPresent(loanApp, 'requestedOfferType', offerType);
	bookoutData.forEach((b) => {
		if (!hasBookoutDataSource(loanApp.bookoutData, b.source)) {
			loanApp.bookoutData.push(b);
		}
	});

	// Persist whatever is modified.
	if (typeof loanApp.markModified === 'function') {
		loanApp.markModified('expressCheckout');
	}

	return loanApp;
}

module.exports = {
	createEstimatorPayload,
	createSaveEstimatePayload,
	setEstimateOnLoanApp,
	hasBookoutDataSource,
};
