import type {
	LoanApplication,
	Lender,
	Dealer,
	DecisionMatrix,
	LoanApplicationTradeIn,
} from '@autofidev/models/generated';

import type { AutoFiError } from '@client/graphql/generated';
import type themes from '@client/themes/index';
import type { RequestedPaymentInterval } from './lib/types/LoanApplication';

export type Address = {
	city?: string;
	state?: string;
	street?: string;
	street2?: string;
	zip?: string;
};

export type Applicant = {
	name: {
		first: string;
		full: string;
		last: string;
	};
	phone?: string;
};

export enum OfferType {
	Cash = 'cash',
	Finance = 'finance',
	Lease = 'lease',
}

export type SelectedProduct = {
	_id: string;
	category: string;
	finePrintTextI18n: { [locale: string]: string };
	fullDescriptionI18n: { [locale: string]: string };
	mainBannerImage: string;
	mainBannerVideo: string;
	marketingUrl: string;
	menuTileImage: string;
	nameI18n: { [locale: string]: string };
	noProductSelected: boolean;
	offerType: typeof OfferType;
	origin: string;
	preSelected: boolean;
	productId: string;
	qa: Array<any>;
	suggestedRetailPrice: number;
	suggestedRetailPricePretax: number;
	termMiles: number;
	termMonths: number;
	userSelected: boolean;
	vendor: string;
};

// Use brands to fake types for some objects until we have types for them
export interface Breakdown {
	_isBreakdown: true;
	numberOfPayments: number;
	onlinePrice: number;
	rebateItems: any[];
	selectedProducts?: SelectedProduct[];
	tax: Tax;
	tradeIn: any;
	vehiclePriceAfterDiscounts: number;
	zip: string;
}

export enum DiscountCode {
	CustomDiscount = 'DLR_CUSTOM_DISCOUNT',
	CustomMarkup = 'DLR_CUSTOM_MARKUP',
	DealerDiscount = 'DLR',
	FEP = 'FEP',
	PlanA = 'PLAN:A',
	PlanD = 'PLAN:D',
	PlanX = 'PLAN:X',
	PlanZ = 'PLAN:Z',
}

export const editableDiscountCodes = Object.values(DiscountCode);

export interface ExpressCheckoutPreferences {
	cash: OfferTypePreferences;
	creditBand: string;
	finance: OfferTypePreferences;
	lease: LeaseOfferPreferences;
	tradeIn?: LoanApplicationTradeIn;
}

export interface ExpressCheckout {
	preferences: ExpressCheckoutPreferences;
}

export interface FicoBand {
	label: string;
	labelText: string;
	maxFico: number;
	minFico: number;
}

export enum IncentiveOption {
	LargestRebates = 'LARGEST_REBATE',
	LowestAPR = 'LOWEST_APR',
	LowestMonthly = 'LOWEST_MONTHLY',
}

export interface LeaseOfferPreferences extends OfferTypePreferences {
	annualMiles: number;
}

export interface LoanApp
	extends Omit<LoanApplication, 'lender' | 'dealer' | 'pricing' | 'tradeIn' | 'expressCheckout' | 'cosigner'> {
	_id: string;
	cosigner?: LoanApplication['applicant'] & {
		use: boolean;
	};
	currentStepName: string;
	dealer?: Dealer;
	expressCheckout: ExpressCheckout;
	hasPrevStep: boolean;
	isLease?: boolean;
	leasingInfo?: {
		acquisitionFee: number;
		acquisitionFeeName: string;
	};
	lender?: Lender;
	pricePlanDiscounts?: any[];
	pricing?: { matrix: DecisionMatrix[] };
	requestedOfferType?: 'FINANCE' | 'LEASE' | 'CASH';
	settings?: {
		[key: string]: any;
		enabledPIIFields: string[];
	};
	tradeIn: LoanApplicationTradeIn;
}

export interface Offer {
	amountFinanced: number;
	annualMiles?: number;
	apr: number;
	downPayment: number;
	isSubvented: boolean;
	monthlyPayment: number;
	// @TODO: type this
	offer: any;
	termMonths: number;
	totalCash: number;
	totalInterest: number;
	totalOfPayments: number;
}

export interface OfferTypePreferences {
	apr: number;
	downPayment: number;
	isSubvented: boolean;
	termMonths: number;
}

// WARNING: we can't simply use Object.values here because the type order has its importance
export const offerTypes = [OfferType.Finance, OfferType.Lease, OfferType.Cash];

export interface Payment {
	amount: number;
	apr: number;
	effectiveRate: number;
	interval: string;
	paymentLoaded: boolean;
	termMonths: number;
	vehiclePrice: number;
}

export enum PersonType {
	Customer,
	DealershipContact,
}

export type Rebate = {
	amount: number;
	expiryDate: string;
	isQualifiedOffer: boolean;
	isSelected: boolean;
	metadata: {
		isPrivateOffer: boolean;
	};
	name: string;
	programId: number | string;
	rules: any[];
};

export enum ScheduleType {
	Appointment,
	DeliveryOrPickup,
}

export interface SmartOffer {
	_isSmartOffer: true;
	amountFinanced: number;
	annualMiles?: number;
	apr: number;
	downPayment: number;
	effectiveRate: number;
	estimate: SmartOfferEstimate;
	isSubvented: boolean;
	monthlyPayment: number;
	// @TODO: type this
	offer: object;
	paymentAmount: number;
	rebates: {
		total: number;
	};
	termMonths: number;
	totalCash: number;
	totalInterest: number;
	totalOfPayments: number;
	unlockedPrice?: number;
	vehiclePrice: number;
}

export interface SmartOfferEstimate {
	dueAtSigning: number;
}

export interface Subpanel {
	name: string;
}

export type Tax = {
	taxesAndFees?: any[];
	taxesAndFeesList?: any[];
};

export type TaxFee = {
	amount: number;
	code: string;
	description: string;
};

export interface TermsOptions {
	annualMiles: number;
	apr: number;
	creditBand: string;
	// @SEE offer-range-helpers.ts#getOfferRange
	disabledTermOptions: number[];
	downPayment: number;
	ficoBands: FicoBand[];
	incentiveOption: IncentiveOption;
	isLeaseBalancingEnabled: boolean;
	isLocked: boolean;
	loading: boolean;
	maxCashDown: number;
	mileageOptions: number[];
	minCashDown: number;
	offerType: OfferType;
	requestedPaymentInterval: RequestedPaymentInterval;
	showCreditBand: boolean;
	showEstimatedAPR: boolean;
	showIncentivesSelection: boolean;
	showVehiclePaymentSelection: boolean;
	termMonths: number;
	// @SEE offer-range-helpers.ts#getOfferRange
	termOptions: number[];
	theme: Theme;
	vehiclePaymentIntervals: string[];
	zip: string;
}

export interface Theme {
	'body-color': string;
	'body-font': string;
	'body-size': string;
	'brand-accent': string;
	'brand-accent-extra-faded': string;
	'brand-accent-faded': string;
	'brand-accent-light': string;
	'brand-action': string;
	'brand-action-dark': string;
	'font-family-brand': string;
	'font-family-neutral': string;
	'footnote-color': string;
	'footnote-font': string;
	'footnote-size': string;
	'header-one-color': string;
	'header-one-font': string;
	'header-one-size': string;
	'header-two-color': string;
	'header-two-font': string;
	'header-two-size': string;
	'link-color': string;
	'link-font': string;
	'nav-brand-color': string;
	'subheader-color': string;
	'subheader-font': string;
	'subheader-size': string;
}

export type ThemeName = keyof typeof themes;

export enum UserRole {
	Admin = 'admin',
	DealerAdmin = 'dealeradmin',
	SalesRep = 'salesrep',
	Su = 'su',
}

export enum VehicleAge {
	Cpo = 'cpo',
	New = 'new',
	Used = 'used',
}

export type Phone = { number: string; type: string };

export const isAutoFiError = (arg: any): arg is AutoFiError => arg.__typename === 'AutoFiError';

export type DepositPaymentStatus = boolean;
