import React from 'react';
import PropTypes from 'prop-types';

const Edit = ({ className, color = '#1579BA' }) => (
	<svg className={className} height="17" viewBox="0 0 23 17" width="23" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fillRule="evenodd">
			<path d="M-5-8h32v32H-5z" />
			<g fill={color}>
				<path d="M2.3 14H23v3H0z" fillOpacity=".1" />
				<path d="M16.696 2.732L14.27.306a1.036 1.036 0 0 0-1.47 0l-2.172 2.171a.09.09 0 0 0-.023.024h-.002l-8.481 8.484a1.07 1.07 0 0 0-.221.324L.083 15.553c-.166.39-.078.841.221 1.143a1.037 1.037 0 0 0 1.145.222l4.241-1.816c.122-.053.232-.127.325-.221L16.695 4.2a1.038 1.038 0 0 0 0-1.468zM4.687 13.272l-1.672.714.717-1.673 7.424-7.421.955.955-7.424 7.424zm8.526-8.527l-.955-.959 1.277-1.277.956.955-1.278 1.281z" />
			</g>
		</g>
	</svg>
);

Edit.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
};

export default Edit;
