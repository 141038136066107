import get from 'lodash/get';

// TODO: add types for each of these
export const getAutoFi = (): unknown => (typeof window === 'undefined' ? {} : get(window, 'autofi', {}));

export const getFlow = (): unknown => get(getAutoFi(), 'flow', {});

export const getModals = (): unknown => get(getAutoFi(), 'modals', {});

export const getNav = (): unknown => get(getAutoFi(), 'nav', {});

export const getPanels = (): unknown => get(getAutoFi(), 'panels', {});

export const getIsAgent = (): unknown => get(getAutoFi(), 'isAgent', false);
