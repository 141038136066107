import get from 'lodash/get';
import sumBy from 'lodash/sumBy';

import { calculateTotalTaxesAndFees } from '@client/lib/helpers/taxHelpers';
import { setRebateDisclaimers, getDiscountsWithDealerCash } from '@client/lib/breakdown';
import { filterDiscountsByOfferType } from '@root/lib/helpers/discounts';
import type { Accessory, Estimate } from '@client/graphql/generated';

// abstract the data returned from estimator calls for easier access
export default class Estimator {
	type: string;

	loanAppModel: any;

	estimate: Estimate;

	offer: any;

	constructedLoanApp: any;

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
	constructor(data) {
		const {
			data: estimate = {},
			offer = {},
			constructedLoanApp = {},
			offerType: type,
			loanAppModel, // TODO: see comment below (decide if we want to lean on model or constructed)
		} = data;

		this.loanAppModel = loanAppModel;
		this.estimate = estimate;
		this.offer = offer;
		this.constructedLoanApp = constructedLoanApp;
		this.type = type.toLowerCase();
	}

	/* GETTERS */
	get apr() {
		return get(this.offer, 'apr');
	}

	get accessoriesTotal() {
		return this.vehicle.accessoriesAdded?.reduce(
			(total: number, accessory: Accessory) => total + accessory.totalPrice,
			0
		);
	}

	get effectiveRate() {
		return get(this.estimate, ['effectiveRate', this.paymentInterval.toLowerCase()]);
	}

	// an alias for this.dealerRetailPrice, to clarify that they are synonymous
	get msrp() {
		return this.dealerRetailPrice;
	}

	get dealerRetailPrice() {
		return get(this.vehicle, 'dealerRetailPrice', 0);
	}

	get biweeklySalesTax() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'estimate' does not exist on type 'Estima
		return this.estimate.biweeklySalesTax;
	}

	get baseBiweeklyPayment() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'estimate' does not exist on type 'Estima
		return this.estimate.baseBiweeklyPayment;
	}

	get biweeklyPayment() {
		return get(this.estimate, 'biweeklyPayment', 0);
	}

	get monthlySalesTax() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'estimate' does not exist on type 'Estima
		return this.estimate.monthlySalesTax;
	}

	get baseMonthlyPayment() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'estimate' does not exist on type 'Estima
		return this.estimate.baseMonthlyPayment;
	}

	get monthlyPayment() {
		return get(this.estimate, 'monthlyPayment', 0);
	}

	get estimatedPayment() {
		switch (this.paymentInterval) {
			case 'BIWEEKLY':
				return this.biweeklyPayment;
			case 'MONTHLY':
			default:
				return this.monthlyPayment;
		}
	}

	get paymentAmount() {
		return this.type === 'cash' ? this.cashPrice : this.estimatedPayment;
	}

	get paymentInterval() {
		return this.loanAppModel.requestedPaymentInterval;
	}

	//
	// TODO:  we could instead use the data from `constructedLoanApp`
	// need to decide which set of data to use as a base
	// loanappModel comes from venom, so trust!! :)
	// otoh the estimate is based on the data in constructedLoanApp.....
	get vehicle() {
		return this.loanAppModel.vehicle;
	}

	get vehiclePrice() {
		return this.dealerRetailPrice - this.totalDiscounts;
	}

	get inclusiveRebates() {
		return get(this.offer, 'rebates', {
			items: [],
			total: 0,
		});
	}

	get rebates() {
		const rebates = this.inclusiveRebates;
		// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rebate' implicitly has an 'any' type.
		const items = (rebates.items || []).filter((rebate) => !rebate.isDiscount);
		const total = sumBy(items, 'amount') || 0;

		return { items, total };
	}

	get rebateItems() {
		return this.rebates.items.map(setRebateDisclaimers);
	}

	get rebatesOffered() {
		// sometimes we dont have rebates on the loanAppModel yet, but they
		// were returned by the estimator in the `constructedLoanApp`
		// we want to grab rebates from estimate to make sure they are the latest
		// but do need to ensure that we mark them as selected based on loanAppModel
		const rebatesOffered = get(this.constructedLoanApp, 'rebatesOffered', []);
		const selectedQualifiedRebateIds = this.loanAppModel.rebatesOffered
			// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rebate' implicitly has an 'any' type.
			.filter((rebate) => rebate.isQualifiedOffer && rebate.isSelected)
			// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rebate' implicitly has an 'any' type.
			.map((rebate) => rebate.programId?.toString());

		// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rebate' implicitly has an 'any' type.
		return rebatesOffered.map((rebate) => {
			rebate.isSelected = selectedQualifiedRebateIds.includes(rebate.programId?.toString());
			return rebate;
		});
	}

	get discountRebates() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'offer' does not exist on type 'Estimator
		return get(this.offer, 'rebates.items', []).filter((rebate) => rebate.isDiscount);
	}

	get includedDiscounts() {
		// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'discount' implicitly has an 'any' type.
		const includedDiscounts = this.discounts.filter(({ include }) => include);
		return filterDiscountsByOfferType(includedDiscounts, this.type);
	}

	get discounts() {
		return getDiscountsWithDealerCash(this.loanAppModel.discounts, this.totalDiscountRebates);
	}

	get totalDiscountRebates() {
		return sumBy(this.discountRebates, 'amount') || 0;
	}

	get rebatesTotal() {
		return this.rebates.total;
	}

	get taxesAndFees() {
		return get(this.constructedLoanApp, 'taxesAndFees', []);
	}

	get totalDiscounts() {
		return sumBy(this.includedDiscounts, 'amount') || 0;
	}

	get loanInfo() {
		return get(this.constructedLoanApp, 'loanInfo', {});
	}

	get totalTaxes() {
		return calculateTotalTaxesAndFees(
			{
				...this.constructedLoanApp,
				dealer: this.loanAppModel.dealer._data,
			},
			this.offer
		);
	}

	// TODO those below

	get downPayment() {
		if (this.type === 'lease') {
			return this.offer.totalCash;
		}

		return this.offer.downPayment;
	}

	get totalUpFrontTax() {
		return get(this.loanInfo, 'totalUpFrontTax', 0);
	}

	get totalCapitalizedFees() {
		return get(this.loanInfo, 'totalCapitalizedFees', 0);
	}

	get rebateAppliedToCap() {
		return get(this.loanInfo, 'rebateAppliedToCap', 0);
	}

	get rebateAppliedToSigning() {
		return get(this.loanInfo, 'rebateAppliedToSigning', 0);
	}

	get lockedPrice() {
		return this.dealerRetailPrice;
	}

	get unlockedDiscounts() {
		const { totalDiscounts, rebateAppliedToCap, rebateAppliedToSigning, type } = this;
		let { rebatesTotal } = this;

		if (type === 'lease') {
			rebatesTotal = rebateAppliedToCap + rebateAppliedToSigning;
		}

		return totalDiscounts + rebatesTotal;
	}

	get unlockedPrice() {
		return this.lockedPrice - this.unlockedDiscounts;
	}

	get cashPrice() {
		const vehiclePrice = this.dealerRetailPrice - this.rebatesTotal - this.totalDiscounts;

		const tradeInAmount = get(this.constructedLoanApp, 'tradeIn.amount', 0);
		const val = vehiclePrice - tradeInAmount + this.totalTaxes + this.accessoriesTotal;

		return val;
	}
	/* SETTERS */

	/* METHODS */
}
