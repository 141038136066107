import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

const defaultStyle = {
	transition: `all .5s ease-in-out`,
	opacity: 0,
	transform: 'scale(1) translateY(0%)',
};

const transitionStyles = {
	entering: {
		opacity: 0,
		transform: 'scale(0.5) translateY(-75%)',
	},
	entered: {
		opacity: 1,
		transform: 'scale(1) translateY(0%)',
	},
};

const Swoop = ({ in: inProp, duration, children }) => (
	<Transition in={inProp} timeout={duration}>
		{(state) => (
			<div
				style={{
					...defaultStyle,
					...transitionStyles[state],
				}}
			>
				{children}
			</div>
		)}
	</Transition>
);

Swoop.defaultProps = {
	duration: 500,
};

Swoop.propTypes = {
	in: PropTypes.bool,
	children: PropTypes.any,
	duration: PropTypes.number,
};

export default Swoop;
