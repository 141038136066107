import { useEffect } from 'react';

import type { Dealer } from '@autofidev/models/generated';

export type HookParams = {
	afterEffect?: () => void;
	dealer: Dealer;
	shouldEmbed?: boolean;
};

export const useGoogleTagScript = ({ afterEffect, dealer, shouldEmbed = true }: HookParams) => {
	useEffect(() => {
		if (!shouldEmbed) {
			return;
		}

		let measurementIds: string[] = [];

		try {
			const googleAnalyticsMeasurementIds = dealer.settings.analytics?.googleAnalyticsMeasurementIds || [];
			measurementIds = googleAnalyticsMeasurementIds.filter((id) => !id.toLowerCase().startsWith('ua'));
		} catch (e) {
			/* do nothing */
		}

		// no ids found for GA4 events, we are in Store, or gtag has not been set up, move on
		if (!measurementIds.length) {
			return;
		}

		const gtmScript = document.createElement('script');
		// only the first ID should be pushed to the GTM script
		// the rest, if there are more then 1 are handled in the dataLayer script below
		gtmScript.src = `https://www.googletagmanager.com/gtag/js?${new URLSearchParams({ id: measurementIds[0] })}`;

		const dataLayerScript = document.createElement('script');

		dataLayerScript.innerHTML = `
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			${measurementIds.map((id) => `gtag('config', '${id}');`).join('\n\t\t\t')}
		`;

		document.head.prepend(gtmScript, dataLayerScript);

		if (afterEffect) {
			afterEffect();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
