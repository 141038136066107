import React from 'react';
import PropTypes from 'prop-types';

const CircleCheck = ({ height, width, stroke, fill }) => (
	<svg height={height || '31'} viewBox="0 0 31 31" width={width || '31'} xmlns="http://www.w3.org/2000/svg">
		<g
			fill={fill || 'none'}
			fillRule="evenodd"
			stroke={stroke || '#3FB94D'}
			strokeWidth="3"
			transform="translate(2 1.85)"
		>
			<circle cx="13.5" cy="13.5" r="13.5" />
			<path d="M7.714 14.424L11.282 18l8.004-9" strokeLinecap="square" />
		</g>
	</svg>
);

CircleCheck.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	stroke: PropTypes.string,
	fill: PropTypes.string,
};

export default CircleCheck;
