import request from 'axios';
import 'url-polyfill';

export const getCurrentURL = () => window.location.href;

// For a given endpoint, fully construct the desired result URL
export const formatURL = (endpoint: any, opts = {}) => {
	// @ts-expect-error ts-migrate(2339) FIXME: Property 'shouldPrefix' does not exist on type '{}
	const { shouldPrefix = true, shouldUseOverrides } = opts;
	const isAbsoluteUrl = /^http/.test(endpoint);

	const currentUrl = new URL(getCurrentURL());
	const url = isAbsoluteUrl ? new URL(endpoint) : new URL(`${currentUrl.origin}${endpoint}`);

	if (!isAbsoluteUrl && shouldPrefix) {
		url.pathname = `/api/v1${url.pathname}`;
	}

	if (shouldUseOverrides) {
		// Create a URLSearchParams interface based on the endpoint
		const currentParams = url.searchParams.toString();
		const newParams = new URLSearchParams(currentParams);

		// Copy overrides to new URLSearchParams
		['af-cobra-pr', 'af-scorpion-pr'].forEach((override) => {
			const params = currentUrl.searchParams.has('postMessageTarget')
				? new URL(currentUrl.searchParams.get('postMessageTarget')).searchParams
				: currentUrl.searchParams;

			if (!params.has(override)) {
				return;
			}

			const value = params.get(override);
			newParams.set(override, value);
		});

		// Set our search based on the new params
		url.search = `?${newParams.toString()}`;
	}

	return url.toString();
};

// configure default authorization
const auth = () => {
	const { csrfToken, jwtToken, sessionID } = window;

	request.defaults.headers['X-CSRF-Token'] = csrfToken;
	request.defaults.headers['Authorization'] = `JWT ${jwtToken}`;
	request.defaults.headers['af-sessionid'] = sessionID;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'request' implicitly has an 'any' type.
const removeAuthorizationHeader = (request) => {
	delete request.defaults.headers.Authorization;
	return request;
};

export default {
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'endpoint' implicitly has an 'any' type.
	get: (endpoint, options = {}) => {
		// If this request should be authorized, set those defaults on axios
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'auth' does not exist on type '{}'.
		if (options.auth) {
			auth();
		}

		const url = formatURL(endpoint, {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'prefix' does not exist on type '{}'.
			shouldPrefix: options.prefix,
			shouldUseOverrides: true,
		});

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'raw' does not exist on type '{}'.
		return request.get(url).then((response) => (options.raw ? response : response.data));
	},
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'endpoint' implicitly has an 'any' type.
	post: (endpoint, data = {}, options = {}) => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'prefix' does not exist on type '{}'.
		const { prefix: shouldPrefix, ...requestOptions } = options;

		const url = formatURL(endpoint, { shouldPrefix, shouldUseOverrides: true });

		// Set headers for the request
		auth();

		// axios doesn't allow replacement of headers so we have to first delete
		// the default, then replace with the dealer's token
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'headers' does not exist on type '{}'.
		if (requestOptions.headers && requestOptions.headers.authorization) {
			removeAuthorizationHeader(request);
		}

		return (
			request
				.post(url, data, requestOptions)
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'raw' does not exist on type '{}'.
				.then((response) => (options.raw ? response : response.data))
		);
	},
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'endpoint' implicitly has an 'any' type.
	patch: (endpoint, data = {}, options = {}) => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'prefix' does not exist on type '{}'.
		const url = formatURL(endpoint, { shouldPrefix: options.prefix, shouldUseOverrides: true });

		auth();

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'raw' does not exist on type '{}'.
		return request.patch(url, data).then((response) => (options.raw ? response : response.data));
	},
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'requests' implicitly has an 'any[]' typ
	multi: (requests = []) => {
		// TODO: can we handle individual requests errors but not fail the entire multi request?
		return request.all(requests).then((results) => {
			return results;
		});
	},
};
