import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.scss';

type Props = {
	children?: React.ReactNode;
};

const Header = ({ children }: Props) => <header className="panel-header">{children}</header>;

// TODO: this inline style overrides legacy css. get rid of legacy
Header.BackButton = ({ children = 'Back', ...restProps }) => (
	<div className="afi-v2-back afi-v2-c-fast" style={{ display: 'block' }} {...restProps}>
		{children}
	</div>
);
// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type '({ ch
Header.BackButton.propTypes = {
	children: PropTypes.node,
};
// @ts-expect-error ts-migrate(2339) FIXME: Property 'displayName' does not exist on type '({
Header.BackButton.displayName = 'Header.BackButton';

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'children' implicitly has an 'any'
Header.Title = ({ children }) => <h1>{children}</h1>;
// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type '({ ch
Header.Title.propTypes = {
	children: PropTypes.node,
};
// @ts-expect-error ts-migrate(2339) FIXME: Property 'displayName' does not exist on type '({
Header.Title.displayName = 'Header.Title';

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'children' implicitly has an 'any'
Header.Description = ({ children }) => <div className={styles['panel-description']}>{children}</div>;
// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type '({ ch
Header.Description.propTypes = {
	children: PropTypes.node,
};
// @ts-expect-error ts-migrate(2339) FIXME: Property 'displayName' does not exist on type '({
Header.Description.displayName = 'Header.Description';

export default Header;
